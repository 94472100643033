<template>
  <section id="login" class="d-flex align-items-center relative">
    <img style="width: 100px" class="img-fluid my-3 d-none d-md-block logo-absolute" src="/images/logo-eurotxt-black.png" alt="" />
    <div class="container" id="camera">
      <div class="row">
        <div class="col-md-7 offset-md-5 col-sm-9 offset-sm-3 col-12" style="background-color: rgba(255, 255, 255, 0.7)">
          <img style="width: 120px" class="img-fluid my-4 d-md-none" src="/images/logo-eurotxt-black.png" alt="" />
          <h1 class="bis gros-titre">Condition générales d'utilisation v.{{ cgu.cg_version }}</h1>
          <div class="row mb-2">
            <div class="col-md-12">
              <a href="" class="color-red" @click="$router.push('/subscribe').catch(err => {})">Retour accueil</a>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-12" v-html="cgu.cg_text"></div>
          </div>
          <div class="col-md-12 mb-3">
            <a href="" class="color-red" @click="$router.push('/subscribe').catch(err => {})">Retour accueil</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "cgu",
  components: {},
  data() {
    return {
      cgu: ""
    };
  },
  async mounted() {
    let response = await this.$axios.get(this.$config.server_url + "/api/1.0/cgus/last");
    this.cgu = response.data.data;
  },
  computed: {},
  watch: {},
  methods: {}
};
</script>
<style scoped>
#login {
  min-height: 100vh;
  background-image: url("/images/fond.png");
  background-position: center;
  background-size: cover;
}
.gros-titre {
  font-size: 32px;
}
.logo-absolute {
  position: absolute;
  top: 10px;
  left: 40px;
}
@media (min-width: 576px) {
  .gros-titre {
    font-size: 42px;
  }
}
</style>
