<template>
  <div id="app">
    <div class="alertcookie" v-if="alertcookieshow">
      <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center">
        <p>
          Ce site utilise les cookies pour améliorer votre expérience. Nous supposeronons que cela vous convient, mais vous pouvez les désactiver si
          vous le souhaitez.
          <a href="" @click="$router.push('/cgu').catch(err => {})" class="ml-3 mt-3">En savoir plus.</a>
        </p>
        <button class="btn btn-primary ml-md-3" @click="deleteAlertCookie">J'accepte</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modalcookies",
  components: {},
  data() {
    return {
      alertcookieshow: false
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  watch: {},
  async mounted() {
    // cookies
    let ok = this.$cookie.get("euros_allertcookie");
    if (!ok || ok != "ok") this.alertcookieshow = true;
  },
  destroyed() {},
  methods: {
    deleteAlertCookie() {
      this.alertcookieshow = false;
      this.$cookie.set("euros_allertcookie", "ok", 100);
    }
  }
};
</script>
<style lang="scss">
.alertcookie {
  max-height: 400px;
  overflow: auto;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px 60px;
  border-top: 3px solid #c6091f;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
</style>
