<template>
  <div>
    <euros-header :titre="getTitre()" pathRetour="/logout" btnRetour="Déconnexion"></euros-header>
    <div class="container-fluid bg-blue main">
      <div class="row pt-2">
        <div class="col-sm-3 d-flex align-items-center justify-content-between justify-content-sm-start mb-2 mb-sm-0">
          <div>
            <img style="width: 120px" class="img-fluid" src="/images/logo-eurotxt-black.png" alt="" @click="reloadApp" />
          </div>
        </div>
        <div class="col-sm-9 d-flex align-items-center justify-content-between justify-content-sm-end flex-wrap flex-sm-nowrap">
          <div class="mr-3">
            <button @click="newPatient" class="btn btn-primary uppercase btn-nouveau">Nouveau patient</button>
          </div>
          <div class="mr-3">
            <button @click="archiver" class="btn btn-primary uppercase btn-nouveau">Archiver</button>
          </div>
          <div>
            <m-form-checkbox class="mb-0 mt-3" v-model="archive" label="Voir tous" name="archive" :reverse="true"></m-form-checkbox>
          </div>
          <div>
            <m-form-text class="mt-0" placeholder="Rechercher" v-model="filterText" name="filtertext"></m-form-text>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <m-list-simple
            class="w-100"
            ref="patientList"
            :dynamic="true"
            :items="patients"
            item-value="pa_id"
            :item-height="30"
            :limit="patients_limit"
            :skip="patients_skip"
            :total="patients_total"
            @itemclick="itemclick"
          >
            <template v-slot:ths="{}">
              <th @click="sortContacts(['pa_name'])">
                <span class="pointer">Nom</span>
              </th>
              <th class="d-none d-md-table-cell" @click="sortContacts(['pa_sex'])">
                <span class="pointer">Sexe</span>
              </th>
              <th class="d-none d-md-table-cell" @click="sortContacts(['pa_birthdate'])">
                <span class="pointer">Age</span>
              </th>
              <th @click="sortContacts(['t1.createdAt'])" style="width: 150px" class="d-none d-md-table-cell">
                <span class="pointer">Créé le</span>
              </th>
              <th @click="sortContacts(['pa_dateoperation'])" style="width: 150px" class="d-none d-md-table-cell">
                <span class="pointer">Chirurgie</span>
              </th>
              <th style="width: 90px">
                <span></span>
              </th>
            </template>
            <template v-slot:tds="{ item }">
              <td>
                <div v-if="item.pa_name">{{ item.pa_name }}</div>
                <div v-else>Anonyme</div>
                <div class="d-block d-md-none text-little" v-if="item.pa_birthdate && item.pa_birthdate !== '0000-00-00'">
                  <span v-if="item.pa_sex"> {{ item.pa_sex === 2 ? "Femme " : "Homme " }}</span>
                  Né(e) le {{ item.pa_birthdate | formatDate }} ({{ item | formatAge }})
                </div>
                <div class="d-block d-md-none text-little">Créé le {{ item.createdAt | formatDateTime }}</div>
              </td>
              <td class="d-none d-md-table-cell">
                <div v-if="item.pa_sex">
                  {{ item.pa_sex === 2 ? "Femme " : "Homme " }}
                </div>
              </td>
              <td class="d-none d-md-table-cell">
                <div v-if="item.pa_birthdate && item.pa_birthdate !== '0000-00-00'">
                  {{ item.pa_birthdate | formatDate }} ({{ item | formatAge }})
                </div>
              </td>
              <td style="width: 150px; font-size: 0.7rem" class="d-none d-md-table-cell">
                {{ item.createdAt | formatDateTime }}
              </td>
              <td style="width: 150px; font-size: 0.7rem" class="d-none d-md-table-cell">
                {{ item.pa_dateoperation | formatDate }}
              </td>
              <td style="width: 90px" class="pointer">
                <icon width="22" height="22" color="#c6091f" name="print" class="mr-2" @click.stop="print(item)"></icon>
                <icon width="22" height="22" color="#c6091f" name="trash" class="mr-2" @click.stop="destroy(item)"></icon>
              </td>
            </template>
          </m-list-simple>
        </div>
      </div>
    </div>
    <div class="text-center">Version : {{ version }}</div>

    <div class="winmodal" v-if="!$store.state.cgu.validated || $store.state.help.patients"></div>
    <m-confirm-dialog
      v-model="confirmDestroyDialog"
      :text="confirmDestroyText"
      title="Confirmation"
      width="400px"
      @canceled="confirmDestroyDialog = false"
      @confirmed="destroyOk"
    ></m-confirm-dialog>

    <div class="cguwin" v-if="!$store.state.cgu.validated">
      <!-- <div class="cguclose">Fermer</div> -->
      <div class="d-flex flex-column h-100">
        <div class="flex-grow-1 cguwin-content">
          <h5>Condition Général d'Utilisation (version {{ $store.state.cgu.cg_version }})</h5>
          <div class="mb-5" v-html="$store.state.cgu.cg_text"></div>
        </div>
        <div class="pt-2">
          <button class="btn btn-primary mb-0" @click="cguAccept">J'accepte</button>
        </div>
      </div>
    </div>

    <div class="helpwin helpwinleft0" v-if="$store.state.help.patients">
      <div class="winclose">
        <label for="notdisplayanymore" style="margin: -5px 30px"
          ><input type="checkbox" id="notdisplayanymore" v-model="notdisplayanymore" />Ne plus afficher</label
        >
        <a href="javascript:void(0);" @click="$store.commit('set_help', ['patients', notdisplayanymore])"
          ><icon width="24" height="24" name="times" class="" color="black"></icon
        ></a>
      </div>
      <div class="row">
        <div class="col-md-3 helpwinleftcol0">
          <img src="/images/loic-gauche.png" class="img-fluid" alt="" />
        </div>
        <div class="col-md-6">
          <h3>Bonjour je suis LOIC, votre outil d’aide à la planification EUROS.</h3>
          <p>Laissez-moi vous guidez lors de votre première utilisation !</p>
          <p>Cliquez sur NOUVEAU PATIENT pour commencer.</p>
          <div>
            <button class="btn btn-primary" @click="newPatient">NOUVEAU PATIENT</button>
          </div>
        </div>
        <div class="col-md-3">
          <img src="/images/aide/01-nouveau-patient.png" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { version } from "../../package.json";

export default {
  name: "home-patients",
  components: {},
  data() {
    return {
      notdisplayanymore: false,
      version,
      filterText: "",
      tsSearch: null,
      archive: false,
      patients: [],
      patients_total: 0,
      patients_skip: 0,
      patients_limit: 100,
      confirmDestroyDialog: false,
      confirmDestroyText: "",
      confirmDestroyObj: null,
      sortFields: ["t1.createdAt"],
      sortOrder: "desc"
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    // console.log("this.$route.path", this.$route.path);
    this.$store.dispatch("addHistoryUrl", this.$route.path);
    this.loadPatients();
    await this.$store.dispatch("setversion");
  },
  watch: {
    archive(val) {
      this.loadPatients();
    },
    filterText: function (val) {
      if (this.tsSearch) window.clearTimeout(this.tsSearch);
      this.tsSearch = window.setTimeout(() => {
        this.loadPatients(val);
      }, 500);
    }
  },
  computed: {},
  methods: {
    reloadApp() {
      window.location.reload();
    },
    async cguAccept() {
      // this.cgu_validated = true;
      // let response =
      await this.$axios.post(this.$config.server_url + "/api/1.0/cgus/valid", { cg_id: this.$store.state.cgu.cg_id });
      this.$store.commit("set_cgu_validated");
    },
    getTitre() {
      let titre = `Liste de vos 30 derniers patients`;
      if (this.archive || this.patients_total < 30) titre = `Liste de vos ${this.patients.length} patients`;
      return titre;
    },
    sortContacts(tabSearchFields) {
      if (tabSearchFields.length && this.sortFields.length && tabSearchFields[0] == this.sortFields[0]) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = tabSearchFields;
      if (tabSearchFields[0] === "t1.createdAt") {
        this.patients.sort((a, b) => {
          if (this.sortOrder === "asc") {
            if (this.$dayjs(a.createdAt).isBefore(b.createdAt)) return -1;
            if (this.$dayjs(a.createdAt).isAfter(b.createdAt)) return 1;
            if (this.$dayjs(a.createdAt).isSame(b.createdAt)) return 0;
          } else {
            if (this.$dayjs(a.createdAt).isBefore(b.createdAt)) return 1;
            if (this.$dayjs(a.createdAt).isAfter(b.createdAt)) return -1;
            if (this.$dayjs(a.createdAt).isSame(b.createdAt)) return 0;
          }
        });
      }
      if (tabSearchFields[0] === "pa_birthdate") {
        this.patients.sort((a, b) => {
          if (this.sortOrder === "asc") {
            if (this.$dayjs(a.pa_birthdate).isBefore(b.pa_birthdate)) return -1;
            if (this.$dayjs(a.pa_birthdate).isAfter(b.pa_birthdate)) return 1;
            if (this.$dayjs(a.pa_birthdate).isSame(b.pa_birthdate)) return 0;
          } else {
            if (this.$dayjs(a.pa_birthdate).isBefore(b.pa_birthdate)) return 1;
            if (this.$dayjs(a.pa_birthdate).isAfter(b.pa_birthdate)) return -1;
            if (this.$dayjs(a.pa_birthdate).isSame(b.pa_birthdate)) return 0;
          }
        });
      } else {
        this.loadPatients();
      }
    },
    print(item) {
      window.open(
        this.$config.server_url +
          "/api/1.0/patients/" +
          item.pa_id +
          "/print" +
          "?token=" +
          this.$store.state.accesstoken +
          "&origin=webapp" +
          "&d=" +
          new Date().getTime()
      );
    },
    destroy(row_pa) {
      // console.log("row_pa", row_pa);
      this.confirmDestroyObj = row_pa;
      this.confirmDestroyText = `Souhaitez-vous effacer le patient ${row_pa.pa_name} ?`;
      this.confirmDestroyDialog = true;
    },
    async destroyOk() {
      // let response =
      await this.$axios.delete(this.$config.server_url + "/api/1.0/patients/" + this.confirmDestroyObj.pa_id, {});
      this.confirmDestroyDialog = false;
      this.loadPatients();
    },
    async loadPatients() {
      let limit = 30;
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      if (this.archive) limit = 100000;
      let params = {
        pa_archive: this.archive,
        // text: this.filterText,
        sort: sort,
        limit: limit
      };
      let response = await this.$axios.get(this.$config.server_url + "/api/1.0/patients", { params });
      this.patients = response.data.data;
      this.patients = this.patients.filter(p => {
        if (!this.filterText) return true;
        if (p.pa_name.toLocaleLowerCase("fr").indexOf(this.filterText.toLocaleLowerCase("fr")) >= 0) return true;
        return false;
      });
      if (response.data.meta) this.patients_total = response.data.meta.total;
      else this.patients_total = this.patients.length;
    },
    newPatient() {
      this.$router.push("/chooseimage").catch(err => {});
    },
    archiver() {
      this.$router.push("/archive").catch(err => {});
    },
    itemclick(item) {
      this.$router.push("/result/" + item.pa_id).catch(err => {});
    }
  }
};
</script>

<style scoped lang="scss">
.m-form-checkbox {
  margin-bottom: 0;
}
.cguwin {
  position: fixed;
  background-color: white;
  border: 1px solid black;
  top: 5%;
  height: 90%;
  width: 90%;
  left: 5%;
  z-index: 10001;
  padding: 20px;
  // overflow: auto;
  .btn-jaccepte {
    position: absolute;
    bottom: 5px;
  }
}
.cguwin-content {
  overflow: auto;
}
@media (min-width: 576px) {
  .helpwin {
  }
}
@media (min-width: 768px) {
  .helpwin {
  }
}
</style>
