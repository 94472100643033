import { render, staticRenderFns } from "./Prosthesis.vue?vue&type=template&id=7635c5c3&scoped=true&"
import script from "./Prosthesis.vue?vue&type=script&lang=js&"
export * from "./Prosthesis.vue?vue&type=script&lang=js&"
import style0 from "./Prosthesis.vue?vue&type=style&index=0&id=7635c5c3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7635c5c3",
  null
  
)

export default component.exports