<template>
  <div class="container-fluid" ref="header" id="header">
    <div class="row">
      <div class="col-md-12 py-2">
        <div
          class="d-flex justify-content-between"
          :class="this.$store.state.historyUrl.length < 2 && btnRetour !== 'Déconnexion' ? 'color-disable' : ''"
        >
          <a href="#" @click="retour" class="d-flex">
            <icon width="22" height="22" name="chevron-left"></icon> <span class="d-none d-sm-block">{{ btnRetour }} </span>
          </a>
          <div class="text-center">
            {{ titre }}
          </div>
          <div class=" ">
            <div @click="help" class="aide pointer color-white d-flex flex-row align-items-center justify-content-end">
              <div class="d-none d-sm-block">Aide</div>
              <icon width="22" height="22" name="question-circle" class="ml-2" color="#c6091f"></icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="winmodal" v-if="showhelp"></div>
    <div class="helpwin helpwinleft0" v-if="showhelp">
      <div class="winclose">
        <a href="javascript:void(0);" @click="showhelp = false"><icon width="24" height="24" name="times" class="" color="black"></icon></a>
      </div>
      <div class="row">
        <div class="col-md-3 helpwinleftcol0">
          <img src="/images/loic-gauche.png" class="img-fluid" alt="" />
        </div>
        <div class="col-md-6">
          <h3>Aide.</h3>
          <p>
            Cliquez sur le bouton "Réinitialiser les aides" afin d'afficher à nouveau les aides que vous ne vouliez plus afficher.<br />
            Vous pouvez aussi télécharger une aide au format PDF.
          </p>
          <div class="row">
            <div class="col">
              <button class="btn btn-primary" @click="inithelps">RÉINITIALISER LES AIDES</button>
            </div>
            <div class="col text-right">
              <button class="btn btn-primary" @click="downloadhelp">TÉLÉCHARGER L'AIDE PDF</button>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <img src="/images/aide/aideenligne.jpeg" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "topheader",
  components: {},
  props: {
    titre: String,
    btnRetour: { type: String, default: "Liste" },
    pathRetour: String
  },
  data() {
    return { showhelp: false };
  },
  async mounted() {},
  watch: {},
  computed: {},
  methods: {
    downloadhelp() {
      window.open(this.$config.server_url + "/api/1.0/files/help" + "?token=" + this.$store.state.accesstoken + "&origin=webapp");
    },
    inithelps() {
      this.$store.commit("reset_help");
    },
    retour() {
      if (this.btnRetour === "Déconnexion") {
        this.$store.commit("set_connexion", {});
        this.$store.commit("set_historyUrl", []);
        this.$router.push("/login").catch(err => {});
      } else {
        // if (this.$store.state.historyUrl.length > 1) {
        //   let path = this.$store.state.historyUrl[this.$store.state.historyUrl.length - 2];
        //   this.$router.push(path);
        //   this.$store.dispatch("deleteHistoryUrl");
        // }
        this.$router.push(this.pathRetour).catch(err => {});
      }
    },

    help() {
      this.showhelp = true;
    }
  }
};
</script>
<style scoped lang="scss">
.color-disable {
  a {
    color: #96c2f0;
  }
}
#header {
  position: fixed;
  background-color: #fff;
  z-index: 100;
}
</style>
