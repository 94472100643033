import _ from "lodash";

const Validation = {
  mandatory(v, errMsg = "Obligatoire") {
    return !!v || errMsg;
  },
  number(v, errMsg = "Indiquer un nombre") {
    if ((v + "").indexOf(",")) v = (v + "").replace(/\,/, ".");
    return _.isFinite(v * 1) || errMsg;
  },
  integer(v, errMsg = "Indiquer un entier") {
    if ((v + "").indexOf(",")) v = (v + "").replace(/\,/, ".");
    if (_.isFinite(v * 1)) {
      if ((v + "").indexOf(".") >= 0) return errMsg;
      return true;
    }
    return errMsg;
  },
  email(v, errMsg = "Indiquer un email valide") {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(v).toLowerCase())) return true;
    return errMsg;
  }
};
export default Validation;
