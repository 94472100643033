<template>
  <div class="modalwait d-flex justify-content-center align-items-center">
    <div class="d-flex justify-content-center align-items-center flex-column">
      <looping-rhombuses-spinner :animation-duration="1000" :size="100" :color="'#c6091f'" />
      <div class="explain text-center" v-if="explain" v-html="explain"></div>
    </div>
    <div class="cancelbtn"><button @click="cancelSend" class="btn btn-primary">Annuler</button></div>
  </div>
</template>

<script>
// import { AtomSpinner } from "epic-spinners";
// import { SelfBuildingSquareSpinner } from "epic-spinners";
// import { BreedingRhombusSpinner } from "epic-spinners";
import { LoopingRhombusesSpinner } from "epic-spinners";

export default {
  name: "mmodalspinner",
  props: {
    addcancel: {
      default: false,
      type: Boolean
    },
    explain: {
      default: "",
      type: String
    }
  },
  components: {
    // SelfBuildingSquareSpinner
    // BreedingRhombusSpinner
    LoopingRhombusesSpinner
  },

  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    cancelSend() {
      this.$emit("cancel");
    }
  }
};
</script>

<style scoped lang="scss">
.cancelbtn {
  position: absolute;
  bottom: 15px;
}
.explain {
  background-color: white;
  color: black;
  padding: 15px;
  margin-top: 30px;
}
</style>
