var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[(_vm.label)?_c('label',{staticClass:"label",class:_vm.labelClass,attrs:{"for":_vm.id2}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"input-group"},[(_vm.prependText)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",domProps:{"innerHTML":_vm._s(_vm.prependText)}})]):_vm._e(),(_vm.type !== 'textarea')?_c('input',{ref:"myinput",class:{
        'bg-white': true,
        'form-control': true,
        'is-invalid': _vm.errormsg2
      },attrs:{"aria-haspopup":_vm.ariaHaspopup,"aria-expanded":_vm.ariaExpanded,"type":_vm.type,"name":_vm.name,"maxlength":_vm.maxlength,"id":_vm.id2,"disabled":_vm.disabled,"aria-describedby":"","placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"click":function($event){return _vm.onclick($event)},"input":function($event){return _vm.oninput($event)},"focus":function($event){return _vm.onfocus($event)},"blur":function($event){return _vm.onblur($event)},"keyup":function($event){return _vm.onkeyup($event)},"keydown":function($event){return _vm.onkeydown($event)}}}):_vm._e(),(_vm.type === 'textarea')?_c('textarea',{ref:"myinput",class:{
        textarea: true,
        'form-control': true,
        'is-invalid': _vm.errormsg2
      },attrs:{"aria-haspopup":_vm.ariaHaspopup,"aria-expanded":_vm.ariaExpanded,"name":_vm.name,"id":_vm.id2,"disabled":_vm.disabled,"aria-describedby":"","autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"click":function($event){return _vm.onclick($event)},"input":function($event){return _vm.oninput($event)},"focus":function($event){return _vm.onfocus($event)},"blur":function($event){return _vm.onblur($event)},"keyup":function($event){return _vm.onkeyup($event)},"keydown":function($event){return _vm.onkeydown($event)}}}):_vm._e(),(_vm.appendText || _vm.appendIcon)?_c('div',{staticClass:"input-group-prepend"},[(_vm.appendText)?_c('span',{staticClass:"input-group-text",domProps:{"innerHTML":_vm._s(_vm.appendText)}}):_vm._e(),(_vm.appendIcon)?_c('span',{staticClass:"input-group-text"},[_c('icon',{staticClass:"icon-menu",attrs:{"name":_vm.appendIcon}})],1):_vm._e()]):_vm._e(),(_vm.errormsg2)?_c('div',{staticClass:"invalid-feedback",attrs:{"id":_vm.id2}},[_vm._v(" "+_vm._s(_vm.errormsg2)+" ")]):_vm._e()]),(_vm.subText)?_c('small',{staticClass:"sub-text form-text text-muted",domProps:{"innerHTML":_vm._s(_vm.subText)}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }