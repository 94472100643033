import Vue from "vue";
import VueRouter from "vue-router";

import store from "./store";
import axios from "axios";

import Patients from "../views/Patients.vue";
import Login from "../views/Login.vue";
import Subscribe from "../views/Subscribe.vue";
import Cgu from "../views/Cgu.vue";
// import Editimage from "../views/Editimage.vue";
// import Editimagejpg from "../views/Editimagejpg.vue";
import ChooseImage from "../views/ChooseImage.vue";
import Ratio from "../views/Ratio.vue";
import Prosthesis from "../views/Prosthesis.vue";
import Infos from "../views/Infos.vue";
import Archive from "../views/Archive.vue";
import Result from "../views/Result.vue";

Vue.use(VueRouter);

function setLogout() {
  store.commit("set_connexion", {
    /*     accesstoken: "",
    //refreshtoken: response.data.refreshtoken,
    user: {},
    // more: {},
    preferences: {} */
  });
}

const routes = [
  {
    path: "/",
    name: "redirectlogin",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {}
  },
  {
    path: "/dbllogin",
    name: "login",
    component: Login,
    meta: {}
  },
  {
    path: "/resetpass/:co_id/:challenge",
    name: "resetpass",
    component: Login,
    meta: {}
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: Subscribe,
    meta: {}
  },
  {
    path: "/cgu",
    name: "cgu",
    component: Cgu,
    meta: {}
  },
  {
    path: "/logout",
    name: "logout",
    component: Login,
    meta: {}
  },
  {
    path: "/patients",
    name: "patients",
    component: Patients,
    meta: {}
  },
  {
    path: "/chooseimage",
    name: "chooseimage",
    component: ChooseImage,
    meta: {}
  },
  // l'id est celui du patient
  {
    path: "/ratio/:id",
    name: "ratio",
    component: Ratio,
    meta: {}
  },
  // l'id est celui du patient
  {
    path: "/prosthesis/:id/:step",
    name: "prosthesis",
    component: Prosthesis,
    meta: {}
  },
  // l'id est celui du patient
  {
    path: "/infos/:id",
    name: "infos",
    component: Infos,
    meta: {}
  },
  {
    path: "/archive",
    name: "archive",
    component: Archive,
    meta: {}
  },
  {
    path: "/archive/:id",
    name: "archiveid",
    component: Archive,
    meta: {}
  },
  // l'id est celui du patient
  {
    path: "/result/:id",
    name: "result",
    component: Result,
    meta: {}
  }
  // {
  //   path: "/editimage",
  //   name: "editimage",
  //   component: Editimage,
  //   meta: {}
  // },
  // {
  //   path: "/editimagejpg",
  //   name: "editimagejpg",
  //   component: Editimagejpg,
  //   meta: {}
  // }
  // {
  //   path: "/login/:code_admin",
  //   name: "login-autosignin",
  //   // component: Login,
  //   // props: true,
  //   meta: { menu: "login" }
  // }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) => {
  async function autolog() {
    if (!store.state.accesstoken) return false;
    // console.log("store.state.accesstoken", store.state.accesstoken);
    // console.log("process.env.VUE_APP_SERVER_URL", process.env.VUE_APP_SERVER_URL);
    try {
      let response = await axios.post(process.env.VUE_APP_SERVER_URL + "/api/1.0/autosignin", {
        accesstoken: store.state.accesstoken,
        de_code: store.state.de_code
      });
      if (response.data.err) {
        setLogout();
        return false;
      }
      let data = response.data;
      store.commit("set_connexion", {
        accesstoken: data.accesstoken,
        //refreshtoken: response.data.refreshtoken,
        user: data.data,
        // more: data.more,
        preferences: data.preferences,
        cgu: data.cgu,
        de_code: data.de_code
      });
      // store.dispatch("startConfig");
      return true;
    } catch (error) {
      console.error("ko", error);
      return false;
    }
  }
  function isIOS() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }
  function isStandalone() {
    return isIOS() && window.navigator.standalone;
  }
  if (to.name == "login") {
    // console.log("isIOS()", isIOS());
    if (isStandalone() || !isIOS()) store.commit("set_ispwa", true);
    else store.commit("set_ispwa", false);
  }
  // http://localhost:7786/#/resetpass/10/%242b%2410%24u6oo0nVsS9C6xTFAXjW5tOekhBZYN7jPvg4H3Gt.8UYoVFiRfitAu
  // console.log("to.name", to.name);
  if (to.name == "logout") {
    // store.dispatch("setData", { cmd: "start-animation" });
    setLogout();
    return next({ path: "/login" });
  }
  if (to.name == "resetpass") return next();
  //if (to.name == "cgu") return next();
  // if (to.name == "login-autosignin") {
  //   store.commit("set_tokens", {
  //     accesstoken: to.params.code_admin
  //     // refreshtoken: response2.data.refreshtoken
  //   });
  //   let ok = await autolog();
  //   if (!ok) return next({ path: "/login?mode=standalone" });
  //   store.dispatch("setData", { cmd: "start-config" });
  //   return next({ path: "/home" });
  // }
  if (!store.state.connected) await autolog();
  if ((to.name == "login" || to.name == "subscribe" || to.name == "cgu") && !store.state.connected) return next();
  if ((to.name == "login" || to.name == "subscribe") && store.state.connected) return next({ path: "/patients" });
  if (!store.state.connected) return next({ path: "/login" });
  // console.log("store.state.connected", store.state.connected);

  // if (to.name == "home" && store.state.connected) return next();
  // if (to.name == "home" && !store.state.connected) return next({ path: "/login?mode=standalone" });

  // if (!store.state.connected) return next({ path: "/login?mode=standalone" });

  // if (
  //   (to.name === "sources" || to.name === "informations" || to.name === "resolutions" || to.name === "region" || to.name === "puckbutton") &&
  //   !store.state.start_config
  // )
  //   return next({ path: "/home" });

  return next();
});

export default router;
