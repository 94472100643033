<template>
  <section id="login" class="d-flex align-items-center relative">
    <div class="addtohomepage" v-if="!$store.state.isPwa">
      <div class="triangle-code"></div>
      Pour installer cette webapp sur votre iPhone, cliquez sur <img src="/images/bt_telecharger.png" alt="" />
    </div>
    <img style="width: 100px" class="img-fluid my-3 d-none d-md-block logo-absolute" src="/images/logo-eurotxt-black.png" alt="" />
    <div class="container" id="camera">
      <div class="row">
        <div class="col-md-7 offset-md-5 col-9 offset-3">
          <img style="width: 120px" class="img-fluid my-4 d-md-none" src="/images/logo-eurotxt-black.png" @click="reloadApp" alt="" />
          <div>Version {{ version }}</div>
          <h1 class="bis gros-titre">
            <span v-if="currentPart == 'part1'">LOIC, votre outil d’aide à la planification EUROS</span>
            <span v-else-if="currentPart == 'part6'">Double identification</span>
            <span v-else>Mot de passe oublié</span>
          </h1>
          <div class="row" ref="part1" v-show="currentPart == 'part1'">
            <div class="col-md-6">
              <h3 class="bis">Connexion</h3>
              <div v-if="errLogin" class="alert alert-danger">{{ errLogin }}</div>
              <m-form-text label="Identifiant" v-model="co_login" name="login" autocomplete></m-form-text>
              <m-form-text label="Mot de passe" type="password" v-model="co_password" name="pass" autocomplete></m-form-text>
              <button class="btn btn-primary mt-2" @click="login()">Se connecter</button>
              <p class="color-blue mt-1 mb-0"><a class="color-pink pointer" @click="currentPart = 'part2'">Mot de passe oublié</a></p>
              <a href="#" @click="subscribe" class="mt-2 d-md-none color-blue mb-3"> Je n'ai pas de compte </a>
              <div class="hr-vertical d-none d-md-block"></div>
            </div>
            <div class="col-md-6 d-none d-md-block">
              <h3 class="bis">Sans compte</h3>
              <p class="color-blue">Cet outil est réservé aux professionnels de santé</p>
              <a href="#" @click="subscribe" class="mt-2">
                <button class="btn btn-primary">Pas de compte</button>
              </a>
            </div>
          </div>
          <div class="row" ref="part2" v-show="currentPart == 'part2'">
            <div class="col-md-12">
              <h3 class="bis">Réinitialisez votre mot de passe</h3>
              <m-form-text label="Email" v-model="co_login" name="login" autocomplete></m-form-text>
              <div class="alert alert-danger mt-2" v-if="alert_txt_part2">
                {{ alert_txt_part2 }}
              </div>
              <button class="btn btn-primary mt-2" @click="sendPassword">Réinitialiser</button>
              <p class="color-blue mt-1 mb-0"><a class="color-pink pointer" @click="currentPart = 'part1'">Annuler</a></p>
            </div>
          </div>
          <div class="row" ref="part3" v-show="currentPart == 'part3'">
            <div class="col-md-12">
              <div class="alert alert-success mt-2">
                Vous allez recevoir un mail dans quelques instant, cliquez sur le lien pour mettre à jour votre mot de passe.
              </div>
              <a href="#" @click="goLogin">Se connecter maintenant</a>
            </div>
          </div>

          <div class="row" ref="part4" v-show="currentPart == 'part4'">
            <div class="col-md-12">
              <m-form-password :showRules="true" valid="shadow" :rules="rulesPassword" @validPassword="validPassword"></m-form-password>
              <div class="alert alert-danger mt-2" v-if="alert_txt_part4">
                {{ alert_txt_part4 }}
              </div>
              <button class="btn btn-primary mt-2" @click="newpassword">Réinitiliser</button>
            </div>
          </div>

          <div class="row" ref="part5" v-show="currentPart == 'part5'">
            <div class="col-md-12">
              <div class="alert alert-success mt-2">
                C'est bon !
                <br />Votre nouveau mot de passe a été défini. <br />Vous pouvez maintenant vous connecter.
              </div>
              <button @click="goLogin" class="btn btn-primary mt-2">Se connecter maintenant</button>
            </div>
          </div>

          <div class="row" ref="part6" v-show="currentPart == 'part6'">
            <div class="col-md-12">
              <p>Merci de saisir le code que vous avez reçu sur votre email</p>
              <div v-if="errLogin" class="alert alert-danger">{{ errLogin }}</div>
              <m-form-text label="Code reçu par mail" v-model="de_code" name="de_code"></m-form-text>
              <button @click="login" class="btn btn-primary mt-2">Se connecter maintenant</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MFormPassword from "../components/MFormPassword.vue";
import { version } from "../../package.json";
export default {
  name: "login",
  components: { MFormPassword },
  data() {
    return {
      version,
      currentPart: "part1",
      errLogin: false,
      co_password: "",
      co_login: "",
      de_code: this.$store.state.de_code,
      /// password
      rulesPassword: {
        nbCharacteres: 8,
        specialCharactere: true,
        uppercase: true,
        number: true
      },
      isValidPassword: false,
      errPassword: [],
      //// reset pass
      alert_txt_part2: "",
      alert_txt_part4: "",
      co_password1: "",
      challenge_newpassword: "",
      co_id: ""
    };
  },
  async mounted() {
    await this.$store.dispatch("setversion");

    if (this.$route.name == "resetpass") {
      this.currentPart = "part4";
      this.co_password1 = "";
      this.co_id = this.$route.params.co_id;
      this.checkchallenge(this.$route.params.co_id, this.$route.params.challenge);
    }
  },
  computed: {},
  watch: {},
  methods: {
    reloadApp() {
      window.location.reload();
    },
    validPassword(isValid, errors, password) {
      this.isValidPassword = isValid;
      this.errPassword = errors;
      this.co_password1 = password;
    },
    async login() {
      // this.$router.push("/home");
      this.errLogin = "";
      try {
        let response = await this.$axios.post(this.$config.server_url + "/api/1.0/signin", {
          co_password: this.co_password,
          co_login: this.co_login,
          de_code: this.de_code,
          isTest: this.currentPart == "part6"
        });
        if (response.data.err) {
          // console.log("response.data.err", response.data.err);
          if (response.data.err.key == "double_check") {
            if (this.currentPart == "part6") this.errLogin = "Votre code est incorrect";
            else this.errLogin = "";
            this.currentPart = "part6";
          } else if (response.data.err.key == "user_not_active") this.errLogin = "Votre compte est désactivé.";
          else this.errLogin = "Erreur de connexion : vérifiez vos identifiants et réessayez.";
          return;
        }
        this.$store.commit("set_connexion", {
          accesstoken: response.data.accesstoken,
          user: response.data.row_co,
          preferences: response.data.preferences,
          cgu: response.data.cgu,
          de_code: response.data.de_code
        });
        this.$router.push("/patients").catch(err => {});
      } catch (error) {
        console.error("Erreur de co : ", error);
      }
    },
    subscribe() {
      this.$router.push("/subscribe").catch(err => {});
    },
    async sendPassword() {
      let response = await this.$axios.post(this.$config.server_url + "/api/1.0/signin/forgetpassword", { co_login: this.co_login });
      if (response.data.err) {
        this.alert_txt_part2 = "Nous n'avons pas pu retrouver votre identifiant";
      } else {
        this.currentPart = "part3";
      }
    },
    async checkchallenge(co_id, challenge) {
      // console.log("co_id, challenge", co_id, challenge);
      this.co_id = co_id;
      let response = await this.$axios.post(this.$config.server_url + "/api/1.0/signin/forgetpassword/checkchallenge", { co_id, challenge });
      if (response.data.err) {
        this.alert_txt_part4 = "Erreur de challenge";
      } else {
        // this.currentPart = "part3";
        this.challenge_newpassword = response.data.data.challenge;
      }
    },
    async newpassword() {
      //if (!this.challenge_newpassword) return;
      /*** vérification du mot de passe */
      this.alert_txt_part4 = "";
      if (!this.isValidPassword) {
        for (let ierr = 0; ierr < this.errPassword.length; ierr++) {
          this.alert_txt_part4 += this.errPassword[ierr] + " ";
        }
      }
      if (!this.alert_txt_part4) {
        let response = await this.$axios.post(this.$config.server_url + "/api/1.0/signin/forgetpassword/newpassword", {
          co_password: this.co_password1,
          co_id: this.co_id,
          challenge: this.challenge_newpassword
        });
        if (response.data.err) {
          this.alert_txt_part4 = "Erreur";
        } else {
          this.currentPart = "part5";
        }
      }
    },
    goLogin() {
      this.currentPart = "part1";
      this.$router.push("/login").catch(err => {});
    }
  }
};
</script>
<style scoped>
#login {
  min-height: 100vh;
  background-image: url("/images/fond.png");
  background-position: center;
  background-size: cover;
}
.gros-titre {
  font-size: 32px;
}
.hr-vertical {
  position: absolute;
  height: 100%;
  width: 1px;
  top: 0;
  right: 0;
  background-color: gray;
}
.text-small {
  font-size: 10px;
}
.color-blue {
  color: #2858c8;
}
.logo-absolute {
  position: absolute;
  top: 10px;
  left: 40px;
}
@media (min-width: 576px) {
  .gros-titre {
    font-size: 42px;
  }
}

.addtohomepage {
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  background-color: white;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 15px;
  text-align: center;
  padding: 5px 20px;
  width: 90%;
  left: 5%;
}
.triangle-code {
  position: absolute;
  left: 50%;
  bottom: -16px;
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 17px solid white;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}
.icon-eyes {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
}
</style>
