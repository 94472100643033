<template>
  <div class="mlistsimple-container hscroll">
    <table class="table">
      <thead>
        <tr class="tr-head">
          <slot name="ths"></slot>
        </tr>
      </thead>
      <draggable v-if="dragable" tag="tbody" :list="items" handle=".handle">
        <tr v-for="(item, indexItem) in items" :key="item[itemValue]" :class="getTrClass(item)" @click="onitemclick(item, indexItem, $event)">
          <slot name="tds" :item="item"></slot>
        </tr>
      </draggable>

      <tbody v-if="!dragable">
        <tr v-for="(item, indexItem) in items" :key="item[itemValue]" :class="getTrClass(item)" @click="onitemclick(item, indexItem, $event)">
          <slot name="tds" :item="item"></slot>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @Marina j'ai créé cette objet car il est bien différent de MList ! En effet MList a beaucoup de complexité qui ne sert à rien ici ! Il faudra peut-être par contre rajouter ici un système de paging, on verra...
export default {
  name: "mlistsimple",
  // components: { draggable },
  props: {
    items: {
      default: function () {
        return [];
      },
      type: Array
    },
    dragable: {
      default: false,
      type: Boolean
    },
    current: {
      default: "",
      type: [String, Number]
    },
    itemValue: {
      default: "",
      type: String
    },
    pagging: {
      default: false,
      type: Boolean
    },
    limit: {
      default: 100,
      type: Number
    },
    total: {
      default: 0,
      type: Number
    },
    skip: {
      default: 0,
      type: Number
    },
    itemHeight: {
      default: 30,
      type: Number
    },
    bgstrip1: {
      default: false,
      type: Boolean
    }
  },
  mounted() {
    this.update(0);
  },
  data() {
    return {
      scrollTop: 0,
      // skip: 0,
      timerScroll: null,
      list: [
        { name: "John", text: "", id: 0 },
        { name: "Joao", text: "", id: 1 },
        { name: "Jean", text: "", id: 2 }
      ],
      dragging: false
    };
  },
  watch: {
    items(val) {
      this.$nextTick(() => {});
    },
    total(val) {
      // this.calcPadding();
    }
  },
  computed: {
    // itemHeight2() {
    //   return this.itemHeight - 0;
    // },
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  components: {},
  methods: {
    getTrClass(item) {
      let cls = "";
      if (this.current == item[this.itemValue]) cls += " active";
      if (item.mListSimpleTrClass) cls += " " + item.mListSimpleTrClass;
      return cls;
    },
    update(scrollTop) {
      this.$nextTick(() => {});
    },
    // scrollToTop() {
    //   this.$refs.container.scrollTop = 0;
    // },
    // onscroll(evt, arg2) {
    //   // console.log("evt", evt.target.scrollTop);
    //   if (this.timerScroll) window.clearTimeout(this.timerScroll);
    //   this.timerScroll = window.setTimeout(() => {
    //     this.scrollTop = evt.target.scrollTop;
    //     let skip = Math.floor(this.scrollTop / this.itemHeight);
    //     this.$emit("changerange", skip, this.scrollTop);
    //   }, 100);
    // },
    onitemclick(item, indexItem, evt) {
      this.$emit("itemclick", item, indexItem, evt);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";

.mlistsimple-item {
  cursor: pointer;
}
.mlistsimple-container {
  font-size: 12px;
}
// @import "../sass/variables.scss";
// .mlistsimple-container {
//   table {
//     tr {
//       td,
//       th {
//       }
//     }
//     thead {
//       th {
//       }
//     }
//     tbody {
//       tr {
//       }
//     }
//   }
// }

// .table tr:nth-child(odd) {
//   background-color: #f7f7f7;
// }
.table {
  thead th {
    color: #1d1d1a;
    text-transform: uppercase;
    border-top: 0;
    // font-size: 14px;
  }
  // thead .tr-head {
  //   background-color: #fff;
  // }
  tbody tr {
    cursor: pointer;
  }
  tbody tr:hover {
    background-color: #e1d7da;
  }
  td {
    font-size: 1rem;
    // font-weight: 500;
  }
}
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
input {
  display: inline-block;
  width: 50%;
}
.text {
  margin: 20px;
}
</style>
