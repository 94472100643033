import Vue from "vue";
// import jQuery from "jquery";

import "vue-awesome/icons/times";
import "vue-awesome/icons/print";
import "vue-awesome/icons/question-circle";
import "vue-awesome/icons/chevron-left";
import "vue-awesome/icons/chevron-right";
import "vue-awesome/icons/arrow-circle-left";
import "vue-awesome/icons/arrow-circle-right";
import "vue-awesome/icons/camera-retro";
import "vue-awesome/icons/file-image";
import "vue-awesome/icons/trash";
import "vue-awesome/icons/forward";
import "vue-awesome/icons/eye";
import "vue-awesome/icons/eye-slash";
import "vue-awesome/icons/check";

import Icon from "vue-awesome/components/Icon";
Vue.component("Icon", Icon);

// import MForm from "../components/MForm";
// Vue.component("m-form", MForm);
// import MFormBase from "../components/MFormBase";
// Vue.component("m-form-base", MFormBase);
import MFormText from "../components/MFormText";
Vue.component("m-form-text", MFormText);
import MFormTextMarkdown from "../components/MFormTextMarkdown";
Vue.component("m-form-text-markdown", MFormTextMarkdown);

import MFormCombobox from "../components/MFormCombobox";
Vue.component("m-form-combobox", MFormCombobox);
// import MFormRadioGroup from "../components/MFormRadioGroup";
// Vue.component("m-form-radio-group", MFormRadioGroup);
// import MFormRadio from "../components/MFormRadio";
// Vue.component("m-form-radio", MFormRadio);
import MFormCheckbox from "../components/MFormCheckbox";
Vue.component("m-form-checkbox", MFormCheckbox);

// import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
// import datePicker from "vue-bootstrap-datetimepicker";
// Vue.component("date-picker", datePicker);
import MFormDate from "../components/MFormDate";
Vue.component("m-form-date", MFormDate);
import MCalendar from "../components/MCalendar";
Vue.component("m-calendar", MCalendar);
import MFormHour from "../components/MFormHour";
Vue.component("m-form-hour", MFormHour);
import MHour from "../components/MHour";
Vue.component("m-hour", MHour);
// jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
//   icons: {
//     time: "far fa-clock",
//     date: "far fa-calendar",
//     up: "fas fa-arrow-up",
//     down: "fas fa-arrow-down",
//     previous: "fas fa-chevron-left",
//     next: "fas fa-chevron-right",
//     today: "fas fa-calendar-check",
//     clear: "far fa-trash-alt",
//     close: "far fa-times-circle"
//   }
// });

// import MFormDateHour from "../components/MFormDateHour";
// Vue.component("m-form-date-hour", MFormDateHour);
import MConfirmDialog from "../components/MConfirmDialog";
Vue.component("m-confirm-dialog", MConfirmDialog);
import MMessageDialog from "../components/MMessageDialog";
Vue.component("m-message-dialog", MMessageDialog);
import MFormFiles from "../components/MFormFiles";
Vue.component("m-form-file", MFormFiles);
// import MFormImages from "../components/MFormImages";
// Vue.component("m-form-images", MFormImages);
import MFormSelect from "../components/MFormSelect";
Vue.component("m-form-select", MFormSelect);
import MFormSelectMultiple from "../components/MFormSelectMultiple";
Vue.component("m-form-select-multiple", MFormSelectMultiple);

import MList from "../components/MList";
Vue.component("m-list", MList);
import MListSimple from "../components/MListSimple";
Vue.component("m-list-simple", MListSimple);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

// import ContactDetails from "../views/ContactDetails";
// Vue.component("contact-details", ContactDetails);

import VueDraggable from "vuedraggable";
Vue.component("draggable", VueDraggable);

// import MHomeIcon from "../components/MHomeIcon";
// Vue.component("m-home-icon", MHomeIcon);

import TopHeader from "../views/TopHeader";
Vue.component("euros-header", TopHeader);

import MModalSpinner from "../components/MModalSpinner";
Vue.component("m-modal-spinner", MModalSpinner);

import MModalCookies from "../components/MModalCookies";
Vue.component("m-modal-cookies", MModalCookies);

import MFormPassword from "../components/MFormPassword";
Vue.component("m-form-password", MFormPassword);
