<template>
  <div class="form-group">
    <label class="label" :class="labelClass" :for="id2" v-if="label">{{ label }}</label>
    <div class="input-group">
      <div class="input-group-prepend" v-if="prependText">
        <span class="input-group-text" v-html="prependText"></span>
      </div>
      <v-select
        ref="myinput"
        v-model="value2"
        label="text"
        :options="items"
        :disabled="disabled"
        style="width: 100%; background-color: #ffffff7a; border-radius: 5px"
        :style="dark ? 'background-color:#ffffff7a; border-radius:5px;' : ''"
        @search:focus="onfocus"
      ></v-select>
      <div v-if="placeholderData" class="placeholder">{{ placeholder }}</div>
      <!-- <m-form-text
      ref="myinput"
      data-toggle="dropdown"
      :id="id"
      v-model="rawvalue"
      type="text"
      :name="name"
      :autocomplete="autocomplete"
      :readonly="readonly"
      :disabled="disabled"
      :autoSelectOnFocus="autoSelectOnFocus"
      :label="label"
      :tooltip="tooltip"
      :tooltipPosition="tooltipPosition"
      :rules="rules"
      :placeholder="placeholder"
      appendIcon="arrow-down"
      :prependIcon="prependIcon"
      :appendText="appendText"
      :subText="subTextValue"
      :prependText="prependText"
      :ariaHaspopup="true"
      :ariaExpanded="showDropdown"
      @click="onclick"
      @input="oninput"
      @focus="onfocus"
      @keyup="onkeyup"
      @ShowDropdownMenu="ShowDropdownMenu"
    ></m-form-text>

    <div
      role="menu"
      class="dropdown-menu"
      ref="mydropdown"
      v-show="showDropdown"
      style="display:block;"
      @focus="onfocus"
    >
      <a
        v-for="(item, i) in items"
        :key="item.value"
        :class="{
          'dropdown-item': true,
          focusDropdown: focusDropdown && focusDropdown.index === i
        }"
        role="menuitem"
        @click="setValue(item)"
        @focus="setValue(item)"
      >
        {{ item.text }}
      </a>
    </div> -->
      <div class="input-group-prepend" v-if="appendText || appendIcon">
        <span v-if="appendText" class="input-group-text" v-html="appendText"></span>
        <span v-if="appendIcon" class="input-group-text">
          <icon class="icon-menu" :name="appendIcon"></icon>
        </span>
      </div>
      <div :id="id2" class="invalid-feedback" v-if="errormsg2">
        {{ errormsg2 }}
      </div>
    </div>
    <small class="sub-text form-text text-muted" v-if="subText" v-html="subText"></small>
  </div>
</template>

<script>
// import { createPopper } from "@popperjs/core";
// import MFormBase from "./MFormBase";
// import { createPopper } from "@popperjs/core";

export default {
  name: "mformselect",
  // extends: MFormBase,
  props: {
    value: [String, Number, Object],
    id: {
      default: "",
      type: String
    },
    dark: {
      default: false,
      type: Boolean
    },
    name: {
      default: "",
      type: String
    },
    autocomplete: {
      default: "new-password",
      type: String
    },
    readonly: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    autoSelectOnFocus: {
      default: true,
      type: Boolean
    },
    label: {
      default: "",
      type: String
    },
    tooltip: {
      default: "",
      type: String
    },
    tooltipPosition: {
      default: "bottom",
      type: String
    },
    rules: {
      default: function () {
        return [];
      },
      type: Array
    },
    placeholder: String,
    prependIcon: String,
    appendIcon: String,
    prependText: String,
    appendText: String,
    subText: String,
    items: {
      default: function () {
        return [];
      },
      type: Array
    },
    multiple: {
      default: false,
      type: Boolean
    }
  },
  mounted() {
    if (!this.id2) this.id2 = this.$Utils.randomstring();
    this.$nextTick(() => {
      // this.popper = createPopper(
      //   this.$refs.myinput.$el,
      //   this.$refs.mydropdown,
      //   {
      //     placement: "bottom-start"
      //   }
      // );
    });
    //window.addEventListener("click", this.onWindowClick);
  },
  /*  destroyed() {
    window.removeEventListener("click", this.onWindowClick);
  }, */

  data() {
    // let name2 = this.name ? this.name : "";
    let rawvalue = this.findItem(this.value).text;
    let subTextValue;
    if (this.findItem(this.value).subtext) {
      subTextValue = this.findItem(this.value).subtext;
    } else {
      subTextValue = this.subText;
    }
    this.findItem(this.value).subtext;
    let value2 = this.findItem(this.value);
    return {
      id2: this.id,
      // name2,
      value2,
      rawvalue,
      subTextValue,
      errormsg2: "",
      showDropdown: false,
      focus: null,
      focusDropdown: null,
      placeholderData: this.value ? false : true
    };
  },
  computed: {
    labelClass() {
      let classTxt = "";
      if (this.$_.isPlainObject(this.value2) && ((!this.value2.value && this.value2.value === 0) || !this.value2.value)) {
        return this.value2.value === 0 ? "label_focus" : classTxt;
      }
      classTxt += this.focus || (!this.value2.value && this.value2.value === 0) || this.value2.value ? "label_focus" : "";
      return classTxt;
    }
  },
  watch: {
    rawvalue: function (val) {
      this.$emit("search", val);
      if (val == "") this.$emit("input", "");
    },
    value2: function (val) {
      this.placeholderData = val ? false : true;
      let v = null;
      if (val) v = val.value;
      this.$emit("input", v);
    },
    value: function (val) {
      this.rawvalue = this.findItem(val).text;
      this.value2 = this.findItem(val);
      this.subTextValue = this.findItem(this.value).subtext;
    },
    focusDropdown: function (val) {
      this.rawvalue = val.item.text;
    },
    items: function (val) {
      // this.$nextTick(() => {
      //   this.popper.update();
      // });
    }
  },
  components: {},
  methods: {
    ShowDropdownMenu(showDropdown) {
      this.showDropdown = showDropdown;
      // this.popper.update();
    },
    informValid() {
      this.errormsg2 = "";
    },
    informInvalid(txt) {
      this.errormsg2 = txt;
    },
    validate() {
      let oks = [];
      for (let iRule = 0; iRule < this.rules.length; iRule++) {
        const rule = this.rules[iRule];
        let ok = rule(this.value2 ? this.value2.value : null);
        if (this.$_.isString(ok)) oks.push(ok);
      }
      if (oks.length == 0) {
        this.informValid();
        return true;
      }
      this.informInvalid(oks.join(","));
      return false;
    },
    oninput(val) {
      this.rawvalue = val;
    },
    onkeyup(evt) {
      switch (evt.keyCode) {
        case 40: // flèche du bas
          evt.preventDefault();
          if (!this.focusDropdown) {
            this.focusDropdown = { item: this.items[0], index: 0 };
          } else {
            let newIndex = this.focusDropdown.index + 1;
            if (this.items[newIndex]) {
              this.focusDropdown = {
                item: this.items[newIndex],
                index: newIndex
              };
            } else {
              this.focusDropdown = { item: this.items[0], index: 0 };
            }
          }
          break;
        case 38: // flèche du haut
          evt.preventDefault();
          if (!this.focusDropdown) {
            this.focusDropdown = {
              item: this.items[this.items.length - 1],
              index: this.items.length - 1
            };
          } else {
            let newIndex = this.focusDropdown.index - 1;
            if (this.items[newIndex]) {
              this.focusDropdown = {
                item: this.items[newIndex],
                index: newIndex
              };
            } else {
              this.focusDropdown = {
                item: this.items[this.items.length - 1],
                index: this.items.length - 1
              };
            }
          }
          break;
        case 13: // entrer
          evt.preventDefault();
          this.setValue(this.focusDropdown.item);
          this.ShowDropdownMenu(false);
          break;
      }
      this.$emit("onkeyup", evt);
    },
    onfocus(evt) {
      // console.log("ofcus");
      let ref = this.$refs.myinput ? this.$refs.myinput : "noInput";
      this.focus = ref;
      this.$emit("focus", evt);
    },
    onclick(evt) {
      this.$emit("click", evt);
    },
    findItem(val) {
      let f = this.$_.find(this.items, { value: this.value });
      if (!f) f = { value: "", text: "" };
      return f;
    },
    setValue(item) {
      this.value2 = item;
      this.validate();
      this.$emit("input", item.value);
    }
  }
};
</script>
<style lang="scss">
.input-group {
  //background-color: #fff;
  //padding-bottom: 8px;
}
.vs__dropdown-toggle {
  padding-bottom: 8px;
  border-radius: 3px;
}
.placeholder {
  position: absolute;
  top: 7px;
  left: 10px;
  color: #6c757d;
}
/* .focusDropdown {
  background-color: #f8f9fa;
}
.input-group-text {
  border: 0;
  padding: 0;
  background-color: #f8f9fa00;
}
.vs__dropdown-toggle {
  border: 0;
  border-bottom: 1px solid #999999;
  border-radius: 0;
}
.vs__selected-options {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
} */
</style>
