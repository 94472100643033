<template>
  <div>
    <m-modal-spinner
      v-if="spinner"
      :addcancel="true"
      explain="Chargement...<br>LOIC est un outil d’aide et n’est pas destiné au diagnostic ni à a la planification détaillée du traitement des patients"
      @cancel="cancelSend"
    ></m-modal-spinner>
    <euros-header titre="Ajouter une radio de la hanche" pathRetour="/patients"></euros-header>
    <div class="container-fluid bg-blue d-flex flex-column main">
      <div class="d-none d-md-block flex-grow-0">
        <div class="row pt-2">
          <div class="col-sm-3 d-flex align-items-center justify-content-between justify-content-sm-start mb-2 mb-sm-0">
            <div>
              <img style="width: 120px" class="img-fluid" src="/images/logo-eurotxt-black.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex-grow-1 d-flex align-items-center justify-content-center">
        <div class="row">
          <div class="col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
            <div class="mr-5">
              <div @click="changefile" class="container-icon d-flex align-items-center justify-content-center">
                <icon width="60" height="60" color="gray" name="file-image" class="mr-2"></icon>
                <input hidden type="file" ref="inputfile" name="inputfile" accept="image/*, application/dicom" @change="inputFileChange($event)" />
              </div>
              <div class="bis text-center mt-3">Choisir un fichier</div>
            </div>
          </div>
          <div class="col-md-6 mt-2 d-flex align-items-center justify-content-center justify-content-md-start">
            <div class="ml-5">
              <div class="bis">Le fichier peut être au format :</div>
              <ul style="margin-left: -20px">
                <li class="bis">.DICOM</li>
                <li class="bis">.JPEG / .JPG</li>
                <li class="bis">.PNG</li>
              </ul>
              <div @click="help" class="mt-3 aide pointer color-white d-flex flex-row align-items-left justify-content-start">
                <div class="d-none d-sm-block"><a href="javascript:void(0);">Télécharger l'aide</a></div>
                <icon width="22" height="22" name="question-circle" class="ml-2" color="#c6091f"></icon>
              </div>
            </div>
          </div>

          <!--         <div class="col-md-5 offset-md-1 py-5 d-flex align-items-center">
        
           <div class="container-icon d-flex align-items-center justify-content-center">
            <icon width="60" height="60" color="gray" name="camera-retro" class="mr-2"></icon>
          </div>
          <div class="bis text-center mt-3">
            Prendre une photo
          </div>
        </div> -->
        </div>
      </div>
    </div>

    <div class="winmodal" v-if="$store.state.help.chooseimage"></div>
    <div class="helpwin" v-if="$store.state.help.chooseimage">
      <div class="winclose">
        <label for="notdisplayanymore" style="margin: -5px 30px"
          ><input type="checkbox" id="notdisplayanymore" v-model="notdisplayanymore" />Ne plus afficher</label
        >
        <a href="javascript:void(0);" @click="$store.commit('set_help', ['chooseimage', notdisplayanymore])"
          ><icon width="24" height="24" name="times" class="" color="black"></icon
        ></a>
      </div>
      <div class="row">
        <div class="col-md-8">
          <h3>Cliquez sur le bouton Choisir un fichier et le récupérer sur votre ordinateur.</h3>
          <p>Le fichier peut être un JPEG JPG, un PNG ou un DICOM</p>
          <div>
            <button class="btn btn-primary" @click="clickHelp">CHOISIR UN FICHIER</button>
          </div>
        </div>
        <div class="col-md-4">
          <img src="/images/aide/02-choisir-fichier.png" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "chooseimage",
  components: {},

  data() {
    return {
      notdisplayanymore: false,
      spinner: false,
      cancelTokenSource: null
      // fileSelected: {}
    };
  },
  async mounted() {
    this.cancelTokenSource = null;
    this.$store.dispatch("addHistoryUrl", this.$route.path);
  },
  watch: {},
  computed: {},
  methods: {
    clickHelp() {
      this.$store.commit("set_help", ["chooseimage", this.notdisplayanymore]);
      this.$refs.inputfile.click();
    },
    help() {
      window.open(this.$config.server_url + "/api/1.0/files/help" + "?token=" + this.$store.state.accesstoken + "&origin=webapp");
    },
    cancelSend() {
      this.spinner = false;
      if (this.cancelTokenSource) this.cancelTokenSource.cancel();
      // this.$router.push("/patients");
    },
    changefile() {
      this.$refs.inputfile.click();
    },
    async inputFileChange(e) {
      let maxWait = 3000,
        time = new Date();
      if (!e.target.files.length) return;

      this.spinner = true;
      let file = e.target.files[0];
      let formdata = new FormData();
      formdata.append("file", file);

      try {
        this.cancelTokenSource = this.$axios.CancelToken.source();
        let response = await this.$axios.post(this.$config.server_url + "/api/1.0/files", formdata, {
          cancelToken: this.cancelTokenSource.token,
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        let towait = maxWait - (new Date() - time);
        // console.log("towait", towait);
        if (towait > 0) {
          await new Promise(accept => {
            window.setTimeout(() => {
              accept();
            }, towait);
          });
        }
        // console.log("time-new Date()", new Date() - time);

        if (response.data.data.ext == ".dcm") this.$router.push("/prosthesis/" + response.data.data.patientId + "/1").catch(err => {});
        else this.$router.push("/ratio/" + response.data.data.patientId).catch(err => {});
      } catch (error) {
        alert("Le format de l'image n'est pas pris en charge.");
        this.spinner = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container-icon {
  background-color: #fff;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: auto;
}
.bg-blue {
  height: 100vh;
}
</style>
