<template>
  <div class="overflow-hidden" ref="body">
    <euros-header ref="header" titre="Renseignez les infos du patient" pathRetour="/patients"></euros-header>
    <div class="container-fluid bg-blue main">
      <div class="main3parts d-flex" ref="main3parts">
        <div class="main3parts-col1 d-none flex-column d-lg-flex" style="flex-grow: 0">
          <div style="flex-grow: 1">
            <img style="width: 120px" class="img-fluid d-none d-lg-block" src="/images/logo-eurotxt-black.png" alt="" />
          </div>
          <div style="flex-grow: 0">
            <button @click="previousStep" class="btn btn-primary w-100">Précédent</button>
          </div>
        </div>
        <div class="main3parts-col2 relative bg-dark" ref="gestoparent" style="flex-grow: 1">
          <div style="height: 100%; position: relative" ref="gesto">
            <div style="position: absolute" ref="bgimage">
              <img
                ref="imgRadio"
                class=""
                :src="$config.server_url + '/api/1.0/files/patient/' + $route.params.id + '?token=' + $store.state.accesstoken + '&origin=webapp'"
                alt="test"
                @load="imageLoaded"
              />
            </div>
          </div>
        </div>
        <div class="main3parts-col3 flex-column d-none d-lg-flex">
          <div class="contenu-col3" style="flex-grow: 1">
            <p class="text-always-small mb-0 text-justify">
              Le logiciel a reconnu tout le texte inscrit sur la radio. Cliquez sur les menus déroulants et sélectionnez les textes qui correspondent
              au nom et à la date de naissance du patient.
            </p>
            <p class="mb-0 mt-2 text-always-small text-justify">
              Si le programme ne reconnait pas l'info, vous pouvez l'écrire dans le champs en dessous
            </p>
            <div class="formulaire mt-3">
              <label class="mt-2 mb-0" for="name">Nom *</label>
              <select class="custom-select mt-2 w-100" v-model="ocr_name" @change="ocrChange('name')">
                <option v-for="(option, index) in itemOcr" :value="option.value" :key="'name_' + index">
                  {{ option.text }}
                </option>
              </select>
              <!-- <m-form-select label="Nom" :items="itemOcr" v-model="ocr_name" @input="ocrChange('name')"></m-form-select> -->
              <m-form-text label="" v-model="patient.pa_name"></m-form-text>
            </div>

            <div class="formulaire">
              <label class="mt-2 mb-0" for="">Date de naissance</label>
              <select class="custom-select mt-2 w-100" v-model="ocr_birthdate" @change="ocrChange('birthdate')">
                <option v-for="(option, index) in itemOcr" :value="option.value" :key="'date_' + index">
                  {{ option.text }}
                </option>
              </select>
              <!-- <m-form-select label="Date de naissance" :items="itemOcr" v-model="ocr_birthdate" @input="ocrChange('birthdate')"></m-form-select> -->
              <m-form-text label="" v-model="patient.pa_birthdate" placeholder="jj/mm/aaaa"></m-form-text>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="mt-2 mb-0" for="">Poids (en kg)</label>
                <m-form-text type="number" v-model="patient.pa_weight"></m-form-text>
              </div>
              <div class="col-6">
                <label class="mt-2 mb-0" for="">Taille (en cm)</label>
                <m-form-text type="number" v-model="patient.pa_height"></m-form-text>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="mt-2 mb-0" for="">Sexe</label>
                <select class="custom-select mt-0" v-model="patient.pa_sex">
                  <option v-for="(option, index) in itemsSex" :value="option.value" :key="'sex_' + index">
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div class="col-6">
                <label class="mt-2 mb-0" for="">Date de chirurgie</label>
                <m-form-text label="" v-model="patient.pa_dateoperation" placeholder="jj/mm/aaaa"></m-form-text>
              </div>
            </div>
          </div>
          <div class="text-right mt-4" style="flex-grow: 0">
            <button @click="nextStep" class="btn btn-primary btn-next w-100">Suivant</button>
          </div>
        </div>
        <!-- pour les tailles de mobile -->
        <div @click="showOrhideMenu" class="btn-menu-mobile d-block d-lg-none" ref="btnMenuMobile">
          <icon :name="isShowMenu ? 'chevron-right' : 'chevron-left'" width="15" height="15"></icon>
        </div>
        <div ref="menuMobile" class="menuMobile d-block d-lg-none">
          <p class="mb-0 text-small">
            Reconnaissance de texte : sélectionnez le nom et à la date de naissance du patient. Ou écrivez l'info dans le champ en dessous
          </p>
          <div class="">
            <label class="mt-2 mb-0" for="name">Nom *</label>
            <select class="custom-select mt-0" v-model="ocr_name" @change="ocrChange('name')">
              <option v-for="(option, index) in itemOcr" :value="option.value" :key="'nameM_' + index">
                {{ option.text }}
              </option>
            </select>
            <!-- <m-form-select label="Nom" :items="itemOcr" v-model="ocr_name" @input="ocrChange('name')"></m-form-select> -->
            <m-form-text label="" v-model="patient.pa_name"></m-form-text>
          </div>
          <div class="">
            <label class="mt-2 mb-0" for="">Date de naissance</label>
            <select class="custom-select mt-0" v-model="ocr_birthdate" @change="ocrChange('birthdate')">
              <option v-for="(option, index) in itemOcr" :value="option.value" :key="'dateM_' + index">
                {{ option.text }}
              </option>
            </select>
            <!-- <m-form-select label="Date de naissance" :items="itemOcr" v-model="ocr_birthdate" @input="ocrChange('birthdate')"></m-form-select> -->
            <m-form-text label="" v-model="patient.pa_birthdate" placeholder="jj/mm/aaaa"></m-form-text>
          </div>
          <div class="row">
            <div class="col" style="padding-right: 5px">
              <label class="mt-2 mb-0" for="">Poids (en kg)</label>
              <!-- <select class="custom-select mt-2" v-model="ocr_weight" @change="ocrChange('weight')">
                <option v-for="(option, index) in itemOcr" :value="option.value" :key="'dateM_' + index">
                  {{ option.text }}
                </option>
              </select> -->
              <m-form-text class="mt-0" type="number" v-model="patient.pa_weight"></m-form-text>
            </div>
            <div class="col" style="padding-left: 5px">
              <label class="mt-2 mb-0" for="">Taille (en cm)</label>
              <!-- <select class="custom-select mt-2" v-model="ocr_height" @change="ocrChange('height')">
                <option v-for="(option, index) in itemOcr" :value="option.value" :key="'dateM_' + index">
                  {{ option.text }}
                </option>
              </select> -->
              <m-form-text class="mt-0" type="number" v-model="patient.pa_height"></m-form-text>
            </div>
          </div>
          <div class="row">
            <div class="col" style="padding-right: 5px">
              <label class="mt-2 mb-0" for="">Sexe</label>
              <select class="custom-select mt-0" v-model="patient.pa_sex">
                <option v-for="(option, index) in itemsSex" :value="option.value" :key="'sex_' + index">
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="col" style="padding-left: 5px">
              <label class="mt-2 mb-0" for="">Date de chirurgie</label>
              <m-form-text class="mt-0" type="text" v-model="patient.pa_dateoperation"></m-form-text>
            </div>
          </div>
          <div class="mt-2 d-flex">
            <div class="flex-fill pr-2"><button @click="previousStep" class="btn btn-primary btn-sm w-100">Précédent</button></div>
            <div class="flex-fill pl-2"><button @click="nextStep" class="btn btn-primary btn-sm w-100">Suivant</button></div>
          </div>
        </div>
      </div>
    </div>
    <m-message-dialog v-model="messageErreur" title="Erreur" :text="messageErreurTxt"></m-message-dialog>
  </div>
</template>

<script>
// import Moveable from "moveable";
// import Gesto from "gesto";
// import InfiniteViewer from "infinite-viewer";
import Hammer from "hammerjs";

export default {
  name: "infos",
  components: {},
  props: {},
  data() {
    return {
      scaleStart: -1,
      file: {},
      patient: {},
      bgimage: {},
      getso: {},
      style: "",
      tx: 0,
      ty: 0,
      scale: 1,
      isShowMenu: true,
      itemOcr: [],
      ocr_birthdate: "",
      ocr_name: "",
      // ocr_weight: "",
      // ocr_height: "",
      messageErreurTxt: "",
      messageErreur: false,
      itemsSex: [
        { value: 0, text: "-" },
        { value: 1, text: "Homme" },
        { value: 2, text: "Femme" }
      ]
    };
  },
  beforeDestroy() {
    // window.removeEventListener("keyup", this.onkeyup);
    window.removeEventListener("resize", this.setWindowHeight);
    window.removeEventListener("gesturestart", this.preventDefaultPerso);
    window.removeEventListener("gesturechange", this.preventDefaultPerso);
    window.removeEventListener("gestureend", this.preventDefaultPerso);
    window.removeEventListener("wheel", this.preventDefaultPerso, { passive: false });
  },
  async mounted() {
    this.setWindowHeight();
    this.$store.dispatch("addHistoryUrl", this.$route.path);
    await this.loadPatient();
    this.loadOcr();
    window.addEventListener("resize", this.setWindowHeight);
    window.addEventListener("gesturestart", this.preventDefaultPerso);
    window.addEventListener("gesturechange", this.preventDefaultPerso);
    window.addEventListener("gestureend", this.preventDefaultPerso);
    window.addEventListener("wheel", this.preventDefaultPerso, { passive: false });
    this.$refs.bgimage.addEventListener("mousedown", this.preventDefaultPerso);

    // let target2 = this.$refs.gesto;
    let clickPosX = 0,
      clickPosY = 0;
    let startMoveGesto = false;
    this.addMultipleEventsListener(this.$refs.gestoparent, "mousedown touchstart", e => {
      // console.log("mousedown", e);
      if (e.type == "touchstart") e = e.touches[0];
      startMoveGesto = true;
      clickPosX = e.clientX;
      clickPosY = e.clientY;
      // console.log("clickPosX,clickPosY", clickPosX, clickPosY);
    });
    this.addMultipleEventsListener(window, "mouseup touchend", e => {
      // console.log("mouseup");
      startMoveGesto = false;
    });
    this.addMultipleEventsListener(this.$refs.gestoparent, "mousemove touchmove", e => {
      //  console.log("e.deltaX", e);
      if (e.type == "touchmove") e = e.touches[0];
      if (startMoveGesto) {
        // let wContainer = this.$refs.gestoparent.offsetWidth,
        //   hContainer = this.$refs.gestoparent.offsetHeight;
        this.tx += (e.clientX - clickPosX) * (1 / this.scale);
        this.ty += (e.clientY - clickPosY) * (1 / this.scale);
        clickPosX = e.clientX;
        clickPosY = e.clientY;
        // toW = wContainer / 2 - this.tx;
        // toH = hContainer / 2 - this.ty;
        // console.log("mousemove", startMoveGesto, tx, ty, scale);
        // console.log("this.$refs.gesto.style.transform", this.$refs.gesto.style.transform);
        this.drawElements();
      }
    });

    let onzoom = e => {
      // let previousScale = scale;
      if (e.scale) this.scale = this.scaleStart + e.scale - 1;
      else this.scale -= e.deltaY / 800;
      if (this.scale < 0.01) this.scale = 0.01;
      // console.log("scale, e.deltaX", scale, scale - previousScale);
      // this.$refs.gesto.style.transformOrigin = `${toW}px ${toH}px`;
      // this.$refs.gesto.style.transform = `translate(${this.tx}px, ${this.ty}px) scale(${this.scale})`;
      this.drawElements();
      e.preventDefault();
    };

    this.$refs.gestoparent.addEventListener("wheel", onzoom);
    var hammertime = new Hammer(this.$refs.gestoparent, {});
    hammertime.get("pinch").set({ enable: true });
    hammertime.on("pinch", onzoom);
    hammertime.on("pinchstart", () => {
      this.scaleStart = this.scale;
    });
    this.$nextTick(() => {
      if (this.$refs.menuMobile) {
        let height = this.$refs.menuMobile.clientHeight;
        if (height > 0) {
          let heightString = height + "px";
          let heightWin = this.$refs.body.clientHeight;
          if (height > heightWin) this.$refs.btnMenuMobile.style.bottom = heightWin - 34 + "px";
          else this.$refs.btnMenuMobile.style.bottom = heightString;
        }
      }
    });
  },
  // watch: {
  //   scale: function() {
  //     // console.log("scale", this.scale);
  //   }
  // },
  computed: {},
  methods: {
    setWindowHeight() {
      this.$refs.main3parts.style.minHeight = document.documentElement.clientHeight - this.$refs.header.$refs.header.offsetHeight + "px";
    },
    showOrhideMenu() {
      let wContainer = this.$refs.menuMobile.offsetWidth + 15;
      if (this.isShowMenu) {
        this.$refs.menuMobile.style.transform = `translate(${wContainer}px, 0)`;
      } else {
        this.$refs.menuMobile.style.transform = `translate(0, 0)`;
      }
      this.isShowMenu = !this.isShowMenu;
    },
    preventDefaultPerso(event) {
      event.preventDefault();
    },
    drawElements() {
      let wContainer = this.$refs.gestoparent.offsetWidth,
        hContainer = this.$refs.gestoparent.offsetHeight;
      let toW = wContainer / 2 - this.tx;
      let toH = hContainer / 2 - this.ty;
      this.$refs.gesto.style.transformOrigin = `${toW}px ${toH}px`;
      this.$refs.gesto.style.transform = `translate(${this.tx}px, ${this.ty}px) scale(${this.scale})`;
    },
    centerBgImage() {
      let wContainer = this.$refs.gestoparent.offsetWidth,
        hContainer = this.$refs.gestoparent.offsetHeight,
        wImage = this.$refs.bgimage.offsetWidth,
        hImage = this.$refs.bgimage.offsetHeight;
      if (wContainer / wImage > hContainer / hImage) {
        this.scale = hContainer / hImage;
        this.tx = (wContainer - wImage) / 2;
        this.ty = (hImage * this.scale - hImage) / 2;
      } else {
        this.scale = wContainer / wImage;
        this.ty = (hContainer - hImage) / 2;
        this.tx = (wImage * this.scale - wImage) / 2;
      }
      // console.log("this.tx", this.tx, wContainer, wImage, (wContainer - hContainer) / 2);
      // let toW = wContainer / 2 - this.tx;
      // let toH = hContainer / 2 - this.ty;
      // this.$refs.gesto.style.transformOrigin = `${toW}px ${toH}px`;
      // this.$refs.gesto.style.transform = `translate(${this.tx}px, ${this.ty}px) scale(${this.scale})`;
      this.drawElements();
    },
    imageLoaded(e) {
      this.$nextTick(() => {
        this.drawElements();
        this.centerBgImage();
        // console.warn("image loaded");
      });
    },
    addMultipleEventsListener(el, s, fn) {
      s.split(" ").forEach(e => el.addEventListener(e, fn, false));
    },
    async loadPatient() {
      // load patient pour récupérer les pa_tools
      let response = await this.$axios.get(this.$config.server_url + "/api/1.0/patients/" + this.$route.params.id);
      this.patient = response.data.data;
      let pa_birthdate = "",
        pa_dateoperation = "";
      if (this.patient.pa_birthdate !== "0000-00-00" && this.$dayjs(this.patient.pa_birthdate, "YYYY-MM-DD").isValid())
        pa_birthdate = this.$dayjs(this.patient.pa_birthdate, "YYYY-MM-DD").format("DD/MM/YYYY");
      if (this.patient.pa_dateoperation !== "0000-00-00" && this.$dayjs(this.patient.pa_dateoperation, "YYYY-MM-DD").isValid())
        pa_dateoperation = this.$dayjs(this.patient.pa_dateoperation, "YYYY-MM-DD").format("DD/MM/YYYY");
      this.patient.pa_birthdate = pa_birthdate;
      if (!pa_dateoperation) pa_dateoperation = this.$dayjs().format("DD/MM/YYYY");
      this.patient.pa_dateoperation = pa_dateoperation;
      this.drawElements();
      if (!this.patient.pa_toolstx) this.centerBgImage();
    },
    async loadOcr() {
      let response = await this.$axios.post(this.$config.server_url + "/api/1.0/ocr/" + this.$route.params.id);
      this.itemOcr = response.data.data.texts;
      this.itemOcr.unshift({ text: "Textes trouvés : ", value: "Textes trouvés : " });
      let tabField = ["name", "birthdate", "weight", "height"];
      for (let ife = 0; ife < tabField.length; ife++) {
        const field = tabField[ife];
        if (!this.patient["pa_" + field]) {
          this["ocr_" + field] = this.itemOcr[0].text;
        }
      }
    },
    ocrChange(type) {
      //console.log("type", type);
      if (type === "birthdate") this.$dayjs(this.ocr_birthdate, "YYYY-MM-DD");
      //console.log("val", this["ocr_" + type]);
      this.patient["pa_" + type] = this["ocr_" + type];
      // console.log("this.patient", this.patient);
    },
    /************** les événements au clavier ***********   */
    // onkeyup(event) {
    //   if (event.key === "ArrowLeft" || event.key === "ArrowRight" || event.key === "ArrowUp" || event.key === "ArrowDown")
    //     this.$refs.bgimage.style.transform = this.translate(event.key, this.$refs.bgimage.style.transform);
    //   if (event.key === "+" || event.key === "-") this.$refs.bgimage.style.transform = this.scaleimg(event.key, this.$refs.bgimage.style.transform);
    // },
    // scaleimg(zoom, style) {
    //   if (!style) style = "scale(1)";
    //   let newStyle = "scale(";
    //   let scale = style.split("(")[1].split(")")[0] * 1;
    //   if (zoom === "+") scale += 0.01;
    //   if (zoom === "-") scale -= 0.01;
    //   newStyle += scale + ")";
    //   return newStyle;
    // },
    // translate(direction, style) {
    //   if (!style) style = "translate(0px, 0px)";
    //   const regex1 = /matrix/;
    //   let tabPx = [];
    //   if (regex1.test(style)) {
    //     let indexOf = style.indexOf("translate");
    //     style = style.substr(indexOf);
    //   }
    //   tabPx = style
    //     .split("(")[1]
    //     .split(")")[0]
    //     .split(",");

    //   if (direction === "ArrowLeft") tabPx[0] = tabPx[0].replace("px", "") * 1 - 1 + "px";
    //   if (direction === "ArrowRight") tabPx[0] = tabPx[0].replace("px", "") * 1 + 1 + "px";
    //   if (direction === "ArrowUp") tabPx[1] = tabPx[1].replace("px", "") * 1 - 1 + "px";
    //   if (direction === "ArrowDown") tabPx[1] = tabPx[1].replace("px", "") * 1 + 1 + "px";
    //   let newStyle = "translate(";
    //   newStyle += tabPx.join(",");
    //   newStyle += ")";
    //   return newStyle;
    // },
    async previousStep() {
      let ok = await this.savePatient("prev");
      if (!ok) return;
      this.$router.push("/prosthesis/" + this.$route.params.id + "/2").catch(err => {});
    },
    async nextStep() {
      let ok = await this.savePatient("next");
      if (!ok) return;
      this.$router.push("/result/" + this.$route.params.id).catch(err => {});
    },
    async savePatient(nextOrPrev) {
      // vérification des champs
      this.messageErreurTxt = "";
      let ok = true;
      if (!this.patient.pa_name && nextOrPrev === "next") {
        ok = false;
        this.messageErreurTxt += "Le nom est obligatoire";
        this.messageErreur = true;
      }
      let pa_birthdate = "0000-00-00",
        pa_dateoperation = "0000-00-00";
      if (this.patient.pa_birthdate) {
        pa_birthdate = this.$dayjs(this.patient.pa_birthdate, "DD[/]MM[/]YYYY", "fr");
        if (!pa_birthdate.isValid()) {
          ok = false;
          this.messageErreurTxt += "<br/>Date de naissance non valide. Le bon format est : JJ/MM/AAAA";
          this.messageErreur = true;
        }
        pa_birthdate = pa_birthdate.format("YYYY-MM-DD");
      }
      if (this.patient.pa_dateoperation) {
        pa_dateoperation = this.$dayjs(this.patient.pa_dateoperation, "DD[/]MM[/]YYYY", "fr");
        if (!pa_dateoperation.isValid()) {
          ok = false;
          this.messageErreurTxt += "<br/>Date de chirurgie non valide. Le bon format est : JJ/MM/AAAA";
          this.messageErreur = true;
        }
        pa_dateoperation = pa_dateoperation.format("YYYY-MM-DD");
      }
      if (ok) {
        //on enregistre le nom et la date de naissance
        let dataPaTools = {
          pa_name: this.patient.pa_name,
          pa_birthdate,
          pa_dateoperation,
          pa_weight: this.patient.pa_weight,
          pa_height: this.patient.pa_height,
          pa_sex: this.patient.pa_sex
        };
        await this.$axios.put(this.$config.server_url + "/api/1.0/patients/" + this.$route.params.id, dataPaTools);
      }
      return ok;
    }
  }
};
</script>

<style scoped lang="scss">
.container-img-radio {
  overflow: hidden;
}
.main3parts {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 -15px;
  .main3parts-col1 {
    padding: 0 15px;
  }
  .main3parts-col2 {
    overflow: hidden;
  }
  .main3parts-col3 {
    padding: 0 15px;
  }
}
@media (min-width: 576px) {
  // .part3-mobile {
  //   width: 40%;
  // }
}
@media (min-width: 768px) {
  .main3parts {
    flex-direction: row;
    .main3parts-col1 {
      min-width: 20%;
      padding: 15px;
      margin: 0;
    }
    .main3parts-col2 {
      min-width: 40%;
      margin: 0;
    }
    .main3parts-col3 {
      min-width: 40%;
      margin: 0;
      padding: 15px;
    }
  }
}
.custom-select {
  background-color: #ffffff85;
}
@media (min-width: 992px) {
  .part-name {
    min-width: 50%;
    margin: 0;
  }
  // .formulaire {
  //   width: 60%;
  // }
  .text-always-small {
    font-size: 14px;
  }
  .contenu-col3 {
    padding-left: 2em;
  }
  // .btn-next {
  //   width: 50% !important;
  // }
}
</style>
