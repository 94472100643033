import Vue from "vue";
import Vuex from "vuex";
//import SocketsListener from "./sockets.js";
import _ from "lodash";
import axios from "axios";
// import router from "./router";
// import config from "../config";
// import dayjs from "dayjs";

Vue.use(Vuex);
//SocketsListener.init(Vue.prototype.$socket, Vue.prototype);
let accesstoken = window.localStorage.getItem("accesstoken") ? window.localStorage.getItem("accesstoken") : "";
let refreshtoken = window.localStorage.getItem("refreshtoken") ? window.localStorage.getItem("refreshtoken") : "";
let de_code = window.localStorage.getItem("de_code") ? window.localStorage.getItem("de_code") : "";
let historyUrl = window.localStorage.getItem("historyUrl") ? window.localStorage.getItem("historyUrl") : "[]";

let helpDefault = { patients: true, chooseimage: true, ratio: true, prosthesis1: true, prosthesis2: true, result: true };
let help = _.clone(helpDefault);

help.patients = window.localStorage.getItem("help_patients") === "false" ? false : true;
help.chooseimage = window.localStorage.getItem("help_chooseimage") === "false" ? false : true;
help.ratio = window.localStorage.getItem("help_ratio") === "false" ? false : true;
help.prosthesis1 = window.localStorage.getItem("help_prosthesis1") === "false" ? false : true;
help.prosthesis2 = window.localStorage.getItem("help_prosthesis2") === "false" ? false : true;
help.result = window.localStorage.getItem("help_result") === "false" ? false : true;

try {
  historyUrl = JSON.parse(historyUrl);
} catch (error) {
  historyUrl = [];
}

let defaultUser = {
  co_id: "",
  co_name: "",
  co_civility: "",
  co_phone_mobile: "",
  co_phone_pro: "",
  co_email: "",
  co_type: ""
};
let user = _.cloneDeep(defaultUser);

export default new Vuex.Store({
  state: {
    isPwa: false,
    mainloading: false,
    currentcontact: null,
    cgu: { cg_text: "Les CGU", cg_version: "1.0", validated: false },
    help,
    // lastcoid: "",
    // mysocket: null,
    // mysocket_connected: false,
    connected: false,
    // box_connected: false,
    accesstoken,
    refreshtoken,
    de_code,
    user,
    preferences: {
      personal: {},
      shared: {},
      rights: {}
    },
    reload_actions: 0,
    inputOpened: null,
    dialogErr: false,
    dialogErrTxt: "",

    lang: "french",
    // socket_response: null,
    salt_md5: "M1&1#ZZKWOSX72S",
    inputOpened: null,
    historyUrl
  },
  mutations: {
    set_inputOpened(state, inputOpened) {
      state.inputOpened = inputOpened;
    },
    set_ispwa(state, ok) {
      state.isPwa = ok;
    },
    set_help(state, val) {
      state.help[val[0]] = false;
      if (val[1]) {
        window.localStorage.setItem("help_" + val[0], false);
      }
    },
    reset_help(state) {
      _.each(helpDefault, (v, k) => {
        console.log("k", k);
        window.localStorage.removeItem("help_" + k);
      });
    },
    /*     set_box_connected(state, val) {
      state.box_connected = val;
    }, */
    set_historyUrl(state, val) {
      if (val.length > 50) val = _.drop(val);
      state.historyUrl = val;
      window.localStorage.setItem("historyUrl", JSON.stringify(val));
    },
    set_currentcontact(state, val) {
      state.currentcontact = val;
    },
    set_mainloading(state, ok) {
      state.mainloading = ok;
    },
    /*     set_mysocket(state, socketData) {
      if (socketData.hasOwnProperty("socket")) state.mysocket = socketData.socket;
      state.mysocket_connected = socketData.state;
      state.mysocket_connected_to_room = socketData.room;
    }, */
    set_reload_actions(state) {
      state.reload_actions = Math.random();
    },
    set_tokens(state, what) {
      axios.defaults.headers.common["x-auth-accesstoken"] = what.accesstoken;
      window.localStorage.setItem("accesstoken", what.accesstoken);
      state.accesstoken = what.accesstoken;
      // window.localStorage.setItem("refreshtoken", what.refreshtoken);
      // state.refreshtoken = what.refreshtoken;
    },
    set_connexion(state, what) {
      // console.log("what", what);
      if (!what.accesstoken) {
        axios.defaults.headers.common["x-auth-accesstoken"] = "";
        window.localStorage.removeItem("accesstoken");
        state.accesstoken = null;
        window.localStorage.removeItem("refreshtoken");
        state.refreshtoken = null;
        window.localStorage.removeItem("user");
        state.user = _.cloneDeep(defaultUser);
        state.connected = false;
        state.items_rights_group = [];
        state.cgu = {};
        // state.de_code = "";
        //state.box_connected = false;
        return;
      }
      // console.log("token, refreshtoken, user", what.accesstoken, what.refreshtoken, what.user);
      axios.defaults.headers.common["x-auth-accesstoken"] = what.accesstoken;
      window.localStorage.setItem("accesstoken", what.accesstoken);
      state.accesstoken = what.accesstoken;
      // window.localStorage.setItem("refreshtoken", what.refreshtoken);
      // state.refreshtoken = what.refreshtoken;
      window.localStorage.setItem("user", JSON.stringify(what.user));
      state.user = _.cloneDeep(what.user);
      window.localStorage.setItem("de_code", what.de_code);
      state.de_code = what.de_code;
      // console.log("what.de_code", what.de_code);
      state.preferences = _.cloneDeep(what.preferences);
      // if (what.more && what.more.box_connected) state.box_connected = true;
      // else state.box_connected = false;
      // if (what.more) state.challenge_for_webapp = what.more.challenge_for_webapp;
      // state.items_rights_ressources = what.preferences.rights.acl_resources;
      // state.items_rights_roles = what.preferences.rights.acl_roles;
      // state.items_rights_group = what.preferences.rights.acl_groups;
      //console.log("what.preferences", what.preferences);
      _.each(what.preferences.items, (val, key) => {
        state[key] = what.preferences.items[key];
      });

      state.cgu = what.cgu;

      state.connected = true;
      // this.dispatch("load_nbclientsuivi");
      //this.dispatch("socketjoinroom");
    },
    // set_acl_groups(state, what) {
    //   state.items_rights_group = what;
    // },
    // set_preferences(state, data) {
    //   state.preferences[data.preference] = data.data;
    // },
    set_dialog_error(state, what) {
      // console.log("what", what);
      state.dialogErr = what.dialogErr;
      state.dialogErrTxt = what.dialogErrTxt;
    },
    set_error_login(state, val) {
      state.errorLogin = val;
    },
    set_lang(state, val) {
      state.lang = val;
    },
    set_cgu_validated(state, val) {
      state.cgu.validated = true;
    }
    /*     set_start_config(state, val) {
      state.start_config = val;
      if (!val) {
        router.push("/home").catch(err => {});
      }
    }, */
    /*     set_socket_response(state, response) {
      state.socket_response = response;
    },
    set_box_challenge(state, val) {
      state.user.en_id.bo_challenge = val;
    } */
  },
  actions: {
    async setversion() {
      let response = await axios.get(process.env.VUE_APP_SERVER_URL + "/api/1.0/version", {});
      let newversion = response.data.data;
      let currentversion = window.localStorage.getItem("versionserver");
      window.localStorage.setItem("versionserver", newversion);
      if (!currentversion || currentversion != newversion) window.location.reload();
      // console.log("v", v, response.data.data);
    },
    /*     initSocketEvents(ctx) {
      let socket = this._vm.$SocketIO;
      socket.on("connect", socket => {
        console.warn("SOCKET CONNECTED ACTION", socket);
        ctx.commit("set_mysocket", { socket: this._vm.$SocketIO, state: true, room: false });
        this.dispatch("socketjoinroom");
      });
      socket.on("/config/2.0/show/ok", response => {
        if (ctx.state.accesstoken !== response.accesstoken) return console.warn("/config/2.0/show/ok > challenge error");
        console.warn("SOCKET SHOW OK", response.cmd, response.data);
        if (response.cmd == "current-part") {
          if (response.data == "config") ctx.commit("set_start_config", true);
          else ctx.commit("set_start_config", false);
        }
        ctx.commit("set_socket_response", response);
        // this.dispatch("getDataOk", { cmd: response.cmd, data: response.data });
      });
      socket.on("/config/2.0/do/ok", response => {
        if (ctx.state.accesstoken !== response.accesstoken) return console.warn("/config/2.0/do/ok > challenge error");
        console.warn("SOCKET DO OK", response.cmd, response.data);

        ctx.commit("set_socket_response", response);
        // this.dispatch("setDataOk", { cmd: response.cmd, data: response.data });
      });
      socket.on("/config/2.0/update-box-state", response => {
        if (ctx.state.accesstoken !== response.accesstoken) return console.warn("/config/2.0/update-box-state > challenge error");
        console.warn("SOCKET update-box-state", response);
        // get the new bo_challenge
        ctx.commit("set_box_connected", response.box_connected);
        ctx.commit("set_boxstates", response);
        // if (response.box_connected) ctx.commit("set_box_challenge", response.bo_challenge);
      });

      socket.on("disconnect", () => {
        ctx.commit("set_mysocket", { socket: null, state: false, room: false });
      });
    }, */
    /*     getBoxState(ctx) {
      // /webapp/2.0/update-
      // accesstoken: ctx.state.accesstoken
      // console.log("getBoxState");
      ctx.state.mysocket.emit("/webapp/2.0/get-box-state", {
        accesstoken: ctx.state.accesstoken
      });
    }, */
    showDialogError(ctx, err) {
      ctx.commit("set_dialog_error", {
        dialogErr: err ? true : false,
        dialogErrTxt: err ? err : ""
      });
    },
    addHistoryUrl(ctx, url) {
      // console.log("url", url);
      let tab = ctx.state.historyUrl;
      if (tab[tab.length - 1] !== url) tab.push(url);
      // console.log("tab", tab);
      ctx.commit("set_historyUrl", tab);
    },
    deleteHistoryUrl(ctx) {
      let tab = ctx.state.historyUrl;
      tab.splice(ctx.state.historyUrl.length - 1, 1);
      // console.log("tab", tab);
      ctx.commit("set_historyUrl", tab);
    }
    /*     socketjoinroom(ctx, socket) {
      if (ctx.state.mysocket_connected && ctx.state.connected && ctx.state.user.bo_id) {
        console.warn("JOINING SOCKET ROOM");
        //let timestamp = Date.now();
        ctx.state.mysocket.emit(
          "/config/2.0/join",
          {
            accesstoken: ctx.state.accesstoken,
            //timestamp,
            bo_mac_address: ctx.state.user.bo_id.bo_mac_address
          },
          err => {
            if (err) {
              alert("Une erreur est survenue, nous allons recharger l'application");
              document.location.reload();
              return console.warn("SOCKET ROOM NOT JOINED");
            }
            console.warn("SOCKET ROOM JOINED");
            ctx.state.mysocket_connected_to_room = true;
          }
        );
      }
    } */
  },
  modules: {},
  getters: {}
});
