<template>
  <div>
    <euros-header :titre="`Résultats`" btnRetour="Liste" pathRetour="/patients"></euros-header>
    <div class="container-fluid bg-blue main">
      <div class="">
        <div class="row pt-2">
          <div class="col d-flex align-items-center justify-content-between mb-2 mb-sm-0">
            <div>
              <img style="width: 120px" class="img-fluid" src="/images/logo-eurotxt-black.png" alt="" />
            </div>
            <div class="ml-3 text-right">
              <!-- <button @click="newPatient" class="btn btn-primary btn-nouveau uppercase">Nouveau patient</button> -->
              <button @click="editProthesis" class="btn btn-primary mr-3">
                <icon width="16" height="16" name="arrow-circle-left" class="mr-2" color="" style="margin-bottom: -2px"></icon>Modifier
              </button>
              <button @click="print" class="btn btn-primary mr-3">Imprimer</button>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <h4 class="bis">{{ patient.pa_name }} <span class="date"></span></h4>
            <div>
              <span v-if="patient.pa_sex > 0"><span v-if="patient.pa_sex == 1">Homme</span><span v-if="patient.pa_sex == 2">Femme</span> | </span
              ><span v-if="patient.pa_weight">{{ patient.pa_weight }} Kg | </span><span v-if="patient.pa_height">{{ patient.pa_height }} cm | </span
              ><span v-if="patient.pa_birthdate != '0000-00-00'">Né(e) le {{ $dayjs(patient.pa_birthdate).format("DD/MM/YYYY") }}</span>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-4">
            <div class="mb-2">
              <table class="my-2 mx-2 table-delta">
                <thead>
                  <tr>
                    <th>Delta</th>
                    <th>Offset</th>
                    <th>Long.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Col S</td>
                    <td>{{ patient.pa_delta_min_x | formatDelta }}&nbsp;mm</td>
                    <td>{{ patient.pa_delta_min_y | formatDelta }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <td>Col M</td>
                    <td>{{ patient.pa_delta0_x | formatDelta }}&nbsp;mm</td>
                    <td>{{ patient.pa_delta0_y | formatDelta }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <!-- invertion des +3,5 et -3,5 normal voir issue 47 -->
                    <td>Col L</td>
                    <td>{{ patient.pa_delta_max_x | formatDelta }}&nbsp;mm</td>
                    <td>{{ patient.pa_delta_max_y | formatDelta }}&nbsp;mm</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="mt-2">
              <table class="my-2 mx-2 table-delta">
                <tbody>
                  <tr>
                    <td><strong>Poids</strong></td>
                    <td>{{ patient.pa_weight }}&nbsp;Kg</td>
                  </tr>
                  <tr>
                    <td><strong>Taille</strong></td>
                    <td>{{ patient.pa_height }}&nbsp;cm</td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>

          <div class="col-lg-8">
            <!-- <div class="alert alert-info">
              Ces cases à cocher peuvent être cochées suite à l’intervention, elles permettent d’établir des statistiques d’usages de l’application.
              Enregistrer ces préférences , en cochant cette case, vos futures utilisations intégreront par défaut ces références afin d’optimiser
              votre usage de l’application.
            </div> -->
            <h4>Cotyle et tige choisies</h4>

            <div
              class="line-product bt d-flex justify-content-between align-items-center"
              style="border-top: 0px solid white"
              v-if="patient.pa_cotyle"
            >
              <div class="d-flex align-items-center">
                <img
                  v-if="true"
                  class="img-product img-fluid"
                  :src="
                    $config.server_url +
                    '/api/1.0/products/' +
                    patient.pa_cotyle.pr_id +
                    '/images/2?token=' +
                    $store.state.accesstoken +
                    '&origin=webapp'
                  "
                  alt=""
                />

                <div class="ml-3" v-if="patient.pa_cotyle">
                  {{ patient.pa_cotyle.pr_name }}<br />
                  <!-- <label class="m-form-checkbox">
                    <input type="checkbox" v-model="patient.pa_cotyle_used" @change="saveUsed" />
                    J'ai utilisé ce cotyle
                  </label> -->
                </div>
              </div>
            </div>
            <div class="line-product d-flex justify-content-between align-items-center" v-if="patient.pa_tige">
              <div class="d-flex align-items-center">
                <img
                  v-if="true"
                  class="img-product img-fluid"
                  :src="
                    $config.server_url +
                    '/api/1.0/products/' +
                    patient.pa_tige.pr_id +
                    '/images/2?token=' +
                    $store.state.accesstoken +
                    '&origin=webapp'
                  "
                  alt=""
                />

                <div class="ml-3">
                  {{ patient.pa_tige.pr_name }}<br />
                  <!-- <label class="m-form-checkbox">
                    <input type="checkbox" v-model="patient.pa_tige_used" @change="saveUsed" />
                    J'ai utilisé cette tige
                  </label> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-8 offset-lg-4">
            <m-form-checkbox
              class="mb-0 mt-3"
              v-model="savePreferences"
              label="En cochant cette case vos futures utilisations intégreront par défaut ces données : "
              name="savePreferences"
            ></m-form-checkbox>
            <div style="" class="ml-3" v-if="patient.pa_cotyle">
              - Cotyle : {{ patient.pa_cotyle.pr_name }}<br />
              - Tige : {{ patient.pa_tige.pr_name }} <br />
              - Taille de la bille : {{ patient.pa_toolsballsize }}mm
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-8 offset-lg-4 d-flex justify-content-between mb-3">
            <div>
              <!-- <button @click="editProthesis" class="btn btn-primary mr-3">
                <icon width="16" height="16" name="arrow-circle-left" class="mr-2" color="" style="margin-bottom: -2px"></icon>Modifier
              </button> -->
              <!-- <button @click="print" class="btn btn-primary mr-3">Imprimer</button> -->
            </div>
            <!-- <div>
              <button @click="finish" class="btn btn-primary mr-3">Terminer</button>
            </div> -->
          </div>
        </div>
        <div class="row py-5">
          <div class="col-lg-4"></div>
          <div class="col">
            <h4>Questionnaire de MMC</h4>
            <div class="my-3">Vous pouvez nous aider en répondant à ce questionnaire sur une échelle de 1 à 5</div>
            <div class="questionnaire-line">
              <div>Q1 : Sur une échelle de 1 à 5, à combien évaluez-vous la performance de l'application (temps de réponse &amp; accès) ?</div>
              <div>
                <select class="custom-select" style="max-width: 300px" v-model="patient.pa_answer1" @change="saveUsed">
                  <option selected>Choisir</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
            <div class="questionnaire-line">
              <div>
                Q2 : Sur une échelle de 1 à 5, à combien évaluez-vous l'ergonomie de l'application (placement des calques d'implant / navigation dans
                l'application) ?
              </div>
              <div>
                <select class="custom-select" style="max-width: 300px" v-model="patient.pa_answer2" @change="saveUsed">
                  <option value="0">Choisir</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
            <!-- <div class="questionnaire-line">
              <div>Q3 : Sur une échelle de 1 à 5, à combien évaluez-vous la compréhension / simplicité d'utilisation de l'application ?</div>
              <div>
                <select class="custom-select" style="max-width: 300px" v-model="patient.pa_answer3" @change="saveUsed">
                  <option value="0">Choisir</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div> -->
            <div class="questionnaire-line">
              <div>Q3 : Conseilleriez-vous l'application à un confrère ?</div>
              <div>
                <select class="custom-select" style="max-width: 300px" v-model="patient.pa_answer4" @change="saveUsed">
                  <option value="0">Choisir</option>
                  <option value="1">Oui</option>
                  <option value="2">Non</option>
                </select>
              </div>
            </div>
            <div class="questionnaire-line">
              <div>Q4 : Exprimez-vous !</div>
              <div>
                <textarea name="pa_answer5" style="width: 100%" id="" cols="30" rows="5" v-model="patient.pa_answer5" @input="saveUsed"></textarea>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-lg-8 offset-lg-4 d-flex justify-content-between mb-3">
                <div>&nbsp;</div>
                <div>
                  <button @click="finish" class="btn btn-primary mr-3">Terminer</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="winmodal" v-if="$store.state.help.result"></div>
    <div class="helpwin" v-if="$store.state.help.result">
      <div class="winclose">
        <label for="notdisplayanymore" style="margin: -5px 30px"
          ><input type="checkbox" id="notdisplayanymore" v-model="notdisplayanymore" />Ne plus afficher</label
        >
        <a href="javascript:void(0);" @click="$store.commit('set_help', ['result', notdisplayanymore])"
          ><icon width="24" height="24" name="times" class="" color="black"></icon
        ></a>
      </div>
      <div class="row">
        <div class="col-md-8">
          <h3>Explication des « delta longueur et offset ».</h3>
          <p>
            Les « delta longueur et offset » représentent les distances suivant l’axe de la tige et sa perpendiculaire entre le centre tête défini par
            le positionnement de la tige et celui défini par l’implant acétabulaire (distance cranio caudale pour la longueur et distance latérale
            pour l’offset).
          </p>
          <div>
            <button class="btn btn-primary" @click="clickHelp">SUIVANT</button>
          </div>
        </div>
        <div class="col-md-4">
          <img src="/images/aide/schemadelta.jpeg" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "result",
  components: {},
  data() {
    return {
      notdisplayanymore: false,
      pa_id: this.$route.params.id,
      patient: {},
      savePreferences: false
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("addHistoryUrl", this.$route.path);
    this.loadPatient();
  },
  watch: {},
  computed: {},
  methods: {
    clickHelp() {
      this.$store.commit("set_help", ["result", this.notdisplayanymore]);
    },
    print() {
      window.open(
        this.$config.server_url +
          "/api/1.0/patients/" +
          this.patient.pa_id +
          "/print" +
          "?token=" +
          this.$store.state.accesstoken +
          "&origin=webapp" +
          "&d=" +
          new Date().getTime()
      );
    },
    async loadPatient() {
      let response = await this.$axios.get(this.$config.server_url + "/api/1.0/patients/" + this.pa_id);
      this.patient = response.data.data;
      // console.log("this.patient", this.patient);
      if (this.patient.pa_answer1 == "") this.patient.pa_answer1 = "0";
      if (this.patient.pa_answer2 == "") this.patient.pa_answer2 = "0";
      if (this.patient.pa_answer3 == "") this.patient.pa_answer3 = "0";
      if (this.patient.pa_answer4 == "") this.patient.pa_answer4 = "0";
      // console.log("patient.createdAt", this.patient);
    },
    newPatient() {
      this.$router.push("/chooseimage").catch(err => {});
    },
    editProthesis() {
      this.$router.push("/prosthesis/" + this.pa_id + "/2").catch(err => {});
    },
    async saveUsed() {
      await this.$axios.put(this.$config.server_url + "/api/1.0/patients/" + this.patient.pa_id, {
        // pa_cotyle_used: this.patient.pa_cotyle_used,
        // pa_tige_used: this.patient.pa_tige_used,
        pa_answer1: this.patient.pa_answer1,
        pa_answer2: this.patient.pa_answer2,
        pa_answer3: this.patient.pa_answer3,
        pa_answer4: this.patient.pa_answer4,
        pa_answer5: this.patient.pa_answer5
      });
    },
    async finish() {
      // on enregistre les préférences si la checkbox est cochée
      if (this.savePreferences) {
        let products = {
          pr_id_cotyle: this.patient.pa_cotyle.pr_id,
          pr_id_tige: this.patient.pa_tige.pr_id,
          co_ballsize: this.patient.pa_toolsballsize
        };
        await this.$axios.put(this.$config.server_url + "/api/1.0/contacts/" + this.$store.state.user.co_id + "/preferences", products);
      }
      this.$router.push("/patients").catch(err => {});
    }
  }
};
</script>

<style scoped lang="scss">
.m-form-checkbox {
  margin-bottom: 0;
}
.bt {
  border-top: 1px solid #cacaca;
}
.line-product {
  padding: 20px 0;
  border-bottom: 1px solid #cacaca;
}

.img-product {
  max-width: 100px;
}
.date {
  font-weight: 400;
}
.questionnaire-line {
  padding-bottom: 25px;
}
</style>
