import Vue from "vue";
import App from "./App.vue";
import router from "./plugins/router";
import store from "./plugins/store";
import Utils from "./plugins/utils";
import axios from "axios";
//import socketsListener from "./plugins/sockets";
// import jquery from "jquery";
// import popper from "popper.js";
import "bootstrap";
import _ from "lodash";
import dayjs from "dayjs";
//import config from "./config.js";
import Validation from "./plugins/validation";
import jquery from "jquery";
import Popper from "popper.js";
import SocketIO from "socket.io-client";
import "./plugins/components";
import "./plugins/filters";
import "./registerServiceWorker";

const config = {
  server_url: process.env.VUE_APP_SERVER_URL,
  webapp_url: process.env.VUE_APP_WEBAPP_URL
};

var vueCookie = require("vue-cookie");
Vue.use(vueCookie);

// console.log("config.server_url", config.server_url);
const socket = SocketIO(config.server_url);
Object.defineProperty(Vue.prototype, "$SocketIO", { value: socket });
//store.dispatch("initSocketEvents");
//socketsListener.init(Vue.prototype.$socket, store);
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = true;

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
dayjs.locale("fr");

Object.defineProperty(Vue.prototype, "$config", {
  value: config
});
Vue.config.productionTip = false;
Object.defineProperty(Vue.prototype, "$_", { value: _ });
Object.defineProperty(Vue.prototype, "$$", { value: jquery });
Object.defineProperty(Vue.prototype, "$dayjs", { value: dayjs });
//Object.defineProperty(Vue.prototype, "$config", { value: config });
Object.defineProperty(Vue.prototype, "$Utils", { value: Utils });
Object.defineProperty(Vue.prototype, "$Validation", { value: Validation });
Object.defineProperty(Vue.prototype, "$Popper", { value: Popper });

Object.defineProperty(Vue.prototype, "$axios", { value: axios });
axios.defaults.headers.common["x-auth-accesstoken"] = store.state.accesstoken;
axios.interceptors.request.use(
  function (config2) {
    store.commit("set_mainloading", true);

    return config2;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    store.commit("set_mainloading", false);
    let ok = true;
    //const originalRequest = response.config;
    if (response.data && response.data.err) {
      if (response.data.err.key == "token_mandatory" || response.data.err.key == "user_not_found") {
        ok = false;
      }
      /* if (response.data.err.key == "token_expired") {
        return axios
          .get(config.server_url + "/api/1.0/refreshtoken", {
            headers: { "x-auth-refreshtoken": store.state.refreshtoken }
          })
          .then(response2 => {
            if (!response2.data.err) {
              store.commit("set_tokens", {
                accesstoken: response2.data.accesstoken,
                refreshtoken: response2.data.refreshtoken
              });
              originalRequest.headers["x-auth-accesstoken"] = response2.data.accesstoken;
              return axios(originalRequest);
            }
          });
      } */
      if (response.data.err.key == "token_expired") {
        ok = false;
      }
    }
    if (!ok) {
      store.commit("set_connexion", {});
      router.push("/login");
    }
    return response;
  },
  function (error) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled", error.message);
    } else {
      console.error("response error", error);
      return Promise.reject(error);
    }
  }
);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
