<template>
  <div class="overflow-hidden">
    <m-modal-spinner v-if="spinner"></m-modal-spinner>
    <euros-header ref="header" titre="Alignez la bille rouge avec celle de la radio" pathRetour="/patients"></euros-header>
    <div class="container-fluid bg-blue">
      <div class="main3parts d-flex" ref="main3parts" style="">
        <div class="main3parts-col1 d-none d-lg-block" style="flex-grow: 0">
          <div>
            <img style="width: 120px" class="img-fluid d-none d-lg-block" src="/images/logo-eurotxt-black.png" alt="" />
          </div>
          <div class="mt-3">
            <button class="btn btn-primary w-100" @click="loadCircle()">Trouver la bille auto.</button><br />
            <button class="btn btn-primary btn-green w-100" @click="nextCircle()" v-if="circles.length">Suivant</button>
          </div>
          <div class="mt-3 text-center">Aide : taper + ou - pour zoomer</div>
          <!-- <div>
            <div class="main3parts-col1-icos mt-5 d-flex">
              <div class="icos1 mt-2">
                <h6 class="text-small">Déplacer</h6>
                <div class="d-flex">
                  <div class="d-flex align-items-center">
                    <img class="ico-move1" src="/images/ico_ordi-deplacer01.png" alt="mouvement de la main" />
                  </div>
                  <div class="d-flex align-items-center mx-3 text-small">ou</div>
                  <div class="d-flex align-items-center">
                    <img class="ico-move2" src="/images/ico_ordi-deplacer02.png" alt="mouvement de la main" />
                  </div>
                </div>
              </div>
              <div class="icos2">
                <h6 class="text-small">Zoom</h6>
                <div class="d-flex">
                  <div class="d-flex align-items-center">
                    <img class="ico-move3" src="/images/ico_ordi-zoom01.png" alt="mouvement de la main" />
                  </div>
                  <div class="d-flex align-items-center mx-3 text-small">ou</div>
                  <div class="d-flex align-items-center">
                    <img class="ico-move4" src="/images/ico_ordi-zoom02.png" alt="mouvement de la main" />
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="main3parts-col2 relative bg-dark" ref="gestoparent" style="flex-grow: 1">
          <div class="trouver-bille-mobile d-block d-lg-none">
            <div class="d-flex mt-3">
              <button class="btn btn-primary ml-3" @click="loadCircle()">Trouver la bille</button><br />
              <button class="btn btn-primary btn-green ml-2 d-flex align-items-center" @click="nextCircle()" v-if="circles.length">
                <icon width="20" height="20" name="forward"></icon>
              </button>
            </div>
          </div>
          <div
            v-if="patient.pa_toolstype == 'ruler' && (patient.pa_toolsrulersize == 'horizontal10' || patient.pa_toolsrulersize == 'horizontal20')"
            class="ruler-rouge-h"
            ref="ruler"
          ></div>
          <div
            v-if="patient.pa_toolstype == 'ruler' && (patient.pa_toolsrulersize == 'vertical10' || patient.pa_toolsrulersize == 'vertical20')"
            class="ruler-rouge-v"
            ref="ruler"
          ></div>
          <div v-if="patient.pa_toolstype == 'ball'" class="bille-rouge" ref="bille"></div>
          <div style="height: 100%; position: relative" ref="gesto">
            <div style="position: absolute" ref="bgimage">
              <!-- <div :style="style + backgroundRadio"></div> -->
              <img
                ref="imgRadio"
                class=""
                :src="$config.server_url + '/api/1.0/files/patient/' + $route.params.id + '?token=' + $store.state.accesstoken + '&origin=webapp'"
                alt="test"
                @load="imageLoaded"
              />
            </div>
          </div>
        </div>
        <div class="main3parts-col3 flex-column d-none d-lg-flex">
          <div class="" style="flex-grow: 1">
            <!-- <p>Type de mesure</p>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                v-model="patient.pa_toolstype"
                name="toolsType"
                id="toolsType1"
                value="ball"
              />
              <label class="form-check-label" for="toolsType1">Bille</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                v-model="patient.pa_toolstype"
                name="toolsType"
                id="toolsType2"
                value="ruler"
              />
              <label class="form-check-label" for="toolsType2">Règle</label>
            </div> -->
            <div v-if="patient.pa_toolstype == 'ball'" class="">
              <m-form-text type="number" label="Taille de la bille en mm" v-model="patient.pa_toolsballsize"></m-form-text>
            </div>
            <!--             <div v-if="patient.pa_toolstype == 'ball'" class="mt-3">
              <p>Sélectionnez la taille de la bille</p>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="size2"
                  v-model="patient.pa_toolsballsize"
                  value="2"
                  name="toolsBallSize"
                />
                <label class="form-check-label" for="size2">2 cm</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="size5"
                  v-model="patient.pa_toolsballsize"
                  value="5"
                  name="toolsBallSize"
                />
                <label class="form-check-label" for="size5">5 cm</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="size75"
                  v-model="patient.pa_toolsballsize"
                  value="7.5"
                  name="toolsBallSize"
                />
                <label class="form-check-label" for="size75">7,5 cm</label>
              </div>
            </div> -->
            <!-- <div v-if="patient.pa_toolstype == 'ruler'" class="mt-3">
              <p>Sélectionnez le type de règle</p>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="toolsRulerTypeHorizontal10"
                  v-model="patient.pa_toolsrulersize"
                  value="horizontal10"
                  name="toolsRulerType"
                />
                <label class="form-check-label" for="toolsRulerTypeHorizontal10">Horizontal 10cm</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="toolsRulerTypeHorizontal20"
                  v-model="patient.pa_toolsrulersize"
                  value="horizontal20"
                  name="toolsRulerType"
                />
                <label class="form-check-label" for="toolsRulerTypeHorizontal20">Horizontal 20cm</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="toolsRulerTypeVertical10"
                  v-model="patient.pa_toolsrulersize"
                  value="vertical10"
                  name="toolsRulerType"
                />
                <label class="form-check-label" for="toolsRulerTypeVertical10">Vertical 10cm</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="toolsRulerTypeVertical20"
                  v-model="patient.pa_toolsrulersize"
                  value="vertical20"
                  name="toolsRulerType"
                />
                <label class="form-check-label" for="toolsRulerTypeVertical20">Vertical 20cm</label>
              </div>
            </div> -->
          </div>
          <div class="text-right" style="flex-grow: 0">
            <button @click="nextStep" class="btn btn-primary w-100" style="">Suivant</button>
          </div>
        </div>
        <!-- pour les tailles de mobile -->
        <div class="part3-mobile d-flex flex-column d-block d-lg-none">
          <div @click="showOrhideMenu" class="btn-menu-mobile" ref="btnMenuMobile">
            <icon :name="isShowMenu ? 'chevron-right' : 'chevron-left'" width="15" height="15"></icon>
          </div>
          <div ref="menuMobile" class="menuMobile">
            <div class="" style="flex-grow: 1">
              <div v-if="patient.pa_toolstype == 'ball'" class="mt-3">
                <m-form-text type="number" label="Taille bille mm" v-model="patient.pa_toolsballsize"></m-form-text>
              </div>
              <!-- <p class="mb-0 ">Type de mesure</p>
              <div class="form-check ml-2">
                <input class="form-check-input" type="radio" v-model="patient.pa_toolstype" name="toolsType_mobile" id="toolsType1" value="ball" />
                <label class="form-check-label" for="toolsType1">Bille</label>
              </div>
              <div class="form-check ml-2">
                <input class="form-check-input" type="radio" v-model="patient.pa_toolstype" name="toolsType_mobile" id="toolsType2" value="ruler" />
                <label class="form-check-label" for="toolsType2">Règle</label>
              </div>
              <div v-if="patient.pa_toolstype == 'ball'">
                <p class="mb-0">Taille de la Bille</p>
                <div class="form-check ml-2">
                  <input class="form-check-input" type="radio" id="size2" v-model="patient.pa_toolsballsize" value="2" name="toolsBallSize_mobile" />
                  <label class="form-check-label" for="size2">2 cm</label>
                </div>
                <div class="form-check ml-2">
                  <input class="form-check-input" type="radio" id="size5" v-model="patient.pa_toolsballsize" value="5" name="toolsBallSize_mobile" />
                  <label class="form-check-label" for="size5">5 cm</label>
                </div>
                <div class="form-check ml-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="size75"
                    v-model="patient.pa_toolsballsize"
                    value="7.5"
                    name="toolsBallSize_mobile"
                  />
                  <label class="form-check-label" for="size75">7,5 cm</label>
                </div>
              </div> -->
              <!--  <div v-if="patient.pa_toolstype == 'ruler'">
                <p class="mb-0">Taille de la règle</p>
                <div class="form-check ml-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="size2"
                    v-model="patient.pa_toolsrulersize"
                    value="horizontal10"
                    name="toolsRulerType_mobile"
                  />
                  <label class="form-check-label" for="size2">Horizontal 10cm</label>
                </div>
                <div class="form-check ml-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="size5"
                    v-model="patient.pa_toolsrulersize"
                    value="horizontal20"
                    name="toolsRulerType_mobile"
                  />
                  <label class="form-check-label" for="size5">Horizontal 20cm</label>
                </div>
                <div class="form-check ml-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="size75"
                    v-model="patient.pa_toolsrulersize"
                    value="vertical10"
                    name="toolsRulerType_mobile"
                  />
                  <label class="form-check-label" for="size75">Vertical 10cm</label>
                </div>
                <div class="form-check ml-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="size75"
                    v-model="patient.pa_toolsrulersize"
                    value="vertical20"
                    name="toolsRulerType_mobile"
                  />
                  <label class="form-check-label" for="size75">Vertical 20cm</label>
                </div>
              </div> -->
            </div>
            <div class="text-right mt-2" style="flex-grow: 0">
              <button @click="nextStep" class="btn btn-primary btn-sm w-100" style="">Suivant</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="winmodal" v-if="$store.state.help.ratio"></div>
    <div class="helpwin" v-if="$store.state.help.ratio">
      <div class="winclose">
        <label for="notdisplayanymore" style="margin: -5px 30px"
          ><input type="checkbox" id="notdisplayanymore" v-model="notdisplayanymore" />Ne plus afficher</label
        >
        <a href="javascript:void(0);" @click="$store.commit('set_help', ['ratio'])"
          ><icon width="24" height="24" name="times" class="" color="black"></icon
        ></a>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div v-if="help1">
            <h3>Méthode 1 : cliquez sur le bouton Trouver la bille automatiquement puis plusieurs fois sur le bouton Suivant.</h3>
            <p>Le programme essaye de trouver une forme circulaire dans l’image que vous venez d’envoyer</p>
            <p class="color-red">Puis saisissez la taille de la bille en mm</p>
            <div>
              <button class="btn btn-secondary" @click="clickHelp1">VOIR LA MÉTHODE 2</button>
            </div>
          </div>
          <div v-if="help2">
            <h3>Méthode 2 : Utilisez votre sourie pour positionner la bille sur votre radio avec la forme rouge au centre de l’écran.</h3>
            <p>Vous pouvez utiliser la sourie :</p>
            <ul>
              <li>Cliquer-glisser pour déplacer</li>
              <li>La molette de la sourie pour zoomer</li>
            </ul>
            <p>Mais aussi le clavier :</p>
            <ul>
              <li>Les touches + et - pour zoomer</li>
              <li>Les touches fléches haut, bas, droite et gauche pour vous déplacer</li>
            </ul>
            <p class="color-red">Puis saisissez la taille de la bille en mm</p>
            <div>
              <button class="btn btn-secondary" @click="clickHelp2">VOIR LA MÉTHODE 1</button>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <img src="/images/aide/03-trouver-bille.png" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Moveable from "moveable";
// import Gesto from "gesto";
// import InfiniteViewer from "infinite-viewer";
import Hammer from "hammerjs";

export default {
  name: "ratio",
  components: {},
  props: {},
  data() {
    return {
      notdisplayanymore: false,
      help1: true,
      help2: false,
      spinner: true,
      circles: [],
      scaleStart: -1,
      file: {},
      patient: { pa_toolstype: "ball", pa_toolsballsize: "8", pa_toolsrulersize: "vertical10", pa_toolsscale: 0 },
      bgimage: {},
      getso: {},
      style: "",
      backgroundRadio: "",
      imageRatio: 1.96,
      tx: 0,
      ty: 0,
      scale: 1,
      isShowMenu: true,
      circleCenterX: 0,
      circleCenterY: 0,
      circleRayon: 0
      // moveable: {
      //   draggable: true,
      //   throttleDrag: 0,
      //   resizable: true,
      //   throttleResize: 1,
      //   keepRatio: true,
      //   scalable: false,
      //   throttleScale: 0,
      //   rotatable: true,
      //   throttleRotate: 0,
      //   pinchable: true, // ["draggable", "resizable", "scalable", "rotatable"]
      //   origin: false
      // }
    };
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.onkeyup);
    window.removeEventListener("resize", this.setWindowHeight);
    window.removeEventListener("gesturestart", this.preventDefaultPerso);
    window.removeEventListener("gesturechange", this.preventDefaultPerso);
    window.removeEventListener("gestureend", this.preventDefaultPerso);
    window.removeEventListener("wheel", this.preventDefaultPerso, { passive: false });
  },
  async mounted() {
    this.setWindowHeight();
    // console.log("w", w);
    this.$store.dispatch("addHistoryUrl", this.$route.path);
    window.addEventListener("keyup", this.onkeyup);
    window.addEventListener("resize", this.setWindowHeight);
    window.addEventListener("gesturestart", this.preventDefaultPerso);
    window.addEventListener("gesturechange", this.preventDefaultPerso);
    window.addEventListener("gestureend", this.preventDefaultPerso);
    window.addEventListener("wheel", this.preventDefaultPerso, { passive: false });
    this.loadPatient();

    this.$refs.bgimage.addEventListener("mousedown", this.preventDefaultPerso);
    // this.$refs.imgRadio.addEventListener("mousedown", this.preventDefaultPerso);

    // let target2 = this.$refs.gesto;
    let clickPosX = 0,
      clickPosY = 0;
    let startMoveGesto = false;
    this.addMultipleEventsListener(this.$refs.gestoparent, "mousedown touchstart", e => {
      // console.log("mousedown", e);
      if (e.type == "touchstart") e = e.touches[0];
      startMoveGesto = true;
      clickPosX = e.clientX;
      clickPosY = e.clientY;
      // console.log("clickPosX,clickPosY", clickPosX, clickPosY);
    });
    this.addMultipleEventsListener(window, "mouseup touchend", e => {
      // console.log("mouseup");
      startMoveGesto = false;
    });
    this.addMultipleEventsListener(this.$refs.gestoparent, "mousemove touchmove", e => {
      //  console.log("e.deltaX", e);
      if (e.type == "touchmove") e = e.touches[0];
      if (startMoveGesto) {
        // let wContainer = this.$refs.gestoparent.offsetWidth,
        //   hContainer = this.$refs.gestoparent.offsetHeight;
        this.tx += (e.clientX - clickPosX) * (1 / this.scale);
        this.ty += (e.clientY - clickPosY) * (1 / this.scale);
        clickPosX = e.clientX;
        clickPosY = e.clientY;
        // toW = wContainer / 2 - this.tx;
        // toH = hContainer / 2 - this.ty;
        // console.log("mousemove", startMoveGesto, tx, ty, scale);
        // console.log("this.$refs.gesto.style.transform", this.$refs.gesto.style.transform);
        this.drawElements();
      }
    });

    let onzoom = e => {
      // console.log("e", e.scale, e.deltaY);
      // let previousScale = scale;
      if (e.scale) this.scale = this.scaleStart + e.scale - 1;
      else this.scale -= e.deltaY / 800;
      if (this.scale < 0.01) this.scale = 0.01;
      // console.log("scale, e.deltaX", scale, scale - previousScale);
      // this.$refs.gesto.style.transformOrigin = `${toW}px ${toH}px`;
      // this.$refs.gesto.style.transform = `translate(${this.tx}px, ${this.ty}px) scale(${this.scale})`;
      this.drawElements();
      e.preventDefault();
    };
    this.$refs.gestoparent.addEventListener("wheel", onzoom);
    var hammertime = new Hammer(this.$refs.gestoparent, {});
    hammertime.get("pinch").set({ enable: true });
    hammertime.on("pinch", onzoom);
    hammertime.on("pinchstart", () => {
      this.scaleStart = this.scale;
    });
    // hammertime.on("pinchend", () => {});

    // pour récupérer la hauteur de l'élément formulaire pour mobile et placer le chevron ouvrir/fermer
    this.$nextTick(() => {
      if (this.$refs.menuMobile) {
        let height = this.$refs.menuMobile.clientHeight;
        if (height > 0) {
          let heightString = height + "px";
          this.$refs.btnMenuMobile.style.bottom = heightString;
        }
      }
    });
  },
  // watch: {
  //   scale: function() {
  //     // console.log("scale", this.scale);
  //     this.calculatePixelInMm();
  //   }
  // },
  computed: {},
  methods: {
    clickHelp1() {
      this.help1 = false;
      this.help2 = true;
    },
    clickHelp2() {
      this.help1 = true;
      this.help2 = false;
    },
    clickHelp() {
      this.$store.commit("set_help", "ratio");
    },
    setWindowHeight() {
      this.$refs.main3parts.style.minHeight = document.documentElement.clientHeight - this.$refs.header.$refs.header.offsetHeight + "px";
    },
    showOrhideMenu() {
      let wContainer = this.$refs.menuMobile.offsetWidth + 15;
      if (this.isShowMenu) {
        this.$refs.menuMobile.style.transform = `translate(${wContainer}px, 0)`;
      } else {
        this.$refs.menuMobile.style.transform = `translate(0, 0)`;
      }
      this.isShowMenu = !this.isShowMenu;
    },
    preventDefaultPerso(e) {
      e.preventDefault();
    },
    drawElements() {
      let wContainer = this.$refs.gestoparent.offsetWidth,
        hContainer = this.$refs.gestoparent.offsetHeight;
      // console.log("tx,ty", this.tx, this.ty);
      let toW = wContainer / 2 - this.tx;
      let toH = hContainer / 2 - this.ty;
      // this.scale = 1;
      this.$refs.gesto.style.transformOrigin = `${toW}px ${toH}px`;
      this.$refs.gesto.style.transform = `translate(${this.tx}px, ${this.ty}px) scale(${this.scale})`;
    },
    async loadCircle() {
      // wImage = this.$refs.bgimage.offsetWidth;
      // hImage = this.$refs.bgimage.offsetHeight;
      let response = await this.$axios.get(this.$config.server_url + "/api/1.0/patients/" + this.patient.pa_id + "/circle");
      let data = response.data.data;
      // console.log("data", data);
      this.circles = data;
      if (!data.length) {
        alert("Aucune bille trouvée sur l'image");
        return;
      }
      this.currentCircle = 100000;
      this.nextCircle();
      //this.patient = response.data.data;
    },
    nextCircle() {
      let wContainer = this.$refs.gestoparent.offsetWidth,
        hContainer = this.$refs.gestoparent.offsetHeight;
      this.currentCircle++;
      if (this.currentCircle >= this.circles.length) this.currentCircle = 0;
      this.tx = this.circles[this.currentCircle].x * -1 + wContainer / 2;
      this.ty = this.circles[this.currentCircle].y * -1 + hContainer / 2;
      this.scale = 100 / (this.circles[this.currentCircle].r * 2);
      // console.log("this.scale", this.scale, wImage);
      // this.scale = 1;
      this.drawElements();
    },
    centerBgImage() {
      // console.log("center");
      let wContainer = this.$refs.gestoparent.offsetWidth,
        hContainer = this.$refs.gestoparent.offsetHeight,
        wImage = this.$refs.bgimage.offsetWidth,
        hImage = this.$refs.bgimage.offsetHeight;
      if (wContainer / wImage > hContainer / hImage) {
        this.scale = hContainer / hImage;
        this.tx = (wContainer - wImage) / 2;
        this.ty = (hImage * this.scale - hImage) / 2;
      } else {
        this.scale = wContainer / wImage;
        this.ty = (hContainer - hImage) / 2;
        this.tx = (wImage * this.scale - wImage) / 2;
      }
      // console.log("this.tx", this.tx, wContainer, wImage, (wContainer - hContainer) / 2);
      // let toW = wContainer / 2 - this.tx;
      // let toH = hContainer / 2 - this.ty;
      // this.$refs.gesto.style.transformOrigin = `${toW}px ${toH}px`;
      // this.$refs.gesto.style.transform = `translate(${this.tx}px, ${this.ty}px) scale(${this.scale})`;
      this.drawElements();
      // this.calculatePixelInMm();
    },
    absorbEvent_(event) {
      var e = event || window.event;
      e.preventDefault && e.preventDefault();
      // e.stopPropagation && e.stopPropagation();
      // e.cancelBubble = true;
      // e.returnValue = false;
      return true;
    },
    imageLoaded(e) {
      this.$refs.imgRadio.ontouchstart = this.absorbEvent_;
      this.$refs.imgRadio.ontouchmove = this.absorbEvent_;
      this.$refs.imgRadio.ontouchend = this.absorbEvent_;
      this.$refs.imgRadio.ontouchcancel = this.absorbEvent_;

      this.$nextTick(() => {
        this.drawElements();
        // this.centerBgImage();
        if (this.patient && !this.patient.pa_toolsscale) this.centerBgImage();
        this.spinner = false;
        // console.warn("image loaded");
      });
    },
    addMultipleEventsListener(el, s, fn) {
      s.split(" ").forEach(e => el.addEventListener(e, fn, false));
    },
    async loadPatient() {
      // load patient pour récupérer les pa_tools
      let response = await this.$axios.get(this.$config.server_url + "/api/1.0/patients/" + this.$route.params.id);
      this.patient = response.data.data;
      if (!this.patient.pa_toolstype) this.patient.pa_toolstype = "ball";
      if (!this.patient.pa_toolsballsize) {
        this.patient.pa_toolsballsize = this.patient.co_id.co_ballsize ? this.patient.co_id.co_ballsize : "8";
      }
      if (!this.patient.pa_toolsrulersize) this.patient.pa_toolsrulersize = "vertical10";
      if (this.patient.pa_toolstx) this.tx = this.patient.pa_toolstx;
      if (this.patient.pa_toolsty) this.ty = this.patient.pa_toolsty;
      if (this.patient.pa_toolsscale) this.scale = this.patient.pa_toolsscale;
      this.drawElements();
      // console.log("this.patient.pa_toolsscale", this.patient.pa_toolsscale);
      if (!this.patient.pa_toolsscale) this.centerBgImage();
    },

    /************** les événements au clavier ***********   */
    onkeyup(event) {
      // console.log("onkeyup", event.key);
      if (event.key == "ArrowLeft") this.tx += -1;
      if (event.key == "ArrowRight") this.tx += 1;
      if (event.key == "ArrowUp") this.ty += -1;
      if (event.key == "ArrowDown") this.ty += 1;
      if (event.key == "+") this.scale += 0.1;
      if (event.key == "-") this.scale += -0.1;
      this.drawElements();
    },

    async nextStep() {
      //on enregistre les pa_tools
      let dataPaTools = {
        pa_toolsballsize: this.patient.pa_toolsballsize,
        pa_toolstype: this.patient.pa_toolstype,
        pa_toolsrulersize: this.patient.pa_toolsrulersize,
        pa_toolsscale: this.scale,
        pa_toolstx: this.tx,
        pa_toolsty: this.ty
      };
      await this.$axios.put(this.$config.server_url + "/api/1.0/patients/" + this.$route.params.id, dataPaTools);

      this.$router.push("/prosthesis/" + this.$route.params.id + "/1").catch(err => {});
    }
  }
};
</script>

<style scoped lang="scss">
.bille-rouge {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  background-color: #ff000052;
  z-index: 1000;
}
.ruler-rouge-v {
  position: absolute;
  width: 10px;
  height: 100px;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  background-color: #ff000052;
  z-index: 1000;
}
.ruler-rouge-h {
  position: absolute;
  width: 100px;
  height: 10px;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  background-color: #ff000052;
  z-index: 1000;
}
.trouver-bille-mobile {
  position: absolute;
  //background-color: #353a4066;
  color: white;
  width: 100%;
  z-index: 1000;
  transition: transform 0.5s ease-out;
}
// .container-img-radio {
//   overflow: hidden;
// }
.main3parts {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 -15px;
  margin-top: 65px;
  .main3parts-col1 {
    padding: 15px;
    .main3parts-col1-icos {
      flex-direction: row;
      .icos2 {
        margin-left: 30px;
      }
    }
  }
  .main3parts-col2 {
    overflow: hidden;
  }
  .main3parts-col3 {
    padding: 15px;
  }
}
.ico-move1 {
  width: 38px;
}
.ico-move2 {
  width: 15px;
}
.ico-move3 {
  width: 25px;
}
.ico-move4 {
  width: 15px;
}

@media (min-width: 576px) {
  .main3parts {
    margin-top: 40px;
    flex-direction: row;
    .main3parts-col1,
    .main3parts-col3 {
      min-width: 20%;
      margin: 0;
    }
    .main3parts-col2 {
      min-width: 60%;
      margin: 0;
    }
    .main3parts-col1 {
      .main3parts-col1-icos {
        flex-direction: column;
        .icos2 {
          margin-left: 0;
          margin-top: 30px;
        }
      }
    }
  }
  .ico-move1 {
    width: 50px;
  }
  .ico-move2 {
    width: 25px;
  }
  .ico-move3 {
    width: 35px;
  }
  .ico-move4 {
    width: 25px;
  }
}
</style>
