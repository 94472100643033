<template>
  <div class="overflow-hidden">
    <m-modal-spinner v-if="spinner"></m-modal-spinner>
    <euros-header ref="header" :titre="stepTitle" pathRetour="/patients"></euros-header>
    <div class="container-fluid bg-blue">
      <div class="main3parts d-flex" ref="main3parts">
        <div class="main3parts-col1 d-none d-lg-flex flex-column" style="flex-grow: 0">
          <div class="delta" style="flex-grow: 1">
            <div>
              <img style="width: 120px" class="img-fluid d-none d-lg-block" src="/images/logo-eurotxt-black.png" alt="" />
            </div>
            <div class="mt-3 d-flex justify-content-center">
              <table class="table-delta d-lg-block d-xl-none">
                <thead>
                  <tr>
                    <th>Delta</th>
                    <th>Offset</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Col S</td>
                    <td>{{ patient.pa_delta_min_x | formatDelta }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <td>Col M</td>
                    <td>{{ patient.pa_delta0_x | formatDelta }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <!-- invertion des +3,5 et -3,5 normal voir issue 47 -->
                    <td>Col L</td>
                    <td>{{ patient.pa_delta_max_x | formatDelta }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <th>Delta</th>
                    <th>Long.</th>
                  </tr>
                  <tr>
                    <td>Col S</td>
                    <td>{{ patient.pa_delta_min_y | formatDelta }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <td>Col M</td>
                    <td>{{ patient.pa_delta0_y | formatDelta }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <!-- invertion des +3,5 et -3,5 normal voir issue 47 -->
                    <td>Col L</td>
                    <td>{{ patient.pa_delta_max_y | formatDelta }}&nbsp;mm</td>
                  </tr>
                </tbody>
              </table>

              <table class="table-delta d-lg-none d-xl-block">
                <thead>
                  <tr>
                    <th>Delta</th>
                    <th>Offset</th>
                    <th>Long.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Col S</td>
                    <td>{{ patient.pa_delta_min_x | formatDelta }}&nbsp;mm</td>
                    <td>{{ patient.pa_delta_min_y | formatDelta }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <td>Col M</td>
                    <td>{{ patient.pa_delta0_x | formatDelta }}&nbsp;mm</td>
                    <td>{{ patient.pa_delta0_y | formatDelta }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <!-- invertion des +3,5 et -3,5 normal voir issue 47 -->
                    <td>Col L</td>
                    <td>{{ patient.pa_delta_max_x | formatDelta }}&nbsp;mm</td>
                    <td>{{ patient.pa_delta_max_y | formatDelta }}&nbsp;mm</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="my-3 d-flex justify-content-center">
              <table class="table-delta">
                <thead>
                  <tr>
                    <th>Delta</th>
                    <th>Offset Y</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>0</td>
                    <td>{{ patient.pa_delta0_y }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <td>-3.5</td>
                    <td>{{ patient.pa_delta_min_y }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <td>+3.5</td>
                    <td>{{ patient.pa_delta_max_y }}&nbsp;mm</td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>
          <div style="flex-grow: 0">
            <button @click="previousStep" class="btn btn-primary w-100" style="flex-grow: 0">Précédent</button>
          </div>
        </div>
        <div class="main3parts-col2 relative bg-dark" ref="gestoparent" style="flex-grow: 1">
          <div class="delta-mobile d-block d-lg-none" ref="deltasMobile">
            <table class="table-delta mt-1 mb-1">
              <thead>
                <tr>
                  <th>Delta</th>
                  <th>Offset</th>
                  <th>Long.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Col S</td>
                  <td>{{ patient.pa_delta_min_x | formatDelta }}&nbsp;mm</td>
                  <td>{{ patient.pa_delta_min_y | formatDelta }}&nbsp;mm</td>
                </tr>
                <tr>
                  <td>Col M</td>
                  <td>{{ patient.pa_delta0_x | formatDelta }}&nbsp;mm</td>
                  <td>{{ patient.pa_delta0_y | formatDelta }}&nbsp;mm</td>
                </tr>
                <tr>
                  <!-- invertion des +3,5 et -3,5 normal voir issue 47 -->
                  <td>Col L</td>
                  <td>{{ patient.pa_delta_max_x | formatDelta }}&nbsp;mm</td>
                  <td>{{ patient.pa_delta_max_y | formatDelta }}&nbsp;mm</td>
                </tr>
              </tbody>
            </table>
            <!--             Delta offset : col standard = {{ patient.pa_delta0_x }}&nbsp;mm | col +3.5 = {{ patient.pa_delta_min_x }}&nbsp;mm | col -3.5 =
            {{ patient.pa_delta_max_x }}&nbsp;mm<br />
            Delta longeur : col standard = {{ patient.pa_delta0_y }}&nbsp;mm | col +3.5 = {{ patient.pa_delta_min_y }}&nbsp;mm | col -3.5 =
            {{ patient.pa_delta_max_y }}&nbsp;mm<br /> -->
          </div>
          <div class="d-none d-lg-block form-selected">Vous avez sélectionné {{ showForm === "cotyle" ? "le cotyle" : "la tige" }}</div>
          <div style="height: 100%; position: relative" ref="gesto">
            <div class="bgimage" ref="bgimage">
              <img
                ref="imgRadio"
                class=""
                :src="$config.server_url + '/api/1.0/files/patient/' + $route.params.id + '?token=' + $store.state.accesstoken + '&origin=webapp'"
                alt="test"
                @load="imageRadioLoaded"
              />
            </div>
            <div class="cotyleimage" data-image="cotyle" ref="cotyleimage">
              <div class="cotyleimagecontent" ref="cotyleimagecontent">
                <img
                  v-if="cotyle_productSelected"
                  ref="imgCotyle"
                  class=""
                  :src="
                    $config.server_url +
                    '/api/1.0/products/' +
                    cotyle_productSelected.pr_id +
                    '/images/' +
                    numImage +
                    '?lateralite=' +
                    patient.pa_lateralite +
                    '&token=' +
                    $store.state.accesstoken +
                    '&origin=webapp'
                  "
                  alt="test"
                />
              </div>
            </div>
            <div class="tigeimage" data-image="tige" ref="tigeimage" v-show="currentStep >= 2">
              <div class="tigeimagecontent" ref="tigeimagecontent">
                <img
                  v-if="tige_productSelected"
                  ref="imgTige"
                  class=""
                  :src="
                    $config.server_url +
                    '/api/1.0/products/' +
                    tige_productSelected.pr_id +
                    '/images/' +
                    numImage +
                    '?lateralite=' +
                    patient.pa_lateralite +
                    '&token=' +
                    $store.state.accesstoken +
                    '&origin=webapp'
                  "
                  alt="test"
                />
              </div>
            </div>
            <!-- <div class="ruler" ref="ruler"></div> -->
          </div>
        </div>
        <div class="main3parts-col3 flex-column d-none d-lg-flex">
          <div class="mt-2">
            <div>
              <div class="tabber">
                <label class="label-lateralite label-G ml-2" for="lateralitegc">Gauche</label>
                <input type="radio" v-model="patient.pa_lateralite" name="lateralitec" id="lateralitegc" value="left" @change="drawElements" />
                <label class="label-lateralite label-D ml-4" for="lateralitedc">Droite</label>
                <input type="radio" v-model="patient.pa_lateralite" name="lateralitec" id="lateralitedc" value="right" @change="drawElements" />
                <div class="blob"></div>
              </div>
            </div>
            <div class="d-flex flex-wrap mb-2">
              <h6 class="mt-4 mb-0">
                <strong>Le cotyle</strong> : <span v-if="cotyle_productSelected">{{ cotyle_productSelected.pr_ref }}</span>
              </h6>
            </div>
            <select class="custom-select" v-model="cotyle_model" @change="changeSelectProduct('cotyle', 'model')">
              <option v-for="option in items_cotyle_model" :value="option.key" :key="option.key">
                {{ option.name }}
              </option>
            </select>
            <select class="custom-select my-2" v-model="cotyle_subtype" @change="changeSelectProduct('cotyle', 'subtype')">
              <option v-for="option in items_cotyle_subtype" :value="option.key" :key="option.key">
                {{ option.name }}
              </option>
            </select>
            <select class="custom-select" v-model="cotyle_col" @change="changeSelectProduct('cotyle', 'col')">
              <option v-for="option in items_cotyle_col" :value="option.key" :key="option.key">
                {{ option.name }}
              </option>
            </select>
            <!-- <m-form-combobox
              v-model="cotyle_model"
              placeholder="Modèle"
              :store-url="$config.server_url + '/api/1.0/products/groupby'"
              :store-params="{ type: 'cotyle', groupby: 'pr_model', token: $store.state.accesstoken }"
              item-value="pr_model"
              item-text="pr_model"
            ></m-form-combobox>
            <m-form-combobox
              v-model="cotyle_subtype"
              placeholder="Sous-type"
              :store-url="$config.server_url + '/api/1.0/products/groupby'"
              :store-params="{ type: 'cotyle', groupby: 'pr_subtype', token: $store.state.accesstoken }"
              item-value="pr_subtype"
              item-text="pr_subtype"
            ></m-form-combobox> -->
            <div class="d-flex justify-content-between mt-3 px-4">
              <div @click="changeSelectProduct('cotyle', 'size', 'moins')" class="pointer d-flex align-items-center">
                <icon width="24" height="24" name="arrow-circle-left" class="" :color="getClassGray('moins', 'cotyle')"></icon>
                <!-- <img style="margin: -1px 0 0 0;" src="/images/arrow-left.png" width="16" alt="" /> -->
              </div>
              <div class="flex-grow-1 text-center">Taille {{ cotyle_productSelected ? cotyle_productSelected.pr_size : "" }}</div>
              <div @click="changeSelectProduct('cotyle', 'size', 'plus')" class="pointer d-flex align-items-center">
                <icon width="24" height="24" name="arrow-circle-right" class="" :color="getClassGray('plus', 'cotyle')"></icon>
                <!-- <img style="margin: -1px 0 0 0;" src="/images/arrow-right.png" width="16" alt="" /> -->
              </div>
            </div>
          </div>
          <div class="mt-0" v-if="showForm === 'tige' || currentStep === 2">
            <div class="d-flex flex-wrap mb-2">
              <h6 class="mt-5 mb-0">
                <strong>La tige : </strong> <span v-if="tige_productSelected">{{ tige_productSelected.pr_ref }}</span>
              </h6>
            </div>
            <select class="custom-select" v-model="tige_model" @change="changeSelectProduct('tige', 'model')">
              <option v-for="option in items_tige_model" :value="option.key" :key="option.key">
                {{ option.name }}
              </option>
            </select>
            <select class="custom-select my-2" v-model="tige_subtype" @change="changeSelectProduct('tige', 'subtype')">
              <option v-for="option in items_tige_subtype" :value="option.key" :key="option.key">
                {{ option.name }}
              </option>
            </select>
            <select class="custom-select" v-model="tige_col" @change="changeSelectProduct('tige', 'col')">
              <option v-for="option in items_tige_col" :value="option.key" :key="option.key">
                {{ option.name }}
              </option>
            </select>
            <!-- <m-form-combobox
              v-model="tige_model"
              placeholder="Modèle"
              :store-url="$config.server_url + '/api/1.0/products/groupby'"
              :store-params="{ type: 'tige', groupby: 'pr_model', token: $store.state.accesstoken }"
              item-value="pr_model"
              item-text="pr_model"
            ></m-form-combobox>
            <m-form-combobox
              v-model="tige_subtype"
              placeholder="Sous-type"
              :store-url="$config.server_url + '/api/1.0/products/groupby'"
              :store-params="{ type: 'tige', groupby: 'pr_subtype', token: $store.state.accesstoken }"
              item-value="pr_subtype"
              item-text="pr_subtype"
            ></m-form-combobox>
            <m-form-combobox
              v-model="tige_col"
              placeholder="Col"
              :store-url="$config.server_url + '/api/1.0/products/groupby'"
              :store-params="{ type: 'tige', groupby: 'pr_col', token: $store.state.accesstoken }"
              item-value="pr_col"
              item-text="pr_col"
            ></m-form-combobox> -->
            <div class="d-flex justify-content-between mt-3 px-4">
              <div @click="changeSelectProduct('tige', 'size', 'moins')" class="pointer">
                <icon width="24" height="24" name="arrow-circle-left" class="" :color="getClassGray('moins', 'tige')"></icon>
                <!-- <img style="margin: -9px 0 0 0;" src="/images/arrow-left.png" width="16" alt="" /> -->
              </div>
              <div class="flex-grow-1 text-center">Taille {{ tige_productSelected ? tige_productSelected.pr_size : "" }}</div>
              <div @click="changeSelectProduct('tige', 'size', 'plus')" class="pointer">
                <icon width="24" height="24" name="arrow-circle-right" class="" :color="getClassGray('plus', 'tige')"></icon>
                <!-- <img style="margin: -9px 0 0 0;" src="/images/arrow-right.png" width="16" alt="" /> -->
              </div>
            </div>
          </div>
          <div class="d-flex flex-grow-1 justify-content-end align-items-end">
            <button @click="nextStep" class="btn btn-primary w-100" style="">Suivant</button>
          </div>
        </div>

        <!-- affichage pour mobile -->
        <div @click="showOrhideMenu" class="btn-menu-mobile d-block d-lg-none" ref="btnMenuMobile">
          <icon :name="isShowMenu ? 'chevron-right' : 'chevron-left'" width="15" height="15"></icon>
          <!-- <div class="text">Masquer</div> -->
        </div>
        <div ref="menuMobile" class="menuMobile d-block d-lg-none">
          <div class="">
            <div class="d-flex pointer">
              <div class="d-flex flex-wrap">
                <h6 class="mr-2" v-if="showForm === 'cotyle'">Cotyle</h6>
                <h6 class="mr-2" v-if="showForm === 'tige'">Tige</h6>
                <div class="tabber tabber-mobile mb-2">
                  <label class="label-lateralite label-G ml-2" for="lateralitegm">Gauche</label>
                  <input type="radio" v-model="patient.pa_lateralite" name="lateralitem" id="lateralitegm" value="left" @change="drawElements" />
                  <label class="label-lateralite label-D ml-4" for="lateralitedm">Droite</label>
                  <input type="radio" v-model="patient.pa_lateralite" name="lateralitem" id="lateralitedm" value="right" @change="drawElements" />
                  <div class="blob"></div>
                </div>
              </div>
            </div>
            <div v-if="showForm === 'cotyle'">
              <select class="custom-select" v-model="cotyle_model" @change="changeSelectProduct('cotyle', 'model')">
                <option v-for="option in items_cotyle_model" :value="option.key" :key="option.key">
                  {{ option.name }}
                </option>
              </select>
              <select class="custom-select mt-2" v-model="cotyle_subtype" @change="changeSelectProduct('cotyle', 'subtype')">
                <option v-for="option in items_cotyle_subtype" :value="option.key" :key="option.key">
                  {{ option.name }}
                </option>
              </select>
              <select class="custom-select mt-2" v-model="cotyle_col" @change="changeSelectProduct('cotyle', 'col')">
                <option v-for="option in items_cotyle_col" :value="option.key" :key="option.key">
                  {{ option.name }}
                </option>
              </select>
              <!-- <m-form-combobox
                v-model="cotyle_model"
                placeholder="Modèle"
                :store-url="$config.server_url + '/api/1.0/products/groupby'"
                :store-params="{ type: 'cotyle', groupby: 'pr_model', token: $store.state.accesstoken }"
                item-value="pr_model"
                item-text="pr_model"
                :dark="true"
              ></m-form-combobox>
              <m-form-combobox
                v-model="cotyle_subtype"
                placeholder="Sous-type"
                :store-url="$config.server_url + '/api/1.0/products/groupby'"
                :store-params="{ type: 'cotyle', groupby: 'pr_subtype', token: $store.state.accesstoken }"
                item-value="pr_subtype"
                item-text="pr_subtype"
                :dark="true"
              ></m-form-combobox> -->
              <div class="d-flex justify-content-between my-4 px-4">
                <div @click="changeSelectProduct('cotyle', 'size', 'moins')" class="pointer d-flex align-items-center justify-content-center">
                  <icon width="30" height="30" name="arrow-circle-left" class="" :color="getClassGray('moins', 'cotyle', true)"></icon>
                  <!-- <img style="margin: 0px 0 0 -2px;" src="/images/arrow-left.png" width="30" alt="" /> -->
                </div>
                <div class="flex-grow-1 text-center">Taille {{ cotyle_productSelected ? cotyle_productSelected.pr_size : "" }}</div>
                <div @click="changeSelectProduct('cotyle', 'size', 'plus')" class="pointer d-flex align-items-center justify-content-center">
                  <icon width="30" height="30" name="arrow-circle-right" class="" :color="getClassGray('plus', 'cotyle', true)"></icon>
                  <!-- <img style="margin: 0px 0 0 0;" src="/images/arrow-right.png" width="30" alt="" /> -->
                </div>
              </div>
            </div>
            <div v-if="showForm === 'tige'">
              <select class="custom-select" v-model="tige_model" @change="changeSelectProduct('tige', 'model')">
                <option v-for="option in items_tige_model" :value="option.key" :key="option.key">
                  {{ option.name }}
                </option>
              </select>
              <select class="custom-select my-2" v-model="tige_subtype" @change="changeSelectProduct('tige', 'subtype')">
                <option v-for="option in items_tige_subtype" :value="option.key" :key="option.key">
                  {{ option.name }}
                </option>
              </select>
              <select class="custom-select" v-model="tige_col" @change="changeSelectProduct('tige', 'col')">
                <option v-for="option in items_tige_col" :value="option.key" :key="option.key">
                  {{ option.name }}
                </option>
              </select>
              <!-- <m-form-combobox
                v-model="tige_model"
                placeholder="Modèle"
                :store-url="$config.server_url + '/api/1.0/products/groupby'"
                :store-params="{ type: 'tige', groupby: 'pr_model', token: $store.state.accesstoken }"
                item-value="pr_model"
                item-text="pr_model"
                :dark="true"
              ></m-form-combobox> -->
              <!-- <m-form-combobox
                v-model="tige_subtype"
                placeholder="Sous-type"
                :store-url="$config.server_url + '/api/1.0/products/groupby'"
                :store-params="{ type: 'tige', groupby: 'pr_subtype', token: $store.state.accesstoken }"
                item-value="pr_subtype"
                item-text="pr_subtype"
                :dark="true"
              ></m-form-combobox>
              <m-form-combobox
                v-model="tige_col"
                placeholder="Col"
                :store-url="$config.server_url + '/api/1.0/products/groupby'"
                :store-params="{ type: 'tige', groupby: 'pr_col', token: $store.state.accesstoken }"
                item-value="pr_col"
                item-text="pr_col"
                :dark="true"
              ></m-form-combobox> -->
              <div class="d-flex justify-content-between my-4 px-4">
                <div @click="changeSelectProduct('tige', 'size', 'moins')" class="pointer d-flex align-items-center justify-content-center">
                  <icon width="30" height="30" name="arrow-circle-left" class="" :color="getClassGray('moins', 'tige', true)"></icon>
                  <!-- <img style="margin: 0px 0 0 0;" src="/images/arrow-left.png" width="30" alt="" /> -->
                </div>
                <div class="flex-grow-1 text-center">Taille {{ tige_productSelected ? tige_productSelected.pr_size : "" }}</div>
                <div @click="changeSelectProduct('tige', 'size', 'plus')" class="pointer d-flex align-items-center justify-content-center">
                  <icon width="30" height="30" name="arrow-circle-right" class="" :color="getClassGray('plus', 'tige', true)"></icon>
                  <!-- <img style="margin: 0px 0 0 0;" src="/images/arrow-right.png" width="30" alt="" /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-grow-1 mb-1">
            <div class="flex-fill pr-2"><button @click="previousStep" class="btn btn-primary btn-sm w-100">Précédent</button></div>
            <div class="flex-fill pl-2"><button @click="nextStep" class="btn btn-primary btn-sm w-100">Suivant</button></div>
          </div>
        </div>
      </div>
    </div>
    <m-message-dialog v-model="messageDialog" title="Erreur" text="Vous devez choisir un cotyle et une tige"></m-message-dialog>

    <div class="winmodal" v-if="showhelp1"></div>
    <div class="helpwin" v-if="showhelp1">
      <div class="winclose">
        <label for="notdisplayanymore" style="margin: -5px 30px"
          ><input type="checkbox" id="notdisplayanymore" v-model="notdisplayanymore" />Ne plus afficher</label
        >
        <a href="javascript:void(0);" @click="$store.commit('set_help', ['prosthesis1', notdisplayanymore])"
          ><icon width="24" height="24" name="times" class="" color="black"></icon
        ></a>
      </div>
      <div class="row">
        <div class="col-md-8">
          <h3>Choix de l'implant Acétabulaire.</h3>
          <p>
            Commencez par sélectionner l’implant acétabulaire et le côté opéré. De haut en bas, ces sélecteurs représentent : <br />
            • Le côté opéré<br />
            • La gamme de cotyle<br />
            • La version de cotyle<br />
            • Le diamètre de la tête fémorale + matière de l’insert
          </p>
          <!-- <p>Cliquez ensuite sur le bouton Suivant en bas à droite.</p> -->
          <div>
            <button class="btn btn-primary" @click="$store.commit('set_help', ['prosthesis1', notdisplayanymore])">CHOIX DU COTYLE</button>
          </div>
        </div>
        <div class="col-md-4">
          <img src="/images/aide/05-choix-cotyle.png" class="img-fluid" alt="" />
        </div>
      </div>
    </div>

    <div class="winmodal" v-if="showhelp2"></div>
    <div class="helpwin" v-if="showhelp2">
      <div class="winclose">
        <label for="notdisplayanymore" style="margin: -5px 30px"
          ><input type="checkbox" id="notdisplayanymore" v-model="notdisplayanymore" />Ne plus afficher</label
        >
        <a href="javascript:void(0);" @click="$store.commit('set_help', ['prosthesis2', notdisplayanymore])"
          ><icon width="24" height="24" name="times" class="" color="black"></icon
        ></a>
      </div>
      <div class="row">
        <div class="col-md-8">
          <h3>Choix de la tige.</h3>
          <p>
            Sélectionnez ensuite la tige en cliquant sur les menus sélect à droite. De haut en bas ces sélecteurs représentent :<br />
            • La gamme<br />
            • La version ou col<br />
            • La tête fémorale qui est égale au diamètre tête + matière tête (Ex : 28mm_Ceram)
          </p>
          <!-- <p>Cliquez sur NOUVEAU PATIENT pour commencer</p> -->
          <div>
            <button class="btn btn-primary" @click="$store.commit('set_help', ['prosthesis2', notdisplayanymore])">CHOIX DE LA TIGE</button>
          </div>
        </div>
        <div class="col-md-4">
          <img src="/images/aide/06-choix-tige.png" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moveable from "moveable";
// var numeral = require("numeral");
import Hammer from "hammerjs";

export default {
  name: "prosthesis",
  components: {},
  data() {
    return {
      notdisplayanymore: false,
      showh1: false,
      showh2: false,
      // moins400X: -400,
      savedtr: 0,
      spinner: true,
      oldPosition: { xrotation: 0, yrotation: 0, xreference: 0, yreference: 0, ycood0: 0, set: false },
      nbOfMmOf100pxOfCotyle: 20,
      messageDialog: false,
      file: {},
      patient: {},
      // les inputs selects
      productsTree: [],

      items_cotyle_model: [],
      items_cotyle_subtype: [],
      items_cotyle_col: [],
      items_cotyle_size: [],
      cotyle_model: "",
      cotyle_subtype: "",
      cotyle_col: "",

      items_tige_model: [],
      items_tige_subtype: [],
      items_tige_col: [],
      items_tige_size: [],
      tige_model: "",
      tige_subtype: "",
      tige_col: "",

      cotyle_productSelected: null,
      tige_productSelected: null,

      scaleStart: -1,
      isPinching: false,
      bgimage: {},
      cotyleimage: null,
      tigeimage: null,
      getso: {},
      numImage: "1r",
      // mobile size
      tx: 0,
      ty: 0,
      toW: 0,
      toH: 0,
      scale: 1,
      currentStep: 1,
      isShowMenu: true,
      showForm: "cotyle"
    };
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.onkeyup);
    window.removeEventListener("resize", this.setWindowHeight);
    window.removeEventListener("gesturestart", this.preventDefaultPerso);
    window.removeEventListener("gesturechange", this.preventDefaultPerso);
    window.removeEventListener("gestureend", this.preventDefaultPerso);
    window.removeEventListener("wheel", this.preventDefaultPerso, { passive: false });
  },
  async mounted() {
    this.setWindowHeight();
    this.currentStep = this.$route.params.step * 1;
    if (this.currentStep === 1) {
      this.showh1 = true;
      this.showForm = "cotyle";
    }
    if (this.currentStep === 2) {
      this.showh2 = true;
      this.showForm = "tige";
    }
    this.$store.dispatch("addHistoryUrl", this.$route.path);
    window.addEventListener("keyup", this.onkeyup);
    window.addEventListener("resize", this.setWindowHeight);
    window.addEventListener("gesturestart", this.preventDefaultPerso);
    window.addEventListener("gesturechange", this.preventDefaultPerso);
    window.addEventListener("gestureend", this.preventDefaultPerso);
    window.addEventListener("wheel", this.preventDefaultPerso, { passive: false });

    this.drawElements();
    await this.loadTree();
    await this.loadPatient();
    // une fois qu'on a  le patient, on a les v-model des select, donc on met à jour les menu select.
    // this.items_cotyle_model = this.productsTree[0].items;
    // this.items_tige_model = this.productsTree[1].items;

    // initialiser les elects produit s'il y a un cotyle et une tige
    if (this.cotyle_model) this.defineSelectProduct("cotyle");
    if (this.tige_model) this.defineSelectProduct("tige");

    // window.addEventListener("gesturestart", e => {
    //   // console.log("gesturestart", e);
    // });
    // window.addEventListener("gesturechange", e => {
    //   // console.log("gesturechange", e);
    // });
    // window.addEventListener("gestureend", e => {
    //   // console.log("gestureend", e);
    // });

    // this.$nextTick(() => {
    //   this.initRadio();
    //   this.initCotyle();
    //   if (this.currentStep >= 2) this.initTige();
    // });

    // pour récupérer la hauteur de l'élément formulaire pour mobile et placer le chevron ouvrir/fermer
    this.$nextTick(() => {
      if (this.$refs.menuMobile) {
        let height = this.$refs.menuMobile.clientHeight;
        if (height > 0) {
          let heightString = height + "px";
          this.$refs.btnMenuMobile.style.bottom = heightString;
        }
      }
    });
  },
  watch: {
    showForm(val) {
      this.$nextTick(() => {
        if (this.$refs.menuMobile) {
          let height = this.$refs.menuMobile.clientHeight;
          if (height > 0) {
            let heightString = height + "px";
            this.$refs.btnMenuMobile.style.bottom = heightString;
          }
        }
      });
    }
  },
  computed: {
    showhelp1() {
      if (this.$store.state.help.prosthesis1 && this.showh1) return true;
      return false;
    },
    showhelp2() {
      if (this.$store.state.help.prosthesis2 && this.showh2) return true;
      return false;
    },
    stepTitle() {
      if (this.currentStep == 1 || this.showForm === "cotyle") return "Choisir le cotyle";
      if (this.currentStep == 2 || this.showForm === "tige") return "Choisir la tige";
      if (this.currentStep == 3) return "Résulat";
      return "";
    }
  },
  methods: {
    async loadTree() {
      // route qui récupère tous les produits triés par models, subtype, col et size  sous forme d'arbre
      let response = await this.$axios.get(this.$config.server_url + "/api/1.0/products/tree", {
        params: {}
      });
      this.productsTree = response.data.data;
      // console.log("this.productsTree", this.productsTree);
    },
    /**initialise les selects produits */
    defineSelectProduct(type, sizeSelected = null) {
      // console.log("type, sizeSelected", type, sizeSelected, this.tige_productSelected);
      let index = 0;
      if (type === "tige") index = 1;

      this.items_cotyle_model = this.productsTree[0].items;
      this.items_tige_model = this.productsTree[1].items;
      for (let m = 0; m < this.productsTree[index].items.length; m++) {
        const model = this.productsTree[index].items[m];
        // on definie les items subtype en tournant des les models et en trouvant le model sélectionné
        if (this[type + "_model"] && this[type + "_model"].toLowerCase() === model.key.toLowerCase()) {
          this["items_" + type + "_subtype"] = model.items;
          for (let s = 0; s < model.items.length; s++) {
            const subtype = model.items[s];
            if ((this[type + "_subtype"] && this[type + "_subtype"].toLowerCase() === subtype.key.toLowerCase()) || this[type + "_subtype"] == null) {
              if (this[type + "_subtype"] == null) this[type + "_subtype"] = subtype.key;
              // if (type == "cotyle") {
              //   this["items_" + type + "_size"] = subtype.items;
              //   for (let d = 0; d < subtype.items.length; d++) {
              //     const size = subtype.items[d];
              //     if (this.cotyle_productSelected == null && !sizeSelected) {
              //       this.cotyle_productSelected = size.product;
              //     }
              //     if (this.cotyle_productSelected == null && sizeSelected && sizeSelected === size.key) {
              //       this.cotyle_productSelected = size.product;
              //     }
              //   }
              //   if (!this.cotyle_productSelected) this.cotyle_productSelected = subtype.items[0].product;
              // }
              // if (type === "tige") {
              this["items_" + type + "_col"] = subtype.items;
              for (let c = 0; c < subtype.items.length; c++) {
                const col = subtype.items[c];
                if ((this[type + "_col"] && this[type + "_col"].toLowerCase() === col.key.toLowerCase()) || this[type + "_col"] == null) {
                  if (this[type + "_col"] == null) this[type + "_col"] = col.key;
                  this["items_" + type + "_size"] = col.items;
                  for (let d = 0; d < col.items.length; d++) {
                    const size = col.items[d];
                    if (this[type + "_productSelected"] == null && !sizeSelected) {
                      this[type + "_productSelected"] = size.product;
                    }
                    if (this[type + "_productSelected"] == null && sizeSelected && sizeSelected === size.key) {
                      this[type + "_productSelected"] = size.product;
                    }
                  }
                  if (!this[type + "_productSelected"]) {
                    this[type + "_productSelected"] = col.items[0].product;
                  }
                }
              }
              // }
            }
          }
        }
      }
      // console.log('this[type + "_col"]', type, this[type + "_col"]);
    },
    async changeSelectProduct(type, select, sens = "") {
      // console.log("this.tige_productSelected", this.tige_productSelected.pr_size);
      // on garde la taille en mémoire
      let size;
      if (this[type + "_productSelected"]) {
        size = this[type + "_productSelected"].pr_size;
      }
      // console.log("size", size);
      // on met les v-model à null et on appelle defineSelectProduct() pour redeffinir tous les selects
      if (select !== "size") {
        this[type + "_productSelected"] = null;
        if (select === "model") {
          this[type + "_subtype"] = null;
          // if (type === "tige") this.tige_col = null;
          this[type + "_col"] = null;
        }
        if (select === "subtype") {
          // && type === "tige"
          // this.tige_col = null;
          this[type + "_col"] = null;
        }
        this.defineSelectProduct(type, size);
      } else {
        this.defineSelectProduct(type, size);
        let indexSize = this.$_.findIndex(this["items_" + type + "_size"], { key: this[type + "_productSelected"].pr_size });
        if (indexSize >= 0) {
          if (sens === "plus" && indexSize < this["items_" + type + "_size"].length - 1) {
            this[type + "_productSelected"] = this["items_" + type + "_size"][indexSize + 1].product;
          }
          if (sens === "moins" && indexSize > 0) this[type + "_productSelected"] = this["items_" + type + "_size"][indexSize - 1].product;
        }
      }
      this.oldPosition.set = true;
      this.drawElements();
      window.setTimeout(() => {
        this.drawElements();
        window.setTimeout(() => {
          this.drawElements();
        }, 100);
      }, 100);
    },
    setWindowHeight() {
      this.$refs.main3parts.style.minHeight = document.documentElement.clientHeight - this.$refs.header.$refs.header.offsetHeight + "px";
    },
    getClassGray(sens, type, mobile = false) {
      // "#c6091f" => rouge vif , #d6979e=> rouge disabled
      if (!this[type + "_productSelected"]) return "";
      let indexSize = this.$_.findIndex(this["items_" + type + "_size"], { key: this[type + "_productSelected"].pr_size });
      if (indexSize === 0 && sens === "moins") {
        return mobile ? "#696969" : "#c8c7c7";
      }
      if (indexSize === this["items_" + type + "_size"].length - 1 && sens === "plus") {
        return mobile ? "#696969" : "#c8c7c7";
      }
      return "#c6091f";
    },
    showOrhideMenu() {
      // montrer ou cacher les menus selects
      let wContainer = this.$refs.menuMobile.offsetWidth + 15;
      if (this.isShowMenu) {
        this.$refs.menuMobile.style.transform = `translate(${wContainer}px, 0)`;
      } else {
        this.$refs.menuMobile.style.transform = `translate(0, 0)`;
      }
      // montrer ou cacher les deltas
      let wContainerDeltas = this.$refs.deltasMobile.offsetWidth + 15;
      if (this.isShowMenu) {
        this.$refs.deltasMobile.style.transform = `translate(${wContainerDeltas}px, 0)`;
      } else {
        this.$refs.deltasMobile.style.transform = `translate(0, 0)`;
      }

      this.isShowMenu = !this.isShowMenu;
    },
    preventDefaultPerso(e) {
      e.preventDefault();
    },
    initRadio() {
      this.$refs.bgimage.addEventListener("mousedown", e => e.preventDefault());
      let clickPosX = 0,
        clickPosY = 0;
      let startMoveGesto = false;
      this.addMultipleEventsListener(this.$refs.gestoparent, "mousedown touchstart", e => {
        if (e.type == "touchstart") e = e.touches[0];
        startMoveGesto = true;
        clickPosX = e.clientX;
        clickPosY = e.clientY;
      });
      this.addMultipleEventsListener(window, "mouseup touchend", e => {
        startMoveGesto = false;
      });
      this.addMultipleEventsListener(this.$refs.gestoparent, "mousemove touchmove", e => {
        if (e.type == "touchmove") e = e.touches[0];
        if (startMoveGesto) {
          let wContainer = this.$refs.gestoparent.offsetWidth,
            hContainer = this.$refs.gestoparent.offsetHeight;
          this.tx += (e.clientX - clickPosX) * (1 / this.scale);
          this.ty += (e.clientY - clickPosY) * (1 / this.scale);
          clickPosX = e.clientX;
          clickPosY = e.clientY;
          this.toW = wContainer / 2 - this.tx;
          this.toH = hContainer / 2 - this.ty;
          this.drawElements();
        }
      });
      let onzoom = e => {
        // let previousScale = scale;
        if (e.scale) this.scale = this.scaleStart + e.scale - 1;
        else this.scale -= e.deltaY / 800;
        if (this.scale < 0.01) this.scale = 0.01;
        // this.$refs.gesto.style.transformOrigin = `${toW}px ${toH}px`;
        // this.$refs.gesto.style.transform = `translate(${this.tx}px, ${this.ty}px) scale(${this.scale})`;
        this.drawElements();
        e.preventDefault();
      };
      this.$refs.gestoparent.addEventListener("wheel", onzoom);

      var hammertime = new Hammer(this.$refs.gestoparent, {});
      hammertime.get("pinch").set({ enable: true });
      hammertime.on("pinch", onzoom);
      hammertime.on("pinchstart", () => {
        this.isPinching = true;
        // console.log("this.isPinching", this.isPinching);
        this.scaleStart = this.scale;
      });
      hammertime.on("pinchend", () => {
        this.isPinching = false;
        // console.log("this.isPinching", this.isPinching);
      });
    },
    initCotyle() {
      // this.$refs.cotyleimage.addEventListener("mousedown", e => e.preventDefault());
      this.cotyleimage = new Moveable(this.$refs.gesto, {
        className: "moveable1",
        target: this.$refs.cotyleimage,
        // If the container is null, the position is fixed. (default: parentElement(document.body))
        container: this.$refs.gesto,
        draggable: true,
        resizable: false,
        scalable: false,
        rotatable: true,
        warpable: false,
        // Enabling pinchable lets you use events that
        // can be used in draggable, resizable, scalable, and rotateable.
        pinchable: false, // ["resizable", "scalable", "rotatable"]
        origin: true,
        keepRatio: false,
        // Resize, Scale Events at edges.
        edge: false,
        throttleDrag: 0,
        throttleResize: 0,
        throttleScale: 0,
        throttleRotate: 0,
        dragArea: true
      });
      this.cotyleimage
        //on click afficher le bon formulaire
        .on("click", ({ target, containsTarget, targetIndex }) => {
          this.showForm = target.dataset.image;
        })
        .on("dragStart", ({ target, clientX, clientY, inputEvent }) => {
          this.showForm = target.dataset.image;
          inputEvent.stopPropagation();
          inputEvent.preventDefault();
        })
        .on("drag", ({ target, transform, left, top, right, bottom, beforeDelta, beforeDist, delta, dist, clientX, clientY, inputEvent }) => {
          inputEvent.stopPropagation();
          if (this.isPinching) return;

          let o = this.getTransformOrigin(target);
          if (left + o.x < 0) left = -o.x;
          if (left + o.x > this.$refs.bgimage.offsetWidth) left = this.$refs.bgimage.offsetWidth - o.x;
          if (top + o.y < 0) top = -o.y;
          if (top + o.y > this.$refs.bgimage.offsetHeight) top = this.$refs.bgimage.offsetHeight - o.y;

          this.$refs.cotyleimage.style.left = `${left}px`;
          this.$refs.cotyleimage.style.top = `${top}px`;
          this.setDelta();
        })
        .on("dragEnd", ({ target, isDrag, clientX, clientY }) => {});
      this.cotyleimage
        .on("rotateStart", ({ target, clientX, clientY, inputEvent }) => {
          this.showForm = target.dataset.image;
          inputEvent.stopPropagation();
          inputEvent.preventDefault();
        })
        .on("rotate", ({ target, beforeDelta, delta, dist, transform, clientX, clientY }) => {
          if (this.isPinching) return;
          this.$refs.cotyleimage.style.transform = transform;
          this.setDelta();
        })
        .on("rotateEnd", ({ target, isDrag, clientX, clientY }) => {
          // alert("ok");
        });
    },
    initTige() {
      // this.$refs.tigeimage.addEventListener("mousedown", e => e.preventDefault());
      // this.$refs.tigeimage.addEventListener("touch", e => e.preventDefault());
      this.tigeimage = new Moveable(this.$refs.gesto, {
        className: "moveable1",
        target: this.$refs.tigeimage,
        container: this.$refs.gesto,
        draggable: true,
        resizable: false,
        scalable: false,
        rotatable: true,
        warpable: false,
        // transformOrigin: "1% 1%",
        // defaultGroupOrigin: "1% 1%",
        // Enabling pinchable lets you use events that
        // can be used in draggable, resizable, scalable, and rotateable.
        pinchable: true, // ["resizable", "scalable", "rotatable"]
        origin: true,
        keepRatio: false,
        // Resize, Scale Events at edges.
        edge: false,
        throttleDrag: 0,
        throttleResize: 0,
        throttleScale: 0,
        throttleRotate: 0,
        dragArea: true
      });
      this.tigeimage
        .on("click", ({ target, containsTarget, targetIndex }) => {
          // If you click on an element other than the target and not included in the target, index is -1.
          this.showForm = target.dataset.image;
        })
        .on("dragStart", ({ target, clientX, clientY, inputEvent }) => {
          this.showForm = target.dataset.image;
          inputEvent.stopPropagation();
          inputEvent.preventDefault();
        })
        .on("drag", ({ target, transform, left, top, right, bottom, beforeDelta, beforeDist, delta, dist, clientX, clientY, inputEvent }) => {
          inputEvent.stopPropagation();
          if (this.isPinching) return;
          // console.log("drag");

          // let o = this.getTransformOrigin(target);
          // if (left + o.x < 0) left = -o.x;
          // if (left + o.x > this.$refs.bgimage.offsetWidth) left = this.$refs.bgimage.offsetWidth - o.x;
          // if (top + o.y < 0) top = -o.y;
          // if (top + o.y > this.$refs.bgimage.offsetHeight) top = this.$refs.bgimage.offsetHeight - o.y;

          this.$refs.tigeimage.style.left = `${left}px`;
          this.$refs.tigeimage.style.top = `${top}px`;
          this.setDelta();
        })
        .on("dragEnd", ({ target, isDrag, clientX, clientY }) => {});
      this.tigeimage
        .on("rotateStart", ({ target, clientX, clientY, inputEvent }) => {
          this.showForm = target.dataset.image;
          inputEvent.stopPropagation();
          inputEvent.preventDefault();
        })
        .on("rotate", ({ target, beforeDelta, delta, dist, transform, clientX, clientY }) => {
          if (this.isPinching) return;
          this.$refs.tigeimage.style.transform = transform;
          this.setDelta();
        })
        .on("rotateEnd", ({ target, isDrag, clientX, clientY }) => {
          // alert("ok");
          // this.angle1 = this.getRotateAngle2(this.$refs.cotyleimage);
          this.drawElements();
        });
    },
    placeElements(type) {
      if (this.$refs[type + "image"]) {
        this.$refs[type + "image"].style.left = `${this.patient["pa_" + type + "_img_offsetx"]}px`;
        this.$refs[type + "image"].style.top = `${this.patient["pa_" + type + "_img_offsety"]}px`;
        this.$refs[type + "image"].style.transform = `rotate(${this.patient["pa_" + type + "_img_rotateangle"]}deg)`;
      }
    },
    centerElementIfZero(type) {
      // alert(type);
      // console.log("type", type);
      // return;
      let wImage = this.$refs.bgimage.offsetWidth,
        hImage = this.$refs.bgimage.offsetHeight;
      let rl = this.patient.pa_lateralite == "right" ? "r" : "l";

      if (this.$refs[type + "image"]) {
        if (this.patient["pa_" + type + "_img_offsetx"] * 1 === 0 && this[type + "_productSelected"]) {
          // console.log("zero", type);
          this.patient["pa_" + type + "_img_offsetx"] = wImage / 2 - this[type + "_productSelected"]["pr_imagesize_x_" + rl] / 2;
          this.patient["pa_" + type + "_img_offsety"] = hImage / 2 - this[type + "_productSelected"]["pr_imagesize_y_" + rl] / 2;
          // if (type == "tige") {
          //   let dg = -200;
          //   if (this.patient.pa_lateralite == "right") dg = 200;
          //   dg = 0;
          //   this.patient["pa_" + type + "_img_offsetx"] = parseInt(this.$refs["cotyleimage"].style.left) + dg;
          //   this.patient["pa_" + type + "_img_offsety"] = parseInt(this.$refs["cotyleimage"].style.top) + 250;
          // }
          this.$refs[type + "image"].style.left = `${this.patient["pa_" + type + "_img_offsetx"]}px`;
          this.$refs[type + "image"].style.top = `${this.patient["pa_" + type + "_img_offsety"]}px`;
        }
      }
    },
    drawElements() {
      // console.log("drawElements");
      this.centerElementIfZero("cotyle");
      if (this.currentStep >= 2) this.centerElementIfZero("tige");
      // let invert = this.patient.pa_lateralite == "right" ? 1 : 0;
      let rl = this.patient.pa_lateralite == "right" ? "r" : "l";
      this.numImage = this.patient.pa_lateralite == "right" ? "1r" : "1l";
      this.$refs.gesto.style.transformOrigin = `${this.toW}px ${this.toH}px`;
      // this.scale = 1;
      this.$refs.gesto.style.transform = `translate(${this.tx}px, ${this.ty}px) scale(${this.scale}, ${this.scale})`;
      let scaleElements = this.nbOfMmOf100pxOfCotyle / (this.patient.pa_toolsscale * this.patient.pa_toolsballsize);
      if (this.$refs.cotyleimage && this.cotyle_productSelected) {
        this.$refs.cotyleimage.style.width = this.cotyle_productSelected["pr_imagesize_x_" + rl] + "px";
        this.$refs.cotyleimage.style.height = this.cotyle_productSelected["pr_imagesize_y_" + rl] + "px";
        this.$refs.cotyleimage.style.transformOrigin = `${this.cotyle_productSelected["pr_rotationcenter_x_" + rl] * 1}px ${
          this.cotyle_productSelected["pr_rotationcenter_y_" + rl] * 1
        }px`;
        let angle = this.getRotateAngle2(this.$refs.cotyleimage);
        this.$refs.cotyleimage.style.transform = `scaleX(${scaleElements}) scaleY(${scaleElements}) rotate(${this.radToDeg(angle)}deg)`;
        if (this.cotyleimage) this.cotyleimage.updateRect();
      }
      if (this.$refs.tigeimage && this.tige_productSelected) {
        let toy = this.tige_productSelected["pr_rotationcenter_y_" + rl] * 1 - this.tige_productSelected.pr_cood_0y + 80;
        toy = 380;

        // 1000 >> -400
        // 300 >>
        // 700 >> -400
        // 1000
        // this.moins400X = -1 * this.tige_productSelected["pr_rotationcenter_x_" + rl] + 100;
        // this.axeX = 100;
        // if (invert) {
        // this.moins400X =
        //   -1 * (this.tige_productSelected["pr_imagesize_x_" + rl] - this.tige_productSelected["pr_rotationcenter_x_" + rl]) + 400 - 100;
        // this.axeX = 300;
        // }
        // console.log("this.moins400X", this.moins400X, this.tige_productSelected.pr_imagesize_x, this.tige_productSelected.pr_rotationcenter_x);
        // console.log("this.$refs.tigeimagecontent.style.offsetWidth", this.$refs.tigeimagecontent.offsetWidth);
        // console.log("this.tige_productSelected", this.tige_productSelected.pr_imagesize_x);
        // if (this.tige_productSelected.pr_imagesize_x < 1000) this.moins400X = 0;
        // this.$refs.tigeimagecontent.style.left = this.moins400X + "px";
        // this.$refs.tigeimagecontent.style.top = -1 * this.tige_productSelected.pr_rotationcenter_y + 380 + "px";
        // this.$refs.tigeimagecontent.style.left = 0 + "px";
        // this.$refs.tigeimagecontent.style.top = 0 + "px";
        this.$refs.tigeimage.style.width = this.tige_productSelected["pr_imagesize_x_" + rl] + "px";
        this.$refs.tigeimage.style.height = this.tige_productSelected["pr_imagesize_y_" + rl] + "px";
        this.$refs.tigeimage.style.transformOrigin = `${this.tige_productSelected["pr_rotationcenter_x_" + rl]}px ${
          this.tige_productSelected["pr_rotationcenter_y_" + rl]
        }px`;
        // this.$refs.tigeimage.style.transformOrigin = "100px 365px";
        let angle = this.getRotateAngle2(this.$refs.tigeimage);
        this.$refs.tigeimage.style.transform = `scaleX(${scaleElements}) scaleY(${scaleElements}) rotate(${this.radToDeg(angle)}deg)`;

        if (this.oldPosition.set) {
          // alert("ici");
          let dy1 =
            (this.tige_productSelected["pr_rotationcenter_y_" + rl] -
              this.tige_productSelected["pr_referencecenter_y_" + rl] -
              (this.oldPosition.yrotation - this.oldPosition.yreference)) *
            scaleElements;
          let top = this.$refs.tigeimage.style.top.substring(0, this.$refs.tigeimage.style.top.length - 2) * 1;
          let left = this.$refs.tigeimage.style.left.substring(0, this.$refs.tigeimage.style.left.length - 2) * 1;
          let deltax = -Math.sin(angle) * dy1;
          let deltay = Math.cos(angle) * dy1;
          this.$refs.tigeimage.style.left = left + deltax + "px";
          this.$refs.tigeimage.style.top = top + deltay + "px";
          this.oldPosition.set = false;
        }
        this.oldPosition.xreference = this.tige_productSelected["pr_referencecenter_x_" + rl];
        this.oldPosition.yreference = this.tige_productSelected["pr_referencecenter_y_" + rl];
        this.oldPosition.xrotation = this.tige_productSelected["pr_rotationcenter_x_" + rl];
        this.oldPosition.yrotation = this.tige_productSelected["pr_rotationcenter_y_" + rl];
        this.oldPosition.ycood0 = this.tige_productSelected.pr_cood_0y;
        this.oldPosition.toy = toy;

        if (this.tigeimage) this.tigeimage.updateRect();
      }
      this.setDelta();

      // let scaleRuler = (200 * 100) / (this.patient.pa_toolsballsize * this.patient.pa_toolsscale);
      // this.$refs.ruler.style.transform = `scaleY(${scaleRuler / 100})`;
    },
    setDelta() {
      if (!this.cotyleimage || !this.tigeimage || !this.cotyle_productSelected || !this.tige_productSelected) return;

      // let invert = this.patient.pa_lateralite == "right" ? 1 : 0;
      let rl = this.patient.pa_lateralite == "right" ? "r" : "l";
      let angle = this.getRotateAngle2(this.tigeimage.target);
      // let invert = this.patient.pa_lateralite == "right" ? -1 : 1;
      // invert = 1;

      // let deltaX = this.cotyleimage.target.offsetLeft - this.tigeimage.target.offsetLeft - 103,
      //   deltaY = this.cotyleimage.target.offsetTop - this.tigeimage.target.offsetTop + 66;
      let scaleElements = this.nbOfMmOf100pxOfCotyle / (this.patient.pa_toolsscale * this.patient.pa_toolsballsize);

      // console.log("this.axeX", this.axeX, parseFloat(this.tigeimage.target.style.left));
      let posXCotyle = parseFloat(this.cotyleimage.target.style.left) + this.cotyle_productSelected["pr_rotationcenter_x_" + rl];
      let posYCotyle = parseFloat(this.cotyleimage.target.style.top) + this.cotyle_productSelected["pr_rotationcenter_y_" + rl];

      // let posYTige = this.tigeimage.target.offsetTop + this.tige_productSelected.pr_rotationcenter_y - this.tige_productSelected.pr_cood_0y + 80;
      let posXTige = parseFloat(this.tigeimage.target.style.left) + this.tige_productSelected["pr_rotationcenter_x_" + rl];
      // if (invert)
      //   posXTige =
      //     parseFloat(this.tigeimage.target.style.left) +
      //     this.tige_productSelected.pr_imagesize_x -
      //     this.tige_productSelected.pr_rotationcenter_x +
      //     this.moins400X;
      let posYTige = parseFloat(this.tigeimage.target.style.top) + this.tige_productSelected["pr_rotationcenter_y_" + rl];
      // console.log("posXTige,posXCotyle", posXTige, posXCotyle, posYTige, posYCotyle);

      let calculPoint = point => {
        let dx = (point.x - this.tige_productSelected["pr_rotationcenter_x_" + rl]) * scaleElements;
        // if (invert) dx = -dx;
        // console.log("dx", dx);
        let dy = (point.y - this.tige_productSelected["pr_rotationcenter_y_" + rl]) * scaleElements;
        let myAlpha = Math.atan(dx / dy);
        let h = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
        let deltaX = posXCotyle - (posXTige + Math.cos(myAlpha - angle + Math.PI / 2) * h);
        let deltaY = posYCotyle - (posYTige + -1 * Math.sin(myAlpha - angle + Math.PI / 2) * h);

        let delta0X = deltaX * Math.cos(angle) + deltaY * Math.sin(angle);
        delta0X = (-1 * (delta0X * this.patient.pa_toolsscale * this.patient.pa_toolsballsize)) / 100;
        // this.patient.pa_delta0_x = numeral(delta0X).format("0.0");
        let delta0Y = -1 * (deltaX * Math.sin(-angle) + deltaY * Math.cos(-angle));
        delta0Y = (-1 * (delta0Y * this.patient.pa_toolsscale * this.patient.pa_toolsballsize)) / 100;
        // this.patient.pa_delta0_y = numeral(delta0Y).format("0.0");
        // if (rl == "r") delta0X *= -1;
        return { dx: delta0X, dy: delta0Y };
      };
      let p0 = calculPoint({ x: this.tige_productSelected["pr_cood_0x_" + rl], y: this.tige_productSelected["pr_cood_0y_" + rl] });
      let invertSign = 1;
      if (rl == "l") invertSign = -1;
      this.patient.pa_delta0_x = p0.dx * invertSign;
      this.patient.pa_delta0_y = p0.dy;

      let pMax35 = calculPoint({ x: this.tige_productSelected["pr_cood_p35x_" + rl], y: this.tige_productSelected["pr_cood_p35y_" + rl] });
      this.patient.pa_delta_max_x = pMax35.dx * invertSign;
      this.patient.pa_delta_max_y = pMax35.dy;

      let pMin35 = calculPoint({ x: this.tige_productSelected["pr_cood_m35x_" + rl], y: this.tige_productSelected["pr_cood_m35y_" + rl] });
      this.patient.pa_delta_min_x = pMin35.dx * invertSign;
      this.patient.pa_delta_min_y = pMin35.dy;

      // let plusMoins = 6;
      // let deltaMaxX =
      //   (deltaX + plusMoins * Math.cos(angle - Math.PI / 4)) * Math.cos(angle) +
      //   (deltaY + plusMoins * Math.sin(angle - Math.PI / 4)) * Math.sin(angle);
      // deltaMaxX = (-1 * (deltaMaxX * this.patient.pa_toolsscale * this.patient.pa_toolsballsize)) / 100;
      // this.patient.pa_delta_max_x = numeral(deltaMaxX).format("0.0");
      // let deltaMaxY =
      //   -1 *
      //   ((deltaX + plusMoins * Math.cos(angle - Math.PI / 4)) * Math.sin(-angle) +
      //     (deltaY + plusMoins * Math.sin(angle - Math.PI / 4)) * Math.cos(-angle));
      // deltaMaxY = (-1 * (deltaMaxY * this.patient.pa_toolsscale * this.patient.pa_toolsballsize)) / 100;
      // this.patient.pa_delta_max_y = numeral(deltaMaxY).format("0.0");

      // let deltaMinX =
      //   (deltaX + -plusMoins * Math.cos(angle - Math.PI / 4)) * Math.cos(angle) +
      //   (deltaY + -plusMoins * Math.sin(angle - Math.PI / 4)) * Math.sin(angle);
      // deltaMinX = (-1 * (deltaMinX * this.patient.pa_toolsscale * this.patient.pa_toolsballsize)) / 100;
      // this.patient.pa_delta_min_x = numeral(deltaMinX).format("0.0");
      // let deltaMinY =
      //   -1 *
      //   ((deltaX + -plusMoins * Math.cos(angle - Math.PI / 4)) * Math.sin(-angle) +
      //     (deltaY + -plusMoins * Math.sin(angle - Math.PI / 4)) * Math.cos(-angle));
      // deltaMinY = (-1 * (deltaMinY * this.patient.pa_toolsscale * this.patient.pa_toolsballsize)) / 100;
      // this.patient.pa_delta_min_y = numeral(deltaMinY).format("0.0");

      this.$forceUpdate();

      this.saveOldPos();
    },
    saveOldPos() {},
    centerBgImage() {
      const c = () => {
        let wContainer = this.$refs.gestoparent.offsetWidth,
          hContainer = this.$refs.gestoparent.offsetHeight,
          wImage = this.$refs.bgimage.offsetWidth,
          hImage = this.$refs.bgimage.offsetHeight;
        if (wContainer / wImage > hContainer / hImage) {
          this.scale = hContainer / hImage;
          this.tx = (wContainer - wImage) / 2;
          this.ty = (hImage * this.scale - hImage) / 2;
        } else {
          this.scale = wContainer / wImage;
          this.ty = (hContainer - hImage) / 2;
          this.tx = (wImage * this.scale - wImage) / 2;
        }
        this.toW = wContainer / 2 - this.tx;
        this.toH = hContainer / 2 - this.ty;
        this.drawElements();
      };
      this.$nextTick(() => {
        c();
        setTimeout(() => {
          c();
          setTimeout(() => {
            c();
          }, 500);
        }, 200);
      });
    },
    absorbEvent_(event) {
      var e = event || window.event;
      e.preventDefault && e.preventDefault();
      // e.stopPropagation && e.stopPropagation();
      // e.cancelBubble = true;
      // e.returnValue = false;
      return true;
    },
    imageRadioLoaded(e) {
      this.$refs.imgRadio.ontouchstart = this.absorbEvent_;
      this.$refs.imgRadio.ontouchmove = this.absorbEvent_;
      this.$refs.imgRadio.ontouchend = this.absorbEvent_;
      this.$refs.imgRadio.ontouchcancel = this.absorbEvent_;

      this.$nextTick(() => {
        this.centerBgImage();
        this.spinner = false;
        // console.warn("image loaded");
      });
    },
    addMultipleEventsListener(el, s, fn) {
      s.split(" ").forEach(e => el.addEventListener(e, fn, false));
    },
    async loadPatient() {
      let response = await this.$axios.get(this.$config.server_url + "/api/1.0/patients/" + this.$route.params.id);
      this.patient = response.data.data;
      if (!this.patient.pa_lateralite) this.patient.pa_lateralite = "left";
      if (response.data.data && response.data.data.pa_cotyle) {
        this.defineVmodelSelectProduct(response.data.data.pa_cotyle);
        this.placeElements("cotyle");
      }
      if (response.data.data && response.data.data.pa_tige) {
        this.defineVmodelSelectProduct(response.data.data.pa_tige);
        this.placeElements("tige");
      }
      // console.log("response.data.data", response.data.data);
      if (response.data.data && (!response.data.data.pa_cotyle || !response.data.data.pa_tige)) this.loadDefaultProduct();
      // this.patient.pa_toolsscale =
      // console.log("response.data.data", response.data.data);

      this.$nextTick(() => {
        // this.drawElements();
        this.initRadio();
        this.initCotyle();
        if (this.currentStep >= 2) this.initTige();
        this.centerBgImage();
        // this.drawElements();
      });
    },
    async loadDefaultProduct() {
      // console.log("loadDefaultProduct");
      let response = await this.$axios.get(this.$config.server_url + "/api/1.0/productsdefault");
      this.defineVmodelSelectProduct(response.data.data.tige);
      this.defineVmodelSelectProduct(response.data.data.cotyle);
      this.defineSelectProduct("cotyle");
      this.defineSelectProduct("tige");
    },
    defineVmodelSelectProduct(product) {
      // define les v model
      this[product.pr_type + "_productSelected"] = product;
      this[product.pr_type + "_model"] = product.pr_model;
      this[product.pr_type + "_subtype"] = product.pr_subtype;
      // if (product.pr_type === "tige") {
      this[product.pr_type + "_col"] = product.pr_col;
      // }
    },
    onkeyup(event) {
      if (!this.cotyleimage) return;
      let obj = this.cotyleimage.target;
      if (this.showForm == "tige") {
        if (!this.tigeimage) return;
        obj = this.tigeimage.target;
      }
      let deltaXY = 0.5;
      if (event.key == "ArrowLeft") obj.style.left = parseFloat(obj.style.left) - deltaXY + "px";
      if (event.key == "ArrowRight") obj.style.left = parseFloat(obj.style.left) + deltaXY + "px";
      if (event.key == "ArrowUp") obj.style.top = parseFloat(obj.style.top) - deltaXY + "px";
      if (event.key == "ArrowDown") obj.style.top = parseFloat(obj.style.top) + deltaXY + "px";
      // if (event.key == "+") this.scale += 0.1;
      // if (event.key == "-") this.scale += -0.1;
      this.drawElements();
    },

    async savePatient() {
      let dataPaProthesis = {};
      dataPaProthesis.pa_cotyle = this.cotyle_productSelected;
      dataPaProthesis.pa_tige = this.tige_productSelected;
      dataPaProthesis.pa_lateralite = this.patient.pa_lateralite;
      // on récupère les positions des images des prothèses
      dataPaProthesis.pa_cotyle_img_offsetx = this.cotyleimage.target.offsetLeft;
      dataPaProthesis.pa_cotyle_img_offsety = this.cotyleimage.target.offsetTop;
      dataPaProthesis.pa_cotyle_img_rotateangle = this.radToDeg(this.getRotateAngle2(this.cotyleimage.target));
      // on récupère les deltas
      dataPaProthesis.pa_delta0_x = this.patient.pa_delta0_x;
      dataPaProthesis.pa_delta_min_x = this.patient.pa_delta_min_x;
      dataPaProthesis.pa_delta_max_x = this.patient.pa_delta_max_x;
      dataPaProthesis.pa_delta0_y = this.patient.pa_delta0_y;
      dataPaProthesis.pa_delta_min_y = this.patient.pa_delta_min_y;
      dataPaProthesis.pa_delta_max_y = this.patient.pa_delta_max_y;

      if (this.tigeimage && this.tigeimage.target) {
        dataPaProthesis.pa_tige_img_offsetx = this.tigeimage.target.offsetLeft;
        dataPaProthesis.pa_tige_img_offsety = this.tigeimage.target.offsetTop;
        dataPaProthesis.pa_tige_img_rotateangle = this.radToDeg(this.getRotateAngle2(this.tigeimage.target));
      }
      await this.$axios.put(this.$config.server_url + "/api/1.0/patients/" + this.$route.params.id, dataPaProthesis);
    },
    radToDeg(rad) {
      return (rad * 180) / Math.PI;
    },
    getTransformOrigin(target) {
      let x = 0,
        y = 0;
      // let o = target.style.transformOrigin;
      let st = window.getComputedStyle(target, null);
      if (!st) return { x, y };
      let tr = st.getPropertyValue("transform-origin");
      if (!tr || tr == "none") return { x, y };
      x = parseInt(tr.split(" ")[0]);
      y = parseInt(tr.split(" ")[1]);
      return { x, y };
    },
    getRotateAngle2(target) {
      let st = window.getComputedStyle(target, null);
      if (!st) return 0;
      let tr = st.getPropertyValue("transform");
      if (!tr || tr == "none") return 0;

      let values = tr.split("(")[1];
      values = values.split(")")[0];
      values = values.split(",");
      let a = values[0];
      let b = values[1];
      // let c = values[2];
      // let d = values[3];
      let angle = Math.atan2(b, a);
      // if (b * -1 != c * 1) {
      //   angle = Math.PI - angle;
      // }
      // if (target.dataset.image == "tige") console.log("tr", tr, target.dataset.image, angle, a, b, c, d);
      return angle;
    },
    // getRotateAngle(style) {
    //   let angle = 0;
    //   let regexp1 = new RegExp(/rotate\(/);
    //   if (regexp1.test(style)) {
    //     let index1 = style.indexOf("rotate(");
    //     let index2 = style.indexOf("deg)");
    //     angle = style.substring(index1 + 7, index2);
    //   }
    //   return angle * 1;
    // },
    async previousStep() {
      //enregistrer les prothèses
      await this.savePatient();
      // if (this.currentStep == 1) {
      this.showh1 = true;
      this.$router.push("/ratio/" + this.$route.params.id).catch(err => {});
      // }
      // if (this.currentStep == 2) {
      //   this.currentStep = 1;
      //   this.showForm = "cotyle";
      //   this.tigeimage.destroy();
      //   this.tigeimage = null;
      //   this.savePatient();
      //   this.$router.push("/prosthesis/" + this.$route.params.id + "/1");
      // }
    },
    async nextStep() {
      //enregistrer les prothèses
      await this.savePatient();
      if (this.currentStep == 1) {
        this.showh2 = true;
        this.$router.push(`/prosthesis/${this.patient.pa_id}/2`).catch(err => {});
        this.currentStep = 2;
        this.showForm = "tige";
        this.savePatient();
        this.$nextTick(() => {
          this.placeElements("tige");
          this.drawElements();
          this.initTige();
          this.setDelta();
        });
        return;
      }
      if (this.currentStep === 2) {
        // if pas de cotyle ou de tige return
        if (this.currentStep === 2 && (!this.cotyle_productSelected.pr_id || !this.tige_productSelected.pr_id)) {
          this.messageDialog = true;
          return;
        }
        // go page de résultats si this.currentStep == 2
        this.$router.push("/infos/" + this.$route.params.id).catch(err => {});
      }
    }
  }
};
</script>

<style scoped lang="scss">
///// flex - col responsive
.main3parts {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 -15px;
  margin-top: 40px;
  .main3parts-col1 {
    flex-direction: row;
    padding: 15px;
    .icos2 {
      margin-left: 30px;
    }
  }
  .main3parts-col2 {
    overflow: hidden;
  }
  .main3parts-col3 {
    padding: 15px;
  }
}
@media (min-width: 576px) {
  .main3parts {
    margin-top: 40px;
    flex-direction: row;
    .main3parts-col1,
    .main3parts-col3 {
      min-width: 20%;
    }
    .main3parts-col2 {
      min-width: 60%;
    }
    .main3parts-col1 {
      flex-direction: row;
      .icos2 {
        margin-left: 0;
        margin-top: 30px;
      }
    }
  }
}
// .container-img-radio {
//   overflow: hidden;
// }
/////// partie de droite

.container-icon-range {
  /*   background-color: #fff;
  border-radius: 50%;
  border: 1px solid gray;
  width: 20px;
  height: 20px;
  padding: 1px; */
  cursor: pointer;
}
.gray {
  background-color: #a7a7a7;
}
.gray-mobile {
  background-color: #403e3e !important;
}
.container-icon-range-mobile {
  background-color: #ffffff7a;
  width: 35px;
  height: 35px;
}
/* .mobile-productSelected {
  border-bottom: 2px solid #fff;
  padding-bottom: 2px;
} */
.bgimage,
.cotyleimage,
.tigeimage {
  position: absolute;
}
.cotyleimage {
  // position: relative;
  // pour mettre la couleur verte
  filter: invert(1) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
  width: 300px;
  height: 300px;
  overflow: hidden;
}
.tigeimage {
  // position: relative;
  // pour mettre la couleur rouge
  filter: invert(1) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
  width: 400px;
  height: 1000px;
  overflow: hidden;
}
.cotyleimagecontent {
  position: absolute;
  top: 0px;
  left: 0px;
}
.tigeimagecontent {
  // transform-origin: 1px 1px;
  position: absolute;
  top: 0;
  left: 0;
}
.delta-mobile {
  position: absolute;
  background-color: #353a4066;
  color: white;
  width: 100%;
  z-index: 1000;
  transition: transform 0.5s ease-out;
}
// .ruler {
//   background-color: rgba(255, 0, 0, 0.3);
//   position: absolute;
//   top: 500px;
//   right: 100px;
//   width: 30px;
//   height: 100px;
// }
// .cotyleimage {
//   background-position: -300px -300px;
//   width: 400px;
//   height: 400px;
// }
// .tigeimage {
//   background-position: -400px 0;
//   width: 400px;
//   height: 1000px;
// }
.custom-select {
  background-color: #ffffff85;
}
.form-selected {
  background-color: #c6091f;
  border-radius: 20px;
  padding: 5px 30px;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  position: absolute;
  z-index: 100;
  top: 20px;
  left: 30px;
}
/*************** css switch droite / gauche **************** */
.tabber {
  cursor: pointer;
  width: 230px;
  height: 28px;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  background-color: #c3c9d4;
  border: 1px solid #bababa;
  border-radius: 20px;
}
.tabber-mobile {
  color: #485057;
  background-color: #ffffff73;
}
.label-lateralite {
  position: absolute;
  padding: 0 20px;
  top: 2px;
  z-index: 10;
  margin-bottom: 0;
  transition: transform 125ms ease-in-out, filter 125ms ease-in-out;
}
.label-G {
  left: 8px;
}
.label-D {
  right: 0px;
}
input[type="radio"] {
  display: none;

  // static
  &#lateralitegc ~ .blob,
  &#lateralitegm ~ .blob,
  &#lateralitegt ~ .blob {
    transform-origin: right center;
  }

  &#lateralitedc ~ .blob,
  &#lateralitedt ~ .blob,
  &#lateralitedm ~ .blob {
    transform-origin: left center;
  }

  // animated
  &#lateralitegc:checked,
  &#lateralitegt:checked,
  &#lateralitegm:checked {
    ~ .blob {
      background: #fff;
      animation-name: stretchyRev;
    }
  }

  &#lateralitedc:checked,
  &#lateralitedt:checked,
  &#lateralitedm:checked {
    ~ .blob {
      background-color: #fff;
      animation-name: stretchy;
    }
  }
}
.blob {
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: 0;
  border-radius: 4rem;
  animation-duration: 0.5s;
  animation-direction: forwards;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transition: transform 150ms ease;

  &:before,
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    background-color: inherit;
    height: 100%;
    width: 50%;
    border-radius: 100%;
    transition: transform 150ms ease;
    animation-name: pulse;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  &:before {
    left: 0;
    animation-delay: 0.15s;
  }

  &:after {
    right: 0;
  }
}
@keyframes stretchy {
  0% {
    transform: translateX(0) scaleX(1);
  }

  100% {
    transform: translateX(100%) scaleX(1);
  }
}

@keyframes stretchyRev {
  0% {
    transform: translateX(100%) scaleX(1);
  }

  100% {
    transform: translateX(0) scaleX(1);
  }
}
</style>
