<template>
  <section id="login" class="d-flex align-items-center relative">
    <img style="width: 100px" class="img-fluid my-3 d-none d-md-block logo-absolute" src="/images/logo-eurotxt-black.png" alt="" />
    <div class="container" id="camera">
      <div class="row">
        <div class="col-md-7 offset-md-5 col-9 offset-3">
          <img style="width: 120px" class="img-fluid my-4 d-md-none" src="/images/logo-eurotxt-black.png" alt="" />
          <h1 class="bis gros-titre">Outil d'aide au choix prothèse hanche</h1>
          <div class="row relative">
            <div class="col-md-12">
              <h3 class="bis">Inscription</h3>
              <div v-if="errLogin" class="alert alert-danger" v-html="errLogin"></div>
              <div v-if="sucessSubscription" class="alert alert-success" v-html="sucessSubscription"></div>
            </div>
          </div>
          <div v-if="!sucessSubscription">
            <div class="row">
              <div class="col-md-6">
                <label class="mb-0" for="civility">Civilité</label>
                <select class="custom-select" style="height: 30px" v-model="contact.co_civility" id="civility">
                  <option value="Mme">Madame</option>
                  <option value="M">Monsieur</option>
                  <option value="Dr.">Docteur</option>
                  <option value="Prof.">Prof.</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <m-form-text label="Nom" v-model="contact.co_name" name="name"></m-form-text>
              </div>
              <div class="col-md-6">
                <m-form-text label="Prénom" v-model="contact.co_firstname" name="firstname"></m-form-text>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <m-form-text label="Email" v-model="contact.co_email" name="email"></m-form-text>
              </div>
              <div class="col-md-6">
                <m-form-text label="Téléphone" v-model="contact.co_phone_mobile" name="phone"></m-form-text>
              </div>
            </div>
            <div>
              <m-form-password :showRules="true" valid="shadow" :rules="rulesPassword" @validPassword="validPassword"></m-form-password>
            </div>
            <div class="row">
              <div class="col-md-12 d-flex">
                <m-form-checkbox class="ml-1 mb-0 mt-3" v-model="cgu" label="Valider les cgu" name="cgu"></m-form-checkbox>
                <a href="" @click="$router.push('/cgu').catch(err => {})" class="ml-3 mt-3">Lire les cgu</a>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12 d-flex flex-column">
                <button class="btn btn-primary mt-2" style="width: fit-content" @click="subscribe()">S'inscrire</button>
              </div>
            </div>
          </div>
          <div class="row mt-2 mb-5">
            <div class="col-md-12 d-flex flex-column">
              <a href="" @click="$router.push('/login').catch(err => {})" class="mb-3">J'ai déjà un compte</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "subscribe",
  components: {},
  async mounted() {},
  data() {
    return {
      errLogin: false,
      sucessSubscription: false,
      contact: {
        co_civility: "",
        co_password: "",
        co_name: "",
        co_firstname: "",
        co_email: "",
        co_phone_mobile: ""
      },
      cgu: false,
      password1: "",
      rulesPassword: {
        nbCharacteres: 8,
        specialCharactere: true,
        uppercase: true,
        number: true
      },
      isValidPassword: false,
      errPassword: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    validPassword(isValid, errors, password) {
      this.isValidPassword = isValid;
      this.errPassword = errors;
      this.password1 = password;
    },
    async subscribe() {
      this.errLogin = "";
      let err = [];
      let fieldRequired = [
        // { field: "co_civility", text: "civilité" },
        { field: "co_name", text: "nom" },
        { field: "co_firstname", text: "prénom" },
        { field: "co_email", text: "e-mail" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.contact[field.field]) err.push(field);
      }
      /**** vérifier les cgu *******/
      if (!this.cgu) {
        err.push({ text: "cgu " });
      }
      /*** vérification du mot de passe */
      if (!this.isValidPassword) {
        for (let ierr = 0; ierr < this.errPassword.length; ierr++) {
          err.push({ text: this.errPassword[ierr] });
        }
      }
      if (err.length) {
        this.errLogin = "Vous devez remplir les champs : ";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.errLogin += error.text + ", ";
        }
        return;
      } else {
        try {
          this.contact.co_cgu_date = new Date();
          this.contact.co_password = this.password1;
          let response = await this.$axios.post(this.$config.server_url + "/webapp/1.0/contacts", this.contact);
          if (response.data.err) {
            this.errLogin = response.data.err.message;
            return;
          }
          this.sucessSubscription = "Votre compte a bien été créé, il est en attente de validation, vous recevrez un mail lorsqu'il sera activé.";
        } catch (error) {
          this.errLogin = "Erreur d'inscription : vérifiez ou réessayez plus tard.";
          console.error("Erreur de co : ", error);
        }
      }
    }
  }
};
</script>
<style scoped>
#login {
  min-height: 100vh;
  background-image: url("/images/fond.png");
  background-position: center;
  background-size: cover;
}
.gros-titre {
  font-size: 32px;
}
.text-small {
  font-size: 10px;
}
.color-blue {
  color: #2858c8;
}
.logo-absolute {
  position: absolute;
  top: 10px;
  left: 40px;
}
@media (min-width: 576px) {
  .gros-titre {
    font-size: 42px;
  }
}
</style>
