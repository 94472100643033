<template>
  <div id="app">
    <m-modal-cookies></m-modal-cookies>
    <!-- <div v-if="$store.state.mainloading && $route.name === 'home'" class="mainmodal d-flex justify-content-center align-items-center">
      <icon class="mr-2 fa-spin" name="sync-alt" color="white" scale="6"></icon>
    </div> -->
    <header></header>

    <section>
      <div class="">
        <router-view />
      </div>
    </section>

    <!-- <footer>Euros 2021</footer> -->
  </div>
</template>

<script>
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
// import "@fullcalendar/list/main.css";
// import Vue from "vue";
// import OfferDetails from "./views/OffersDetails";
// Vue.component("offer-details", OfferDetails);
import { version } from "../package.json";

export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  watch: {
    $route(to, from) {
      // this.linkActive = this.$route.meta.menu;
      // if (this.$route.name !== "login") {
      //   this.showMenu = true;
      //   this.disabledMenu = false;
      // } else {
      //   this.showMenu = false;
      //   this.disabledMenu = true;
      // }
    }
  },
  sockets: {},
  computed: {},
  async mounted() {
    window.document.title = "Euros webapp v" + version;
    // console.log("process.env.PACKAGE_VERSION", process.env.PACKAGE_VERSION);
  },
  methods: {}
};
</script>
<style lang="scss">
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./scss/styles.scss";
html {
  background-color: #fff;
}
h1 {
  color: #91295a;
}
#app {
  // background-color: #f4ece3;
  background-color: #fff;
  position: relative;
}

/* footer {
  text-align: center;
} */

@media (min-width: 576px) {
  .container-logo {
    display: block;
  }
}
</style>
