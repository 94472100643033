<template>
  <div>
    <euros-header :titre="`Résultats`" btnRetour="Liste" pathRetour="/patients"></euros-header>
    <div class="container-fluid bg-blue main">
      <div class="">
        <div class="row pt-2">
          <div class="col d-flex align-items-center justify-content-between mb-2 mb-sm-0">
            <div>
              <img style="width: 120px" class="img-fluid" src="/images/logo-eurotxt-black.png" alt="" />
            </div>
            <div class="ml-3 text-right">
              <!-- <button @click="newPatient" class="btn btn-primary btn-nouveau uppercase">Nouveau patient</button> -->
              <!-- <button @click="editProthesis" class="btn btn-primary mr-3">
                <icon width="16" height="16" name="arrow-circle-left" class="mr-2" color="" style="margin-bottom: -2px"></icon>Modifier
              </button> -->
              <button @click="print" class="btn btn-primary mr-3">Imprimer</button>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <h4 class="bis">{{ patient.pa_name }} <span class="date"></span></h4>
            <div>
              <span v-if="patient.pa_sex > 0"><span v-if="patient.pa_sex == 1">Homme</span><span v-if="patient.pa_sex == 2">Femme</span> | </span
              ><span v-if="patient.pa_weight">{{ patient.pa_weight }} Kg | </span><span v-if="patient.pa_height">{{ patient.pa_height }} cm | </span
              ><span v-if="patient.pa_birthdate != '0000-00-00'">Né(e) le {{ $dayjs(patient.pa_birthdate).format("DD/MM/YYYY") }}</span>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-4">
            <div class="mb-2">
              <table class="my-2 mx-2 table-delta">
                <thead>
                  <tr>
                    <th>Delta</th>
                    <th>Offset</th>
                    <th>Long.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Col S</td>
                    <td>{{ patient.pa_delta_min_x | formatDelta }}&nbsp;mm</td>
                    <td>{{ patient.pa_delta_min_y | formatDelta }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <td>Col M</td>
                    <td>{{ patient.pa_delta0_x | formatDelta }}&nbsp;mm</td>
                    <td>{{ patient.pa_delta0_y | formatDelta }}&nbsp;mm</td>
                  </tr>
                  <tr>
                    <!-- invertion des +3,5 et -3,5 normal voir issue 47 -->
                    <td>Col L</td>
                    <td>{{ patient.pa_delta_max_x | formatDelta }}&nbsp;mm</td>
                    <td>{{ patient.pa_delta_max_y | formatDelta }}&nbsp;mm</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="mt-2">
              <table class="my-2 mx-2 table-delta">
                <tbody>
                  <tr>
                    <td><strong>Poids</strong></td>
                    <td>{{ patient.pa_weight }}&nbsp;Kg</td>
                  </tr>
                  <tr>
                    <td><strong>Taille</strong></td>
                    <td>{{ patient.pa_height }}&nbsp;cm</td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>

          <div class="col-lg-8">
            <!-- <div class="alert alert-info">
              Ces cases à cocher peuvent être cochées suite à l’intervention, elles permettent d’établir des statistiques d’usages de l’application.
              Enregistrer ces préférences , en cochant cette case, vos futures utilisations intégreront par défaut ces références afin d’optimiser
              votre usage de l’application.
            </div> -->

            <div
              class="line-product bt d-flex justify-content-between align-items-center"
              style="border-top: 0px solid white"
              v-if="patient.pa_cotyle"
            >
              <div class="">
                <img
                  v-if="true"
                  class="img-product img-fluid"
                  :src="
                    $config.server_url +
                    '/api/1.0/products/' +
                    patient.pa_cotyle.pr_id +
                    '/images/2?token=' +
                    $store.state.accesstoken +
                    '&origin=webapp'
                  "
                  alt=""
                />

                <div class="ml-3" v-if="patient.pa_cotyle">
                  {{ patient.pa_cotyle.pr_name }}<br />
                  J'ai utilisé ce cotyle :<br />
                  <div class="form-check form-check-inline">
                    <input
                      v-model="patient.pa_cotyle_used"
                      class="form-check-input"
                      type="radio"
                      name="pa_cotyle_used"
                      id="pa_cotyle_used0"
                      value="0"
                      @change="saveUsed"
                    />
                    <label class="form-check-label" for="pa_cotyle_used0">Pas encore utilisé</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      v-model="patient.pa_cotyle_used"
                      class="form-check-input"
                      type="radio"
                      name="pa_cotyle_used"
                      id="pa_cotyle_used1"
                      value="1"
                      @change="saveUsed"
                    />
                    <label class="form-check-label" for="pa_cotyle_used1">Oui</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      v-model="patient.pa_cotyle_used"
                      class="form-check-input"
                      type="radio"
                      name="pa_cotyle_used"
                      id="pa_cotyle_used2"
                      value="2"
                      @change="saveUsed"
                    />
                    <label class="form-check-label" for="pa_cotyle_used2">Non</label>
                  </div>
                </div>
                <div v-if="showcotyle">
                  <div class="d-flex flex-wrap mb-2">
                    <h6 class="mt-4 mb-0">
                      <strong>Finalement j'ai utilisé ce cotyle : </strong>
                      <span v-if="cotyle_productSelected">{{ cotyle_productSelected.pr_ref }}</span>
                    </h6>
                  </div>
                  <select class="custom-select" v-model="cotyle_model" @change="changeSelectProduct('cotyle', 'model')">
                    <option v-for="option in items_cotyle_model" :value="option.key" :key="option.key">
                      {{ option.name }}
                    </option>
                  </select>
                  <select class="custom-select my-2" v-model="cotyle_subtype" @change="changeSelectProduct('cotyle', 'subtype')">
                    <option v-for="option in items_cotyle_subtype" :value="option.key" :key="option.key">
                      {{ option.name }}
                    </option>
                  </select>
                  <select class="custom-select" v-model="cotyle_col" @change="changeSelectProduct('cotyle', 'col')">
                    <option v-for="option in items_cotyle_col" :value="option.key" :key="option.key">
                      {{ option.name }}
                    </option>
                  </select>
                  <div class="d-flex justify-content-between mt-3 px-4">
                    <div @click="changeSelectProduct('cotyle', 'size', 'moins')" class="pointer d-flex align-items-center">
                      <icon width="24" height="24" name="arrow-circle-left" class="" :color="getClassGray('moins', 'cotyle')"></icon>
                      <!-- <img style="margin: -1px 0 0 0;" src="/images/arrow-left.png" width="16" alt="" /> -->
                    </div>
                    <div class="flex-grow-1 text-center">Taille {{ cotyle_productSelected ? cotyle_productSelected.pr_size : "" }}</div>
                    <div @click="changeSelectProduct('cotyle', 'size', 'plus')" class="pointer d-flex align-items-center">
                      <icon width="24" height="24" name="arrow-circle-right" class="" :color="getClassGray('plus', 'cotyle')"></icon>
                      <!-- <img style="margin: -1px 0 0 0;" src="/images/arrow-right.png" width="16" alt="" /> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="line-product d-flex justify-content-between align-items-center" v-if="patient.pa_tige">
              <div class="">
                <img
                  v-if="true"
                  class="img-product img-fluid"
                  :src="
                    $config.server_url +
                    '/api/1.0/products/' +
                    patient.pa_tige.pr_id +
                    '/images/2?token=' +
                    $store.state.accesstoken +
                    '&origin=webapp'
                  "
                  alt=""
                />

                <div class="ml-3">
                  {{ patient.pa_tige.pr_name }}<br />
                  J'ai utilisé cette tige : <br />
                  <div class="form-check form-check-inline">
                    <input
                      v-model="patient.pa_tige_used"
                      class="form-check-input"
                      type="radio"
                      name="pa_tige_used"
                      id="pa_tige_used0"
                      value="0"
                      @change="saveUsed"
                    />
                    <label class="form-check-label" for="pa_tige_used0">Pas encore utilisé</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      v-model="patient.pa_tige_used"
                      class="form-check-input"
                      type="radio"
                      name="pa_tige_used"
                      id="pa_tige_used1"
                      value="1"
                      @change="saveUsed"
                    />
                    <label class="form-check-label" for="pa_tige_used1">Oui</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      v-model="patient.pa_tige_used"
                      class="form-check-input"
                      type="radio"
                      name="pa_tige_used"
                      id="pa_tige_used2"
                      value="2"
                      @change="saveUsed"
                    />
                    <label class="form-check-label" for="pa_tige_used2">Non</label>
                  </div>
                </div>
                <div v-if="showtige">
                  <div class="d-flex flex-wrap mb-2">
                    <h6 class="mt-4 mb-0">
                      <strong>Finalement, j'ai utilisé cette tige : </strong>
                      <span v-if="tige_productSelected">{{ tige_productSelected.pr_ref }}</span>
                    </h6>
                  </div>
                  <select class="custom-select" v-model="tige_model" @change="changeSelectProduct('tige', 'model')">
                    <option v-for="option in items_tige_model" :value="option.key" :key="option.key">
                      {{ option.name }}
                    </option>
                  </select>
                  <select class="custom-select my-2" v-model="tige_subtype" @change="changeSelectProduct('tige', 'subtype')">
                    <option v-for="option in items_tige_subtype" :value="option.key" :key="option.key">
                      {{ option.name }}
                    </option>
                  </select>
                  <select class="custom-select" v-model="tige_col" @change="changeSelectProduct('tige', 'col')">
                    <option v-for="option in items_tige_col" :value="option.key" :key="option.key">
                      {{ option.name }}
                    </option>
                  </select>
                  <div class="d-flex justify-content-between mt-3 px-4">
                    <div @click="changeSelectProduct('tige', 'size', 'moins')" class="pointer d-flex align-items-center">
                      <icon width="24" height="24" name="arrow-circle-left" class="" :color="getClassGray('moins', 'tige')"></icon>
                      <!-- <img style="margin: -1px 0 0 0;" src="/images/arrow-left.png" width="16" alt="" /> -->
                    </div>
                    <div class="flex-grow-1 text-center">Taille {{ tige_productSelected ? tige_productSelected.pr_size : "" }}</div>
                    <div @click="changeSelectProduct('tige', 'size', 'plus')" class="pointer d-flex align-items-center">
                      <icon width="24" height="24" name="arrow-circle-right" class="" :color="getClassGray('plus', 'tige')"></icon>
                      <!-- <img style="margin: -1px 0 0 0;" src="/images/arrow-right.png" width="16" alt="" /> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="line-product">
              <div class="ml-3">
                Quel type de col avez vous utilisé ?<br />
                <div class="form-check form-check-inline">
                  <input
                    v-model="patient.pa_col_used"
                    class="form-check-input"
                    type="radio"
                    name="pa_col_used"
                    id="pa_col_used0"
                    value="0"
                    @change="saveUsed"
                  />
                  <label class="form-check-label" for="pa_col_used0">Pas encore utilisé</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="patient.pa_col_used"
                    class="form-check-input"
                    type="radio"
                    name="pa_col_used"
                    id="pa_col_used1"
                    value="1"
                    @change="saveUsed"
                  />
                  <label class="form-check-label" for="pa_col_used1">S</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="patient.pa_col_used"
                    class="form-check-input"
                    type="radio"
                    name="pa_col_used"
                    id="pa_col_used2"
                    value="2"
                    @change="saveUsed"
                  />
                  <label class="form-check-label" for="pa_col_used2">M</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="patient.pa_col_used"
                    class="form-check-input"
                    type="radio"
                    name="pa_col_used"
                    id="pa_col_used3"
                    value="3"
                    @change="saveUsed"
                  />
                  <label class="form-check-label" for="pa_col_used3">L</label>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col d-flex justify-content-between">
                <button @click="goPrevious" class="btn btn-primary mr-3" :disabled="currentPatient == 0">
                  <icon width="16" height="16" name="arrow-circle-left" class="mr-2" color="" style="margin-bottom: -2px"></icon>Précédent
                </button>
                <button @click="goNext" class="btn btn-primary mr-3" :disabled="currentPatient == patients.length - 1">
                  Suivant<icon width="16" height="16" name="arrow-circle-right" class="ml-2" color="" style="margin-bottom: -2px"></icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-8 offset-lg-4 d-flex justify-content-between mb-3">
            <div>
              <!-- <button @click="editProthesis" class="btn btn-primary mr-3">
                <icon width="16" height="16" name="arrow-circle-left" class="mr-2" color="" style="margin-bottom: -2px"></icon>Modifier
              </button> -->
              <!-- <button @click="print" class="btn btn-primary mr-3">Imprimer</button> -->
            </div>
            <!-- <div>
              <button @click="finish" class="btn btn-primary mr-3">Terminer</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "archive",
  components: {},
  data() {
    // console.log("this.$route.params.id", this.$route.params.id);
    return {
      pa_id: this.$route.params.id,
      patient: {},
      patients: [],
      currentPatient: 0,
      savePreferences: false,
      productsTree: [],

      showtige: false,
      showcotyle: false,

      cotyle_productSelected: null,
      tige_productSelected: null,

      items_cotyle_model: [],
      items_cotyle_subtype: [],
      items_cotyle_col: [],
      items_cotyle_size: [],
      cotyle_model: "",
      cotyle_subtype: "",
      cotyle_col: "",

      items_tige_model: [],
      items_tige_subtype: [],
      items_tige_col: [],
      items_tige_size: [],
      tige_model: "",
      tige_subtype: "",
      tige_col: ""
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("addHistoryUrl", this.$route.path);
    await this.loadTree();
    if (!this.$route.params.id) {
      this.loadPatients();
    } else {
      this.loadPatient();
    }
  },
  watch: {
    $route(to, from) {
      if (to.name == "archiveid") {
        this.pa_id = this.$route.params.id;
        this.loadPatient();
      }
    }
  },
  computed: {},
  methods: {
    async loadTree() {
      // route qui récupère tous les produits triés par models, subtype, col et size  sous forme d'arbre
      let response = await this.$axios.get(this.$config.server_url + "/api/1.0/products/tree", {
        params: {}
      });
      this.productsTree = response.data.data;
      this.items_cotyle_model = this.productsTree[0].items;
      this.items_tige_model = this.productsTree[1].items;
      // console.log("this.productsTree", this.productsTree);
    },
    /**initialise les selects produits */
    defineSelectProduct(type, sizeSelected = null) {
      // console.log("type, sizeSelected", type, sizeSelected, this.tige_productSelected);
      let index = 0;
      if (type === "tige") index = 1;

      for (let m = 0; m < this.productsTree[index].items.length; m++) {
        const model = this.productsTree[index].items[m];
        // on definie les items subtype en tournant des les models et en trouvant le model sélectionné
        if (this[type + "_model"] && this[type + "_model"].toLowerCase() === model.key.toLowerCase()) {
          this["items_" + type + "_subtype"] = model.items;
          for (let s = 0; s < model.items.length; s++) {
            const subtype = model.items[s];
            if ((this[type + "_subtype"] && this[type + "_subtype"].toLowerCase() === subtype.key.toLowerCase()) || this[type + "_subtype"] == null) {
              if (this[type + "_subtype"] == null) this[type + "_subtype"] = subtype.key;
              this["items_" + type + "_col"] = subtype.items;
              for (let c = 0; c < subtype.items.length; c++) {
                const col = subtype.items[c];
                if ((this[type + "_col"] && this[type + "_col"].toLowerCase() === col.key.toLowerCase()) || this[type + "_col"] == null) {
                  if (this[type + "_col"] == null) this[type + "_col"] = col.key;
                  this["items_" + type + "_size"] = col.items;
                  for (let d = 0; d < col.items.length; d++) {
                    const size = col.items[d];
                    if (this[type + "_productSelected"] == null && !sizeSelected) {
                      this[type + "_productSelected"] = size.product;
                    }
                    if (this[type + "_productSelected"] == null && sizeSelected && sizeSelected === size.key) {
                      this[type + "_productSelected"] = size.product;
                    }
                  }
                  if (!this[type + "_productSelected"]) {
                    this[type + "_productSelected"] = col.items[0].product;
                  }
                }
              }
            }
          }
        }
      }
      // console.log('this[type + "_col"]', type, this[type + "_col"]);
    },
    async changeSelectProduct(type, select, sens = "") {
      // console.log("this.tige_productSelected", this.tige_productSelected.pr_size);
      // on garde la taille en mémoire
      let size;
      if (this[type + "_productSelected"]) {
        size = this[type + "_productSelected"].pr_size;
      }
      // console.log("size", size);
      // on met les v-model à null et on appelle defineSelectProduct() pour redeffinir tous les selects
      if (select !== "size") {
        this[type + "_productSelected"] = null;
        if (select === "model") {
          this[type + "_subtype"] = null;
          // if (type === "tige") this.tige_col = null;
          this[type + "_col"] = null;
        }
        if (select === "subtype") {
          // && type === "tige"
          // this.tige_col = null;
          this[type + "_col"] = null;
        }
        this.defineSelectProduct(type, size);
      } else {
        this.defineSelectProduct(type, size);
        let indexSize = this.$_.findIndex(this["items_" + type + "_size"], { key: this[type + "_productSelected"].pr_size });
        if (indexSize >= 0) {
          if (sens === "plus" && indexSize < this["items_" + type + "_size"].length - 1) {
            this[type + "_productSelected"] = this["items_" + type + "_size"][indexSize + 1].product;
          }
          if (sens === "moins" && indexSize > 0) this[type + "_productSelected"] = this["items_" + type + "_size"][indexSize - 1].product;
        }
      }
      this.saveUsed();
    },
    getClassGray(sens, type, mobile = false) {
      // "#c6091f" => rouge vif , #d6979e=> rouge disabled
      if (!this[type + "_productSelected"]) return "";
      let indexSize = this.$_.findIndex(this["items_" + type + "_size"], { key: this[type + "_productSelected"].pr_size });
      if (indexSize === 0 && sens === "moins") {
        return mobile ? "#696969" : "#c8c7c7";
      }
      if (indexSize === this["items_" + type + "_size"].length - 1 && sens === "plus") {
        return mobile ? "#696969" : "#c8c7c7";
      }
      return "#c6091f";
    },
    goPrevious() {
      this.currentPatient--;
      this.$router.push("/archive/" + this.patients[this.currentPatient].pa_id).catch(err => {});
    },
    goNext() {
      this.currentPatient++;
      this.$router.push("/archive/" + this.patients[this.currentPatient].pa_id).catch(err => {});
    },
    async loadPatients(redirect = true) {
      let params = {
        // pa_archive: true,
        // text: this.filterText,
        sort: "pa_dateoperation",
        limit: 1000000
      };
      let response = await this.$axios.get(this.$config.server_url + "/api/1.0/patients", { params });
      this.patients = response.data.data.filter(row => {
        // console.log("row.pa_archive", row.pa_archive);
        if (row.pa_archive) return false;
        return true;
      });
      // console.log("this.patients.length", this.patients.length);
      for (let iPatients = 0; iPatients < this.patients.length; iPatients++) {
        const patient = this.patients[iPatients];
        if (patient.pa_id == this.$route.params.id) this.currentPatient = iPatients;
      }
      if (redirect) {
        if (this.patients.length == 0) this.$router.push("/patients").catch(err => {});
        else this.$router.push("/archive/" + this.patients[0].pa_id).catch(err => {});
      }
    },
    print() {
      window.open(
        this.$config.server_url +
          "/api/1.0/patients/" +
          this.patient.pa_id +
          "/print" +
          "?token=" +
          this.$store.state.accesstoken +
          "&origin=webapp" +
          "&d=" +
          new Date().getTime()
      );
    },
    defineVmodelSelectProduct(product) {
      // define les v model
      this[product.pr_type + "_productSelected"] = product;
      this[product.pr_type + "_model"] = product.pr_model;
      this[product.pr_type + "_subtype"] = product.pr_subtype;
      // if (product.pr_type === "tige") {
      this[product.pr_type + "_col"] = product.pr_col;
      // }
    },
    async loadPatient() {
      let response = await this.$axios.get(this.$config.server_url + "/api/1.0/patients/" + this.pa_id);
      this.patient = response.data.data;
      // console.log("this.patient", this.patient);
      if (this.patients.length == 0) this.loadPatients(false);
      // console.log("patient.createdAt", this.patient);
      if (this.patient && this.patient.pa_cotyle_used_finaly) {
        this.defineVmodelSelectProduct(this.patient.pa_cotyle_used_finaly);
      } else if (this.patient && this.patient.pa_cotyle) {
        this.defineVmodelSelectProduct(this.patient.pa_cotyle);
      }
      if (this.patient && this.patient.pa_tige_used_finaly) {
        this.defineVmodelSelectProduct(response.data.data.pa_tige_used_finaly);
      } else if (this.patient && this.patient.pa_tige) {
        this.defineVmodelSelectProduct(response.data.data.pa_tige);
      }

      if (this.cotyle_model) this.defineSelectProduct("cotyle");
      if (this.tige_model) this.defineSelectProduct("tige");

      this.showtige = this.patient.pa_tige_used * 1 == 2;
      this.showcotyle = this.patient.pa_cotyle_used * 1 == 2;
    },
    async saveUsed() {
      let data = {
        archiveornot: true,
        pa_cotyle_used: this.patient.pa_cotyle_used,
        pa_tige_used: this.patient.pa_tige_used,
        pa_col_used: this.patient.pa_col_used,
        pa_cotyle_used_finaly: this.cotyle_productSelected,
        pa_tige_used_finaly: this.tige_productSelected
      };
      // console.log("data", data);
      await this.$axios.put(this.$config.server_url + "/api/1.0/patients/" + this.patient.pa_id, data);
      this.showtige = this.patient.pa_tige_used * 1 == 2;
      this.showcotyle = this.patient.pa_cotyle_used * 1 == 2;
    },
    async finish() {
      // on enregistre les préférences si la checkbox est cochée
      if (this.savePreferences) {
        let products = {
          pr_id_cotyle: this.patient.pa_cotyle.pr_id,
          pr_id_tige: this.patient.pa_tige.pr_id,
          co_ballsize: this.patient.pa_toolsballsize
        };
        await this.$axios.put(this.$config.server_url + "/api/1.0/contacts/" + this.$store.state.user.co_id + "/preferences", products);
      }
      this.$router.push("/patients").catch(err => {});
    }
  }
};
</script>

<style scoped lang="scss">
.m-form-checkbox {
  margin-bottom: 0;
}
.bt {
  border-top: 1px solid #cacaca;
}
.line-product {
  padding: 20px 0;
  border-bottom: 1px solid #cacaca;
}

.img-product {
  max-width: 100px;
}
.date {
  font-weight: 400;
}
.questionnaire-line {
  padding-bottom: 25px;
}
</style>
